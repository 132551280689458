import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import { Link } from 'react-router-dom';
import PensionPlansWithLifeCoverImg from '../../assets/images/Plan-Pension.avif';
import BenefitsImg from '../../assets/images/pension-plan.avif';
import WhoShouldOptImg from '../../assets/images/Pension_Plans_with_Life_Cover.webp';

function Pension_Plans_Cover() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Pension Plans with Life Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Pension Plans with Life Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={PensionPlansWithLifeCoverImg}
                            alt="Pension Plans with Life Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Pension Plans with Life Cover Insurance combine the benefits of retirement savings with life insurance coverage. These plans provide individuals with regular income after retirement while also offering financial protection to their family in case of untimely demise during the policy term.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Pension Plans with Life Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Retirement Income:</strong> Ensures a steady income stream after retirement to meet your post-retirement expenses.</li>
                            <li><strong>✅ Life Cover:</strong> Provides life insurance coverage, protecting your family financially in case of your untimely demise.</li>
                            <li><strong>✅ Flexibility in Contributions:</strong> Offers flexible contribution amounts and premium payment options.</li>
                            <li><strong>✅ Tax Benefits:</strong> Provides tax deductions on premiums paid and tax-free maturity benefits.</li>
                            <li><strong>✅ Customizable Plans:</strong> Tailored plans based on your needs, whether you are looking for a lump sum or monthly pension payouts.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Pension Plans with Life Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Pension Plans with Life Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Pension Plans with Life Cover Insurance:</strong></p>
                            <ul>
                                <li>Provides dual benefits of retirement income and life insurance protection.</li>
                                <li>Ensures financial security for your loved ones if something happens to you before retirement.</li>
                                <li>Offers flexibility to plan for retirement based on your financial goals and risk tolerance.</li>
                                <li>Reduces the financial burden on your family in case of an unfortunate event.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Pension Plans with Life Cover Insurance */}
                <section className="Individual-Health-section">
                    <h2>Types of Pension Plans with Life Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Immediate Annuity Plan:</strong> Provides immediate income after retirement with life cover during the policy term.</li>
                            <li><strong>2️⃣ Deferred Annuity Plan:</strong> Allows accumulation of funds until retirement, with life cover provided throughout the policy period.</li>
                            <li><strong>3️⃣ Traditional Pension Plan:</strong> Combines both pension and life cover, ensuring a secure income for the future.</li>
                            <li><strong>4️⃣ Unit-Linked Pension Plan (ULIP):</strong> Offers a mix of investment and life cover, with the potential for market-linked returns.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Pension Plans with Life Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Pension Plans with Life Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Pension Plans with Life Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals looking for a retirement plan that also provides life insurance coverage.</li>
                                <li>✔ Young professionals who want to secure their future and protect their family financially.</li>
                                <li>✔ People who want to plan their retirement income while having a safety net for their family.</li>
                                <li>✔ Anyone looking for a tax-efficient retirement savings solution with life cover.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Pension_Plans_Cover