import React from 'react'
import Free_oppointment from '../../LifeInsurance/Free_Opp/Free_oppointment';
import Copyright from '../../pages/copyright/Copyright';
import Footer from '../../pages/footer/Footer';
import Navbar from '../../pages/navbar/Navbar';
import Mf_AboutUs from '../Mutal_Fund_About_Us/Mf_AboutUs';
import Mutal_Fund_Build from '../Mutal_Fund_Build/Mutal_Fund_Build';
import MuutalFundHero from '../Mutal_Fund_Hero/muutal_fund_hero'
import Mutal_Fund_Info_Card from '../Mutal_Fund_Info_Card/Mutal_Fund_Info_Card';
import Mutal_Fund_Info_Login from '../Mutal_Fund_Info_Login/Mutal_Fund_Info_Login';

function Mutal_Fund_Home() {
  return (
    <>
    <Navbar/>
    <MuutalFundHero/>
    <Mutal_Fund_Info_Card/>
    <Mutal_Fund_Build/>
    <Mf_AboutUs />
    <Mutal_Fund_Info_Login/>
    <Free_oppointment/>
    <Footer/>
    <Copyright/>
    </>
  )
}

export default Mutal_Fund_Home;