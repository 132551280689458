import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import StructuralCoverInsuranceImg from '../../assets/images/home_insurance.avif';
import WhoShouldOptImg from '../../assets/images/Structural_cover.avif';
import BenefitsImg from '../../assets/images/Structural-Cover-Ben.jpg';
import { Link } from 'react-router-dom';

function Structural_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Structural Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Structural Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={StructuralCoverInsuranceImg}
                            alt="Structural Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Structural Cover Insurance provides financial protection for damages to the structural integrity of a property. It helps cover repair and reconstruction costs due to unforeseen events like natural disasters, accidents, or wear and tear.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Structural Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Comprehensive Coverage:</strong> Covers damage to the building structure due to natural calamities.</li>
                            <li><strong>✅ Repair & Reconstruction Costs:</strong> Assists in rebuilding damaged structures.</li>
                            <li><strong>✅ Protection Against Accidental Damage:</strong> Covers unforeseen structural damages.</li>
                            <li><strong>✅ Affordable Premiums:</strong> Cost-effective protection for property owners.</li>
                            <li><strong>✅ Ideal for Homeowners & Businesses:</strong> Ensures financial security for structural repairs.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Structural Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Structural Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Structural Cover Insurance:</strong></p>
                            <ul>
                                <li>Provides financial security against structural damages.</li>
                                <li>Covers repair and reconstruction expenses.</li>
                                <li>Peace of mind in case of unexpected structural failures.</li>
                                <li>Flexible coverage options for different property types.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Structural Cover Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Structural Cover Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Individual Structural Cover:</strong> Coverage for personal residential properties.</li>
                            <li><strong>2️⃣ Commercial Structural Cover:</strong> Protection for commercial buildings and offices.</li>
                            <li><strong>3️⃣ Industrial Structural Cover:</strong> Covers warehouses, factories, and industrial spaces.</li>
                            <li><strong>4️⃣ Government-Backed Plans:</strong> Special policies for public infrastructure and buildings.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Structural Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Structural Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Structural Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Homeowners looking for protection against structural damages.</li>
                                <li>✔ Business owners securing their commercial properties.</li>
                                <li>✔ Industrial property owners needing additional safety measures.</li>
                                <li>✔ Anyone wanting financial support for unexpected building repairs.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Structural_Cover_Info