import './About_Us_Ritaxes.css';
import About_Us_Ritaxes_1 from '../../assets/images/finance_about_us1.jpg';
import About_Us_Ritaxes_2 from '../../assets/images/Finance_about_us2.jpg';
import { Link } from 'react-router-dom';

function About_Us_Ritaxes() {
    return (
        <>
            <div className="tax-strategies-container">
                <div className="image-section">
                    <div className="image-inner-wrapper">
                        <div className="image-wrapper">
                            <img
                                src={About_Us_Ritaxes_1}
                                alt="Life Insurance"
                                className="zoom-effect"
                            />
                        </div>
                        <div className="image-wrapper">
                            <img
                                src={About_Us_Ritaxes_2}
                                alt="Financial Planning"
                                className="zoom-effect"
                            />
                        </div>
                    </div>

                    <div className="image-inner-section">
                        <div className="experience-section">
                            <h2>15+</h2>
                            <p>Years of Financial Expertise</p>
                        </div>
                    </div>
                </div>

                <div className="text-section">
                    <h5>ABOUT FINANCIAL SERVICES</h5>
                    <h1>Innovative Strategies for Financial Prosperity</h1>
                    <p>
                        At Upholic, we provide expert financial services, specializing in life insurance, financial planning, PMS, and loans. We aim to help individuals and businesses secure their financial futures with comprehensive and personalized strategies. Our experienced team is committed to delivering tailored solutions for each client's unique needs.
                    </p>
                    <ul className="checklist">
                        <li>Comprehensive Life Insurance Solutions</li>
                        <li>Tailored Financial Planning for Future Security</li>
                        <li>Expert Portfolio Management Services (PMS)</li>
                        <li>Custom Loan Products for Personal and Business Needs</li>
                        <li>Strategic Investment Planning</li>
                        <li>Trusted Financial Guidance</li>
                    </ul>
                    <Link to="/book-free-appointment"><button className="learn-more">Learn More</button></Link>
                </div>
            </div>
        </>
    );
}

export default About_Us_Ritaxes;