import li_home from "../../assets/images/about_li_img.jpg";
import fd_home from "../../assets/images/about_fd_img.jpg";
import deposite_home from "../../assets/images/about_deposit_img.jpg";
import { Link } from "react-router-dom";
import './Dashboard_Popup.scss';

function Dashboard_Popup() {
  return (
    <>
      <section className="dashboard-popup-section">
        <h2 className="dashboard-popup-title">Our Services</h2>
        <p className="dashboard-popup-subtitle">CHOOSE A CATEGORY</p>
        <div className="dashboard-popup-container">
          <div className="dashboard-popup-card">
            <Link to="/comingsoon" className="dashboard-popup-card-inner">
              <div className="dashboard-popup-box">
                <div className="dashboard-popup-img-box">
                  <img className="dashboard-popup-img" src={li_home} alt="Life Insurance" />
                </div>
                <div className="dashboard-popup-icon">
                  <h3 className="dashboard-popup-heading">Life Insurance</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="dashboard-popup-card">
            <Link to="/comingsoon" className="dashboard-popup-card-inner">
              <div className="dashboard-popup-box">
                <div className="dashboard-popup-img-box">
                  <img
                    className="dashboard-popup-img"
                    src={fd_home}
                    alt="Financial Planning"
                  />
                </div>
                <div className="dashboard-popup-icon">
                  <h3 className="dashboard-popup-heading">Financial Planning</h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="dashboard-popup-card">
            <Link to="/comingsoon" className="dashboard-popup-card-inner">
              <div className="dashboard-popup-box">
                <div className="dashboard-popup-img-box">
                  <img className="dashboard-popup-img" src={deposite_home} alt="Loan" />
                </div>
                <div className="dashboard-popup-icon">
                  <h3 className="dashboard-popup-heading">Loan</h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard_Popup;