import './Info_Carousel.css';
import Info_fin_background_Video from '../../assets/video/finance_video.mp4'; // Update the import to your video file
import { Link } from 'react-router-dom';

function Info_Carousel() {
  return (
    <>
      <div className="info-carousel-container">
        {/* Background Video */}
        <video autoPlay loop muted className="info-background-video">
          <source src={Info_fin_background_Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Content Overlay */}
        <div className="info-content-overlay">
          {/* Header Section */}
          <div className="info-header-section">
            <h1>EXCLUSIVE FINANCIAL SOLUTIONS</h1>
            <h2>Tailored offers to grow your wealth</h2>
          </div>

          {/* Call-to-Action Button */}
          <Link to="/Signup"><button className="info-cta-button">EXPLORE NOW</button></Link>
        </div>
      </div>
    </>
  );
}

export default Info_Carousel;