import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PortfolioHeroImage from '../../assets/images/Mutual_Fund_Hero.avif';

function Portfolio_Hero() {
    const [hovered, setHovered] = useState(false);
    const handleMouseEnter = () => setHovered(true);
    const handleMouseLeave = () => setHovered(false);

    return (
        <>
            <section className="life-in-custom-hero">
                <div className="life-in-custom-hero-background">
                    {/* Gradient overlay */}
                    <div className={`life-in-custom-hero-overlay ${hovered ? "hovered" : ""}`} />

                    {/* Hero image */}
                    <img
                        src={PortfolioHeroImage}
                        alt="Portfolio Management"
                        className="life-in-custom-hero-image"
                    />
                </div>

                <div className="life-in-custom-hero-content">
                    <h1 className="life-in-custom-hero-title">
                        Expert <span className="highlight">Portfolio Management</span> Services
                    </h1>
                    <p className="life-in-custom-hero-subtitle">
                        Optimize your investments with personalized portfolio management solutions designed for your financial growth.
                    </p>
                    <Link to="/Signup">
                        <button
                            className="life-in-custom-hero-button"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            Manage Your Portfolio
                        </button>
                    </Link>
                </div>
            </section>
        </>
    );
}

export default Portfolio_Hero;