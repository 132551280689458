import React, { useEffect, useState } from "react";
import Admin_sidebar_links from '../admin_sidebar_links/Admin_sidebar_links';
import adminlogoo from "../../assets/images/upholic.newlogo.png";
import axios from "axios";
import PMS_Popup from '../admin_pms_popup/PMS_Popup'; // Import the Popup component
import Admin_PMS_Popup_Dashboard from "../admin_pms_popup_dash_modal/Admin_PMS_Popup_Dashboard";

function Admin_PMS_Dashboard() {
    const [searchQuery, setSearchQuery] = useState("");
    const [customers, setCustomers] = useState([]);
    const [PMSuserCount, setPMSuserCount] = useState(0);
    const [selectedUserId, setSelectedUserId] = useState(null); // State to manage selected user_id
    const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
    const [isPopupDashboardModalOpen, setIsPopupDashboardModalOpen] = useState(false);


    // Rename the functions to match the new state variable
    const openPopupDashboardModal = () => setIsPopupDashboardModalOpen(true);
    const closePopupDashboardModal = () => setIsPopupDashboardModalOpen(false);

    const filteredData = customers.filter((customer) =>
        Object.values(customer).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        ));

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get("https://www.upholic.in/Upholic_Api/fetch_pms_customers.php", {
                    headers: { "Content-Type": "application/json" },
                });
                console.log(response);
                setCustomers(response.data);
                setPMSuserCount(response.data.length);
            } catch (error) {
                console.error("Error fetching customers:", error);
            }
        };

        fetchCustomers();
    }, []);

    const handleUserIdClick = (user_id) => {
        setSelectedUserId(user_id);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedUserId(null);
    };

    const [pmsSummary, setPmsSummary] = useState({
        total_value_of_cost: 0,
        total_value_at_market_price: 0,
        profit_loss_amount: 0,
        profit_loss_percentage: 0,
        total_customers: 0, // Add this field
    });

    // console.log("Total Customers:", pmsSummary.total_customers);


    useEffect(() => {
        const fetchPmsSummary = async () => {
            try {
                const response = await axios.get("https://www.upholic.in/Upholic_Api/fetch_pms_summary.php");
                // console.log("Fetched Data:", response.data);
                // console.log(response);
                setPmsSummary(response.data);
            } catch (error) {
                console.error("Error fetching PMS summary:", error);
            }
        };

        fetchPmsSummary();
    }, []);

    // Ensure values are numbers
    const totalValueOfCost = parseFloat(pmsSummary.total_value_of_cost) || 0;
    const totalUnrealizedPNL = parseFloat(pmsSummary.total_unrealizedPNL) || 0;
    const totalRealizedPNL = parseFloat(pmsSummary.total_realizedPNL) || 0;

    // Correct Profit/Loss Calculation
    const profitLossPercentage = totalValueOfCost > 0 ? ((totalUnrealizedPNL / totalValueOfCost) * 100).toFixed(2) : "0.00";
    const profitLossAmount = totalUnrealizedPNL; // Since realizedPNL is 0

    // console.log(`Profit/Loss (%): ${profitLossPercentage}%`);
    // console.log(`Profit/Loss Amount: ₹${profitLossAmount}`);


    // Determine color for profit/loss
    const profitLossColor = (value) => (parseFloat(value) >= 0 ? "green" : "red");


    return (
        <>
            <Admin_sidebar_links />
            <div className="man-man-div">
                <div className="deposite-header area">
                    {/* <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul> */}
                    <div className="deposite-container">
                        <div className="deposite-logo-img">
                            <img
                                src={adminlogoo}
                                alt="Logo 1"
                                className="deposite-image-logo"
                            />
                        </div>
                        <div className="deposite-search-bar">
                            <input type="text" placeholder="Search here" />
                            <ion-icon name="search" className="search-icon"></ion-icon>
                        </div>

                        <div className="deposite-logo2-bar">
                            <h1 className="deposite-page-heading">PMS</h1>
                        </div>
                    </div>

                    {/* ======================= Cards ==================  */}
                    <div className="deposite-cardBox">
                        <div className="deposite-card">
                            <div>
                                <div className="deposite-numbers">{pmsSummary.total_customers}</div>
                                <div className="deposite-cardName">Total Customer</div>
                            </div>
                            <div className="deposite-iconBx">
                                <ion-icon name="people"></ion-icon>
                            </div>
                        </div>


                        <div className="deposite-card" onClick={openPopupDashboardModal}>
                            <div>
                                <div className="deposite-numbers">₹{totalValueOfCost.toLocaleString()}</div>
                                <div className="deposite-cardName">PMS Amount</div>
                            </div>
                            <div className="deposite-iconBx">
                                <ion-icon name="cash"></ion-icon>
                            </div>
                        </div>

                        {isPopupDashboardModalOpen && <Admin_PMS_Popup_Dashboard onClose={closePopupDashboardModal} />}

                        <div className="deposite-card">
                            <div>
                                <div className="deposite-numbers" style={{ color: pmsSummary.profit_loss_percentage >= 0 ? "green" : "red" }}>
                                    {pmsSummary.profit_loss_percentage}%
                                </div>
                                <div className="deposite-cardName">Profit/Loss (%)</div>
                            </div>
                            <div className="deposite-iconBx">
                                <ion-icon name="stats-chart-outline"></ion-icon>
                            </div>
                        </div>

                        <div className="deposite-card">
                            <div>
                                <div className="deposite-numbers" style={{ color: pmsSummary.profit_loss_amount >= 0 ? "green" : "red" }}>
                                    ₹{pmsSummary.profit_loss_amount.toLocaleString()}
                                </div>
                                <div className="deposite-cardName">Profit/Loss Amt</div>
                            </div>
                            <div className="deposite-iconBx">
                                <ion-icon name="wallet"></ion-icon>
                            </div>
                        </div>

                    </div>
                </div>

                {/* ======================= Deposit Data Table ================== */}
                <div className="trading_dashboard_area">
                    <div className="trading_dashboard_card">
                        <div className="trading-dashboard-header">
                            <h2 className="deposit-page-heading">PMS Customer</h2>
                            <div className="trading-deposit-search-container">
                                <input
                                    type="text"
                                    className="trading-deposit-search-input"
                                    placeholder="Search deposits..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="trading-table-container">
                            <table className="trading-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Username</th>
                                        <th>User ID</th>
                                        <th>Deposit ID</th>
                                        <th>Profile Picture</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Date of Birth</th>
                                        <th>Mobile Number</th>
                                        <th>Email Address</th>
                                        <th>Aadhaar Image</th>
                                        <th>PAN Image</th>
                                        <th>Marital Status</th>
                                        <th>Gender</th>
                                        <th>Nominee Type</th>
                                        <th>Nominee Name</th>
                                        <th>Bank Name</th>
                                        <th>Bank Account</th>
                                        <th>IFSC Code</th>
                                        <th>Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length > 0 ? (
                                        filteredData.map((customer, index) => (
                                            <tr key={customer.id || index}>
                                                {/* Auto-incrementing ID */}
                                                <td>{index + 1}</td>
                                                <td>{customer.username}</td>
                                                <td>
                                                    <span
                                                        style={{ cursor: 'pointer', color: 'blue' }}
                                                        onClick={() => handleUserIdClick(customer.user_id)}
                                                    >
                                                        {customer.user_id}
                                                    </span>
                                                </td>
                                                <td>{customer.deposit_id}</td>
                                                <td>
                                                    {customer.profile_picture ? (
                                                        <h4
                                                            onClick={() => window.open(customer.profile_picture, "_blank")}
                                                            style={{ fontSize: '14px', cursor: 'pointer', marginTop: '10px', marginBottom: '10px' }}
                                                        >
                                                            Tap to view
                                                        </h4>
                                                    ) : (
                                                        "No Image"
                                                    )}
                                                </td>
                                                <td>{customer.first_name}</td>
                                                <td>{customer.last_name}</td>
                                                <td>{customer.date_of_birth}</td>
                                                <td>{customer.mobile_number}</td>
                                                <td>{customer.email_address}</td>
                                                <td>
                                                    {customer.aadhaar_image ? (
                                                        <h4 onClick={() => window.open(customer.aadhaar_image, "_blank")}
                                                            style={{ fontSize: '14px', cursor: 'pointer', marginTop: '10px', marginBottom: '10px' }}>
                                                            Tap to view
                                                        </h4>
                                                    ) : (
                                                        "No Image"
                                                    )}
                                                </td>
                                                <td>
                                                    {customer.pan_image ? (
                                                        <h4 onClick={() => window.open(customer.pan_image, "_blank")}
                                                            style={{ fontSize: '14px', cursor: 'pointer', marginTop: '10px', marginBottom: '10px' }}>
                                                            Tap to view
                                                        </h4>
                                                    ) : (
                                                        "No Image"
                                                    )}
                                                </td>
                                                <td>{customer.marital_status}</td>
                                                <td>{customer.gender}</td>
                                                <td>{customer.nominee_type}</td>
                                                <td>{customer.nominee_name}</td>
                                                <td>{customer.bank_name}</td>
                                                <td>{customer.bank_account}</td>
                                                <td>{customer.ifsc_code}</td>
                                                <td>{customer.category}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="20" style={{ textAlign: 'center' }}>
                                                No customers found.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Render the Popup if showPopup is true */}
            {showPopup && <PMS_Popup user_id={selectedUserId} onClose={handleClosePopup} />}
        </>
    )
}

export default Admin_PMS_Dashboard;