import React from 'react'
import Loan_Hero from '../Loan_Hero/Loan_Hero'
import Navbar from '../../pages/navbar/Navbar'
import Loan_Card from '../Loan_Card/Loan_Card'
import Loan_Build from '../Loan_Build/Loan_Build'
import Loan_About from '../Loan_About/Loan_About'
import Loan_Service from '../Loan_Service/Loan_Service'
import Footer from '../../pages/footer/Footer'
import Copyright from '../../pages/copyright/Copyright'
import Free_oppointment from '../../LifeInsurance/Free_Opp/Free_oppointment'

function Loan_Home() {
  return (
    <>
     <Navbar/>
     <Loan_Hero/>
     <Loan_Card/>
     <Loan_Build/>
     <Loan_About/>
     <Loan_Service/>
     <Free_oppointment/>
     <Footer/>
     <Copyright/>
    </>
  )
}

export default Loan_Home