import mf_image_AboutUs from '../../assets/images/mutual_fund_bg_img.jpg';

function Portfolio_About() {
  return (
    <>
      <div className="mf-about-us-container">
        <img
          src={mf_image_AboutUs}
          alt="Portfolio Management Services Background"
          className="mf-about-us-background"
        />

        {/* Main Content Container */}
        <div className="mf-about-us-content">
          <h2 className="mf-about-us-heading">Benefits of Portfolio Management Services</h2>

          {/* Cards Container */}
          <div className="mf-about-us-cards-container">
            <div className="mf-about-us-card dark-theme">
              <h3>Professional Portfolio Management</h3>
              <p>PMS is handled by expert portfolio managers who analyze market trends and make informed investment decisions.</p>
            </div>
            <div className="mf-about-us-card light-theme">
              <h3>Customized Investment Strategies</h3>
              <p>Investment strategies are tailored to suit individual financial goals, risk appetite, and market conditions.</p>
            </div>
            <div className="mf-about-us-card dark-theme">
              <h3>Diversified Investment Approach</h3>
              <p>Funds are allocated across multiple asset classes such as equities, bonds, and alternative investments to minimize risks.</p>
            </div>
            <div className="mf-about-us-card light-theme">
              <h3>Tax Efficiency</h3>
              <p>PMS helps in structuring investments in a tax-efficient manner to optimize post-tax returns for investors.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Portfolio_About;
