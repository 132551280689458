import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import BenefitsImg from '../../assets/images/Maternity_Insurance.jpg';
import MaternityInsuranceImg from '../../assets/images/MaternityInsuranceImg.png';
import WhoShouldOptMaternity_Insurance from '../../assets/images/WhoShouldOptMaternity_Insurance.avif';
import { Link } from 'react-router-dom'

function Maternity_Insurance_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Maternity Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Maternity Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={MaternityInsuranceImg}
                            alt="Maternity Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Maternity Insurance is a health policy that covers medical expenses related to pregnancy, childbirth, and postnatal care. It helps in covering hospitalization costs, delivery charges, newborn care, and other maternity-related expenses, ensuring financial ease during this important life stage.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Maternity Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Coverage for Delivery Expenses:</strong> Includes both normal and C-section delivery costs.</li>
                            <li><strong>✅ Pre & Postnatal Care:</strong> Covers medical expenses before and after childbirth.</li>
                            <li><strong>✅ Newborn Baby Cover:</strong> Provides financial protection for the newborn’s medical needs.</li>
                            <li><strong>✅ Hospitalization Benefits:</strong> Covers hospital stays, doctor consultations, and medical tests.</li>
                            <li><strong>✅ Cashless Facility:</strong> Avail of cashless treatment at network hospitals.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Maternity Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Maternity Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 For Expecting Parents:</strong></p>
                            <ul>
                                <li>Reduces Financial Burden: Covers high maternity expenses.</li>
                                <li>Comprehensive Care: Ensures both mother and baby receive the best medical attention.</li>
                                <li>Peace of Mind: Allows parents to focus on the baby’s arrival without financial stress.</li>
                                <li>Tax Benefits: Premiums may be eligible for tax deductions under applicable laws.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Maternity Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Maternity Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Individual Maternity Plans:</strong> Specifically designed for maternity coverage.</li>
                            <li><strong>2️⃣ Group Maternity Plans:</strong> Offered as part of employer-provided health insurance.</li>
                            <li><strong>3️⃣ Comprehensive Health Insurance:</strong> Includes maternity benefits along with regular health coverage.</li>
                            <li><strong>4️⃣ Government Schemes:</strong> Special plans for maternity care under government health programs.</li>
                            <li><strong>5️⃣ Family Floater Plans:</strong> Covers maternity expenses under a family insurance policy.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Maternity Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Maternity Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptMaternity_Insurance}
                            alt="Who Should Opt for Maternity Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Couples planning a family in the near future.</li>
                                <li>✔ Expecting parents looking for financial security during childbirth.</li>
                                <li>✔ Individuals seeking coverage for both mother and newborn baby.</li>
                                <li>✔ Employees covered under a corporate group health plan with maternity benefits.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />

        </>
    )
}

export default Maternity_Insurance_Info