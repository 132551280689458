import "./About_Us.scss";
import Navbar from "../../pages/navbar/Navbar";
import Finance_img from "../../assets/images/Finance_About_Us.jpg";
import { Link } from "react-router-dom";

function About_Us() {
    return (
        <>
            <Navbar />
            <header className="about-us-header">
                <img
                    src={Finance_img}
                    alt="Finance Background"
                    className="about-us-background-img"
                />
                {/* Centered Content */}
                <div className="about-us-content">
                    <h1>About Us</h1>
                    <p>
                        We provide expert solutions in <strong>Life Insurance</strong>, 
                        <strong> Loans</strong>, <strong>PMS</strong>, and 
                        <strong> Financial Planning</strong> to secure your future.
                    </p>
                    <Link to="/book-free-appointment"><button className="cta-button">Learn More</button></Link>
                </div>
            </header>
        </>
    );
}

export default About_Us;