import React, { useEffect, useState } from "react";
import * as d3 from "d3";
// import "./Pie_Chart.scss";

const Popup_Dash_Pie_Chart = () => {
  const [portfolioData, setPortfolioData] = useState([]);

  useEffect(() => {
    fetch(`https://www.upholic.in/Upholic_Api/popup_dash_piechart.php`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (!Array.isArray(data)) {
          throw new Error('Data received is not an array');
        }
        const formattedData = data.map((item) => ({
          label: item.sector,
          value: parseFloat(item.total_value),
        }));
        setPortfolioData(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching or formatting data:", error);
        // Optionally set some error state to display to the user
      });
  }, []);

  useEffect(() => {
    if (portfolioData.length > 0) {
      const width = 300;
      const height = 300;
      const radius = Math.min(width, height) / 2;

      const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

      const arc = d3
        .arc()
        .innerRadius(radius - 90)
        .outerRadius(radius - 10);

      const pie = d3
        .pie()
        .value((d) => d.value)
        .sort(null);

      const svg = d3.select("#donut-chart");
      svg.selectAll("*").remove();

      const g = svg
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2},${height / 2})`);

      const arcs = g
        .selectAll(".arc")
        .data(pie(portfolioData))
        .enter()
        .append("g")
        .attr("class", "arc");

      arcs
        .append("path")
        .attr("d", arc)
        .attr("fill", (d) => colorScale(d.data.label));

      arcs
        .append("text")
        .attr("transform", (d) => {
          const [x, y] = arc.centroid(d);
          const offset = 5;
          return `translate(${x + offset},${y + offset})`;
        })
        .attr("dy", ".35em")
        .style("text-anchor", "middle")
        .attr("fill", "#fff")
        .style("font-size", (d) => {
          const maxLength = 10; 
          const labelLength = d.data.label.length;
          return labelLength > maxLength ? `${Math.max(10, 10 - (labelLength - maxLength))}px` : "10px";
        })
        .text((d) => {
          const label = d.data.label;
          return label.length > 10 ? label.slice(0, 10) + "..." : label; 
        });
    }
  }, [portfolioData]);

  return <svg id="donut-chart"></svg>;
};

export default Popup_Dash_Pie_Chart;