import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MutalHeroImage from '../../assets/images/Mutual_Fund_Hero.avif';

function Muutal_Fund_Hero() {
    const [hovered, setHovered] = useState(false);
    const handleMouseEnter = () => setHovered(true);
    const handleMouseLeave = () => setHovered(false);

    return (
        <>
            <section className="life-in-custom-hero">
                <div className="life-in-custom-hero-background">
                    {/* Gradient overlay */}
                    <div className={`life-in-custom-hero-overlay ${hovered ? "hovered" : ""}`} />

                    {/* Hero image */}
                    <img
                        src={MutalHeroImage}
                        alt="Mutual Funds"
                        className="life-in-custom-hero-image"
                    />
                </div>

                <div className="life-in-custom-hero-content">
                    <h1 className="life-in-custom-hero-title">
                        Invest in <span className="highlight">Mutual Funds</span> for Growth
                    </h1>
                    <p className="life-in-custom-hero-subtitle">
                        Secure your financial future with expert-managed mutual funds tailored to your goals.
                    </p>
                    <Link to="/Signup">
                        <button
                            className="life-in-custom-hero-button"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            Start Investing
                        </button>
                    </Link>
                </div>
            </section>
        </>
    );
}

export default Muutal_Fund_Hero;