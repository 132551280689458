import './Portfolio_Service.css';

function Portfolio_Service() {
    return (
        <>
            <div className="mf-login-investment-row">
                {/* Left Side - Text Section */}
                <div className="mf-login-investment-text">
                    <div className="mf-login-investment-text-container">
                        <div className="mf-login-investment-title">
                            <h1 className="mf-login-investment-h2">Portfolio Management Services Category</h1>
                        </div>
                        <div className="mf-login-investment-description">
                            <ul>
                                <li><strong className="mf-strong">Customized Investment Strategies:</strong> PMS offers tailored investment strategies based on individual financial goals, risk appetite, and market conditions.</li>
                                <li><strong className="mf-strong">Professional Management:</strong> Managed by experienced portfolio managers who make informed investment decisions based on research and market analysis.</li>
                                <li><strong className="mf-strong">Diversification:</strong> Investments are spread across multiple asset classes such as equities, fixed income, and alternative investments to manage risk effectively.</li>
                                <li><strong className="mf-strong">Active Monitoring:</strong> Portfolio managers continuously monitor and adjust investments to optimize returns and mitigate risks.</li>
                                <li><strong className="mf-strong">Transparency:</strong> Regular reports and updates are provided to investors regarding portfolio performance and holdings.</li>
                                <li><strong className="mf-strong">Tax Efficiency:</strong> PMS structures investments to maximize tax benefits and optimize post-tax returns.</li>
                                <li><strong className="mf-strong">High Net-Worth Focus:</strong> Typically designed for high-net-worth individuals (HNIs) and institutional investors looking for personalized investment solutions.</li>
                                <li><strong className="mf-strong">Flexible Investment Options:</strong> Offers discretionary and non-discretionary services, where investors can either allow full management control to the portfolio manager or have a say in investment decisions.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Right Side - Image Section */}
                <div className="mf-login-investment-imagee"></div>
            </div>
        </>
    );
}

export default Portfolio_Service;
