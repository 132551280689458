import React, { useEffect, useState, useRef } from 'react';
import Affordable_Premiums from '../../assets/images/Affordable_Premiums.png';
import simple_and_clear from '../../assets/images/simple_and_clear.png';
import Flexible_Terms from '../../assets/images/Flexible_Terms.png';
import bike_insurance from '../../assets/images/Own_Damage_Bike_Insurance.jpg';
import bike_insurances from '../../assets/images/bike-insurance.avif';
import bike_third from '../../assets/images/Third_Party_Bike_Insurance.avif';
import com_bike_insurance from '../../assets/images/Comprehensive_Bike_Insurance.jpg';
import own_damage from '../../assets/images/Own_Damage_Bike_Insurance.jpg';
import peronal_accident from '../../assets/images/Personal_Accident_Cover_for_Riders.jpg';
import { Link } from 'react-router-dom';
import { ImCross } from 'react-icons/im';

function Bike_Insurance() {
    const carousell = useRef(null);
    const intervalRef = useRef(null); // Ref to store the interval ID
    const [angle, setAngle] = useState(0); // State to store the rotation angle

    const carousellItems = [
        {
            img: bike_third,
            title: 'Third-Party Bike Insurance',
            description: 'Covers liabilities for damages or injuries caused to third parties in an accident involving your bike.',
            link: "/third-party-bike-insurance"
        },
        {
            img: com_bike_insurance,
            title: 'Comprehensive Bike Insurance',
            description: 'Provides all-around coverage for damages to your bike, third-party liabilities, theft, and natural calamities.',
            link: "/comprehensive-bike-insurance"
        },
        {
            img: own_damage,
            title: 'Own Damage Bike Insurance',
            description: 'Protects your bike from damages due to accidents, fire, theft, and other unforeseen events.',
            link: "/own-damage-bike-insurance"
        },
        {
            img: peronal_accident,
            title: 'Personal Accident Cover for Riders',
            description: 'Offers compensation for injuries or fatalities resulting from bike accidents.',
            link: "/personal-accident-cover-bike"
        }
    ];

    const totalCards = carousellItems.length;
    const rotationAngle = 360 / totalCards;

    const startSliding = () => {
        intervalRef.current = setInterval(() => {
            setAngle((prevAngle) => {
                const newAngle = prevAngle - rotationAngle;
                if (carousell.current) {
                    carousell.current.style.transform = `translateZ(-25rem) rotateY(${newAngle}deg)`;
                }
                return newAngle;
            });
        }, 2000);
    };

    const stopSliding = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        startSliding(); // Start the sliding on component mount
        return () => stopSliding(); // Clear the interval on component unmount
    }, [rotationAngle]);

    return (
        <>
            <div className="home_insurance-header-section">
                <Link to="/life-Insurance" className="login-close-link">
                    <ImCross className="login-close-icon" />
                </Link>
                <h1 className="home_insurance-main-title">Bike Insurance</h1>
                <p className="home_insurance-subtitle">Protect Your Bike with Comprehensive Insurance Coverage</p>

                <div className="home_insurance-intro-section">
                    <p className="home_insurance-intro-text">
                        Bike insurance provides financial protection for your two-wheeler, covering damages, theft, and third-party liabilities to ensure you ride with confidence.
                    </p>
                </div>

                <div className="new-features-section">
                    <h2 className="new-section-title">Key Features</h2>
                    <div className="new-features">
                        <div className="new-feature-card">
                            <img src={Affordable_Premiums} className="new-type-image" alt="Affordable Premiums"></img>
                            <h3>Affordable Premiums</h3>
                            <p>Bike insurance plans are cost-effective and provide essential coverage for a worry-free ride.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={simple_and_clear} className="new-type-image" alt="Simple and Clear"></img>
                            <h3>Simple and Clear</h3>
                            <p>Easy-to-understand policies that ensure clarity in coverage and the claims process for riders.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={Flexible_Terms} className="new-type-image" alt="Flexible Terms"></img>
                            <h3>Flexible Terms</h3>
                            <p>Customize your bike insurance policy with add-ons and tailored coverage options to suit your needs.</p>
                        </div>
                    </div>
                </div>


                <div className="carousell-container">
                    <h2 className="home_insurance-section-title">Types of Bike Insurance</h2>
                    <div className="carousell">
                        <div
                            className="carousell__cards"
                            ref={carousell}
                            onMouseEnter={stopSliding} // Stop sliding on mouse enter
                            onMouseLeave={startSliding} // Resume sliding on mouse leave
                        >
                            {carousellItems.map((item, index) => (
                                <Link
                                    to={item.link}   // Link to the desired page
                                    key={index}
                                    className="carousell__card"
                                    style={{
                                        transform: `rotateY(${index * rotationAngle}deg) translateZ(25rem)`
                                    }}
                                >
                                    <div className="carousell__img-container">
                                        <img className="carousell__img" src={item.img} alt={`Cover for ${item.title}`} />
                                    </div>
                                    <div className="carousell__content">
                                        <h3 className="carousell__title">{item.title}</h3>
                                        <p className="carousell__para">{item.description}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="insurance-cta-container">
                    <div className="insurance-cta-image-container">
                        <img
                            src={bike_insurances}  // Replace with the image for bike insurance
                            alt="Bike insurance illustration"
                            className="insurance-cta-image"
                        />
                    </div>
                    <div className="insurance-cta-content">
                        <h1 className="insurance-cta-title">Why Choose Bike Insurance?</h1>
                        <p className="insurance-cta-description">
                            Bike insurance is crucial to ensure that you and your bike are protected from unexpected accidents, theft, and damage. Whether you're riding for leisure or commuting, bike insurance offers coverage for repairs, third-party liability, and more. Protect your bike and your peace of mind with the right insurance plan.
                        </p>
                        <ul className="insurance-cta-benefits">
                            <li><strong>Accident Coverage:</strong> Covers bike damage and medical expenses from accidents.</li>
                            <li><strong>Theft Protection:</strong> Ensures financial compensation if your bike is stolen.</li>
                            <li><strong>Third-Party Liability:</strong> Covers legal and medical costs for injuries or damage you cause.</li>
                            <li><strong>Engine & Parts Protection:</strong> Covers damage to engine or essential parts.</li>
                            <li><strong>Natural Disaster Coverage:</strong> Protects against damage from floods, storms, and other disasters.</li>
                            <li><strong>Peace of Mind:</strong> Ride confidently, knowing you're protected in unexpected situations.</li>
                        </ul>

                        <p className="insurance-cta-footer">
                            Protect your bike and enjoy your ride—get bike insurance today.
                        </p>

                        <Link to="/Signup">
                            <button className="insurance-cta-button">Get Your Bike Insurance</button>
                        </Link>
                    </div>
                </div>


                <div className="home_insurance-benefits-section">
                    <h2 className="home_insurance-section-title-ben">Benefits of Bike Insurance</h2>
                    <div className="home_insurance-benefits-inner-section">
                        <div>
                            <ul className="home_insurance-benefits-list">
                                <li>Comprehensive protection against damages, theft, and third-party liabilities for your bike.</li>
                                <li>Covers repair costs, medical expenses, and legal liabilities arising from accidents.</li>
                                <li>Ensures peace of mind while riding, knowing you're financially protected against unforeseen events.</li>
                                <li>Provides flexible coverage options and add-ons, like zero depreciation or roadside assistance.</li>
                                <li>Offers 24/7 customer support to assist you with claims and emergencies.</li>
                                <li>Discover more benefits by booking a free appointment with our team of experts to choose the right bike insurance plan for you.</li>
                            </ul>
                            <div className="home_insurance-cta-section">
                                <Link to='/book-free-appointment' className="home_insurance-cta-button">Book free appointment</Link>
                            </div>
                        </div>
                        <div>
                            <img src={bike_insurance} alt="Benefits of Bike Insurance" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bike_Insurance;
