import { Link } from 'react-router-dom'
import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import WhoShouldOptImg from '../../assets/images/Personal_Accident_Cover.webp';
import PersonalAccidentImg from '../../assets/images/Personal_Accident_Insurance.avif';
import BenefitsImg from '../../assets/images/Benefit-Personal-Accident-Insurance.avif';

function Personal_Accident_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Personal Accident Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Personal Accident Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={PersonalAccidentImg}
                            alt="Personal Accident Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Personal Accident Insurance provides financial protection in case of accidental injuries, disabilities, or death. It offers a lump-sum payment to cover medical expenses, loss of income, and other financial burdens resulting from an accident.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Personal Accident Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Accidental Death Benefit:</strong> Provides a lump-sum payment to the nominee in case of accidental death.</li>
                            <li><strong>✅ Permanent Disability Coverage:</strong> Financial support for total or partial permanent disability.</li>
                            <li><strong>✅ Hospitalization Expenses:</strong> Covers medical bills arising from accidents.</li>
                            <li><strong>✅ Weekly Income Benefit:</strong> Compensation for temporary loss of income due to disability.</li>
                            <li><strong>✅ Worldwide Coverage:</strong> Protection against accidents anywhere in the world.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Personal Accident Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Personal Accident Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 For Policyholders:</strong></p>
                            <ul>
                                <li>Financial Security: Provides financial support in case of accidental injury or death.</li>
                                <li>Income Protection: Ensures a steady flow of income in case of disability.</li>
                                <li>Additional Coverage: Can be availed alongside existing health and life insurance policies.</li>
                                <li>Affordable Premiums: Provides high coverage at a reasonable cost.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Personal Accident Insurance */}
                <section className="Individual-Health-section">
                    <h2>Types of Personal Accident Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Individual Accident Plans:</strong> Provides coverage for individuals against accidental injuries and death.</li>
                            <li><strong>2️⃣ Group Accident Plans:</strong> Offered by employers to provide accident coverage to employees.</li>
                            <li><strong>3️⃣ Family Accident Plans:</strong> Covers all family members under a single policy.</li>
                            <li><strong>4️⃣ Student Accident Plans:</strong> Specially designed for students to cover accident-related medical expenses.</li>
                            <li><strong>5️⃣ Travel Accident Plans:</strong> Provides coverage for accidents occurring while traveling.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Personal Accident Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Personal Accident Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Personal Accident Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals with high-risk jobs or frequent travelers.</li>
                                <li>✔ Self-employed professionals and business owners.</li>
                                <li>✔ Those without a financial backup in case of accidents.</li>
                                <li>✔ Families seeking additional financial security.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />

        </>
    )
}

export default Personal_Accident_Info