
function Portfolio_Card() {
    return (
        <>
            <div className="mf-investment-services-container">
                <h1 className="mf-investment-services-title">Portfolio Management Services</h1>
                <p className="mf-investment-services-description">
                    Our expert portfolio management services help you optimize your investments, ensuring financial growth and stability through tailored strategies.
                </p>
                <div className="mf-investment-services-grid">
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">📈</div>
                        <h2 className="mf-investment-services-heading">Wealth Optimization</h2>
                        <p className="mf-investment-services-text">
                            Our strategic investment approach helps you maximize returns while minimizing risks, ensuring long-term financial success.
                        </p>
                    </div>
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">🔍</div>
                        <h2 className="mf-investment-services-heading">Professional Insights</h2>
                        <p className="mf-investment-services-text">
                            Gain access to expert analysis and market trends, allowing you to make well-informed investment decisions.
                        </p>
                    </div>
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">💰</div>
                        <h2 className="mf-investment-services-heading">Asset Allocation</h2>
                        <p className="mf-investment-services-text">
                            Diversify your portfolio with a strategic mix of assets to minimize risk and enhance financial stability.
                        </p>
                    </div>
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">📊</div>
                        <h2 className="mf-investment-services-heading">Risk Management</h2>
                        <p className="mf-investment-services-text">
                            Our experts assess market risks and provide tailored solutions to safeguard your investments.
                        </p>
                    </div>
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">⏳</div>
                        <h2 className="mf-investment-services-heading">Long-Term Growth</h2>
                        <p className="mf-investment-services-text">
                            Plan for a secure financial future with customized investment strategies designed for long-term growth.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio_Card