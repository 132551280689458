import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import OwnDamageBikeInsuranceImg from '../../assets/images/Own_Damage_Bike_Insurance.jpg';
import BenefitsImg from '../../assets/images/Third_Party_Bike_Insurance.avif';
import WhoShouldOptImg from '../../assets/images/bike-third.avif'
import { Link } from 'react-router-dom'

function Own_Damage_Bike_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Own Damage Bike Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Own Damage Bike Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={OwnDamageBikeInsuranceImg}
                            alt="Own Damage Bike Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Own Damage Bike Insurance offers coverage for damages to your bike caused by accidents, natural calamities, or other unforeseen events. It ensures financial protection for repairs or replacement of your bike's damaged parts.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Own Damage Bike Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Own Damage Coverage:</strong> Covers the cost of repairs or replacement due to accidents, natural disasters, or fire.</li>
                            <li><strong>✅ Damage to Bike Parts:</strong> Includes protection for all major and minor bike parts like the engine, body, and electrical system.</li>
                            <li><strong>✅ Protection Against Accidents:</strong> Ensures you're covered if your bike gets damaged in an accident, regardless of fault.</li>
                            <li><strong>✅ No Liability for Third-Party:</strong> This insurance is focused solely on the damage to your bike, not covering third-party liabilities.</li>
                            <li><strong>✅ Quick Claim Process:</strong> Simple and efficient claim process for faster repairs or replacements.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Own Damage Bike Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Own Damage Bike Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Own Damage Bike Insurance:</strong></p>
                            <ul>
                                <li>Provides financial protection for damages to your bike caused by accidents, fire, or natural calamities.</li>
                                <li>Ensures your bike stays in good condition, even after unforeseen damage.</li>
                                <li>Minimizes out-of-pocket expenses for repairs or replacements of bike parts.</li>
                                <li>Provides peace of mind knowing that you are covered in the event of an accident or damage.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Coverage Details */}
                <section className="Individual-Health-section">
                    <h2>Coverage Details</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✔ Accidental Damage:</strong> Covers damage caused by accidents, whether you are at fault or not.</li>
                            <li><strong>✔ Fire and Theft Protection:</strong> Ensures coverage in case of bike damage due to fire or theft.</li>
                            <li><strong>✔ Natural Calamities:</strong> Protects against damage caused by natural disasters like floods or storms.</li>
                            <li><strong>✔ Damage to Bike Parts:</strong> Covers repair or replacement costs for damaged bike components, including bodywork and engine parts.</li>
                            <li><strong>✔ Roadside Assistance:</strong> Option to add roadside assistance to help with breakdowns or emergencies.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Own Damage Bike Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Own Damage Bike Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Own Damage Bike Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Bike owners who want to ensure their bike is covered against damages from accidents, fire, or theft.</li>
                                <li>✔ Individuals seeking financial protection for repairs and replacements in case of unforeseen damages.</li>
                                <li>✔ People who want coverage for their bike even when they are at fault in an accident.</li>
                                <li>✔ New bike owners who want to keep their bike in top condition by covering potential damages.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Own_Damage_Bike_Info