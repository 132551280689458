import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import GroupTravelInsuranceImg from '../../assets/images/Group-Travel-Insurance.avif';
import BenefitsImg from '../../assets/images/Group_Travel_Insurance.jpg';
import WhoShouldOptImg from '../../assets/images/groupWhoShouldOptImgtravel.avif';
import { Link } from 'react-router-dom'

function Group_Travel_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Group Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Group Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={GroupTravelInsuranceImg}
                            alt="Group Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Group Travel Insurance is designed for groups traveling together, offering collective coverage for medical emergencies, trip cancellations, baggage loss, and other travel risks. It ensures that every member of the group is protected under a single policy, making it cost-effective and convenient.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Group Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Unified Coverage:</strong> One policy covers all travelers in the group, reducing administrative hassle.</li>
                            <li><strong>✅ Medical Expenses & Emergency Assistance:</strong> Covers medical treatments and emergency evacuations for all members.</li>
                            <li><strong>✅ Trip Cancellation & Delays:</strong> Protects against financial losses due to unexpected cancellations or delays.</li>
                            <li><strong>✅ Baggage & Personal Belongings:</strong> Offers compensation for lost, stolen, or delayed baggage.</li>
                            <li><strong>✅ Customizable Plans:</strong> Flexible options to suit corporate groups, students, family tours, or friends traveling together.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Group Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Group Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why Choose Group Travel Insurance?</strong></p>
                            <ul>
                                <li>Simplifies the process by covering all travelers under a single plan.</li>
                                <li>Ensures cost-effective coverage compared to individual policies.</li>
                                <li>Provides peace of mind with emergency medical coverage.</li>
                                <li>Protects against unexpected expenses like trip delays, loss of belongings, or cancellations.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Group Travel Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Group Travel Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Corporate Group Travel Insurance:</strong> Designed for business teams traveling together.</li>
                            <li><strong>2️⃣ Student Group Travel Insurance:</strong> Ideal for school or college groups going on study tours.</li>
                            <li><strong>3️⃣ Family & Friends Group Travel Insurance:</strong> Covers large family groups or friends traveling together.</li>
                            <li><strong>4️⃣ Religious or Pilgrimage Travel Insurance:</strong> Ensures safety for groups traveling for spiritual or religious purposes.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Group Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Group Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Group Travel Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Companies sending employees on business trips.</li>
                                <li>✔ Schools, universities, and student groups traveling for educational purposes.</li>
                                <li>✔ Large families or groups of friends traveling together.</li>
                                <li>✔ Religious groups or organizations going on pilgrimage tours.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Group_Travel_Info