import React from 'react'
import Navbar from '../../pages/navbar/Navbar'
import Portfolio_Hero from '../Portfolio_Hero/Portfolio_Hero'
import Portfolio_Card from '../Portfolio_Card/Portfolio_Card'
import Portfolio_Build from '../Portfolio_Build/Portfolio_Build'
import Portfolio_About from '../Portfolio_About/Portfolio_About'
import Portfolio_Service from '../Portfolio_service/Portfolio_Service'
import Free_oppointment from '../../LifeInsurance/Free_Opp/Free_oppointment'
import Footer from '../../pages/footer/Footer'
import Copyright from '../../pages/copyright/Copyright'

function Portfolio_Home() {
  return (
    <>
    <Navbar/>
    <Portfolio_Hero/>
    <Portfolio_Card/>
    <Portfolio_Build/>
    <Portfolio_About/>
    <Portfolio_Service/>
    <Free_oppointment/>
    <Footer/>
    <Copyright/>
    </>
  )
}

export default Portfolio_Home