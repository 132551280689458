import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import NationalPensionSchemeImg from '../../assets/images/National_Pension_Scheme.jpg';
import BenefitsImg from '../../assets/images/pension-plan.avif';
import WhoShouldOptImg from '../../assets/images/Pension_Plans_with_Life_Cover.webp';
import { Link } from 'react-router-dom'

function National_Pension_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is National Pension Scheme Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is National Pension Scheme Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={NationalPensionSchemeImg}
                            alt="National Pension Scheme Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                National Pension Scheme (NPS) Insurance is a government-sponsored pension scheme designed to provide a secure retirement income for individuals. It allows investors to contribute to their pension fund during their working years and offers attractive tax benefits along with a variety of investment options to build a retirement corpus.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of National Pension Scheme Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Tax Benefits:</strong> Contributions to NPS are eligible for tax deductions under Section 80C and 80CCD of the Income Tax Act.</li>
                            <li><strong>✅ Flexible Investment Options:</strong> Choose between equity, corporate bonds, and government securities based on your risk tolerance.</li>
                            <li><strong>✅ Low-Cost Structure:</strong> NPS has low administrative fees, making it an affordable retirement savings option.</li>
                            <li><strong>✅ Voluntary Contributions:</strong> Contributions are voluntary and can be made regularly or as a lump sum, allowing for flexibility.</li>
                            <li><strong>✅ Portability:</strong> NPS accounts are portable across jobs and locations, enabling seamless transfer of pension funds.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of National Pension Scheme Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of National Pension Scheme Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need National Pension Scheme Insurance:</strong></p>
                            <ul>
                                <li>Ensures a secure retirement with regular pension payments after retirement.</li>
                                <li>Offers tax deductions and rebates, reducing your taxable income.</li>
                                <li>Provides flexibility in contribution amounts and investment choices.</li>
                                <li>Offers low-cost management, which maximizes the returns on your pension funds.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of National Pension Scheme Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of National Pension Scheme Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Tier I Account:</strong> The primary NPS account, offering tax benefits and long-term retirement savings.</li>
                            <li><strong>2️⃣ Tier II Account:</strong> An optional, flexible savings account with no tax benefits, suitable for those who want to invest beyond the mandatory contribution.</li>
                            <li><strong>3️⃣ Corporate NPS:</strong> Offered by employers to employees, allowing corporate contributions along with individual investments.</li>
                            <li><strong>4️⃣ Atal Pension Yojana:</strong> A government-backed NPS scheme targeted at the unorganized sector to offer a fixed pension after retirement.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for National Pension Scheme Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for National Pension Scheme Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for National Pension Scheme Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals looking for a secure and tax-efficient way to save for retirement.</li>
                                <li>✔ Employees seeking to build a long-term pension fund with employer contributions.</li>
                                <li>✔ Self-employed people wanting to secure their future income and avail of tax benefits.</li>
                                <li>✔ Those who want flexibility in managing their pension contributions and investments.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default National_Pension_Info