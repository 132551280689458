import React, { useState } from "react";
import "./Life_Insurance_Hero.css";
import lifeHeroImage from "../../assets/images/life_insurance_hero.avif"; // Importing the image
import { Link } from "react-router-dom";

function Life_Insurance_Hero() {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <>
      <section className="life-in-custom-hero">
        <div className="life-in-custom-hero-background">
          {/* Gradient overlay */}
          <div className={`life-in-custom-hero-overlay ${hovered ? "hovered" : ""}`} />

          {/* Hero image */}
          <img
            src={lifeHeroImage}
            alt="Life Insurance"
            className="life-in-custom-hero-image"
          />
        </div>

        <div className="life-in-custom-hero-content">
          <h1 className="life-in-custom-hero-title">
            Life Insurance for a <span className="highlight">Secure Future</span>
          </h1>
          <p className="life-in-custom-hero-subtitle">
            Protect your loved ones with comprehensive life insurance plans
            tailored to your needs.
          </p>
          <Link to="/Signup">
            <button
              className="life-in-custom-hero-button"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Get Started
            </button>
          </Link>
        </div>
      </section>
    </>
  );
}

export default Life_Insurance_Hero;