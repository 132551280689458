import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import HospitalCashInsuranceImg from '../../assets/images/HospitalCashInsuranceImg.avif';
import BenefitsHospitalImg from '../../assets/images/BenefitsHospitalImg.avif';
import WhoShouldOptImg from '../../assets/images/Hospital _Cash_Insurance.avif';
import { Link } from 'react-router-dom'

function Hospital_Cash_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Hospital Cash Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Hospital Cash Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={HospitalCashInsuranceImg}
                            alt="Hospital Cash Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Hospital Cash Insurance is a health policy that provides a fixed daily cash benefit during hospitalization. This helps cover incidental expenses that may not be included in standard health insurance policies.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Hospital Cash Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Daily Cash Benefit:</strong> Fixed amount per day of hospitalization.</li>
                            <li><strong>✅ Covers Incidental Expenses:</strong> Helps with expenses like food, transport, and other non-medical costs.</li>
                            <li><strong>✅ No Restrictions on Usage:</strong> Cash benefit can be used as needed.</li>
                            <li><strong>✅ Affordable Premiums:</strong> Low-cost insurance with significant financial support.</li>
                            <li><strong>✅ Complements Existing Health Insurance:</strong> Works alongside standard medical policies.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Hospital Cash Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsHospitalImg}
                            alt="Benefits of Hospital Cash Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Hospital Cash Insurance:</strong></p>
                            <ul>
                                <li>Provides additional financial security during hospitalization.</li>
                                <li>Covers extra expenses not included in standard health policies.</li>
                                <li>No need to submit medical bills to claim benefits.</li>
                                <li>Flexible usage for any hospitalization-related costs.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Hospital Cash Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Hospital Cash Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Individual Hospital Cash Plan:</strong> Provides fixed daily cash for personal hospitalization.</li>
                            <li><strong>2️⃣ Family Hospital Cash Plan:</strong> Covers all family members under a single plan.</li>
                            <li><strong>3️⃣ Corporate Hospital Cash Plan:</strong> Offered as an employee benefit by companies.</li>
                            <li><strong>4️⃣ Government-Sponsored Plans:</strong> Special schemes for financially weaker sections.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Hospital Cash Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Hospital Cash Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Hospital Cash Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals looking for additional financial backup during hospitalization.</li>
                                <li>✔ Families seeking extra support for unexpected hospital stays.</li>
                                <li>✔ Employees who want additional coverage alongside their health insurance.</li>
                                <li>✔ Self-employed individuals who may face income loss during hospitalization.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                        <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Hospital_Cash_Info