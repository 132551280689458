import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import ImmediateAnnuityInsuranceImg from '../../assets/images/Immediate_Annuity.jpg';
import BenefitsImg from '../../assets/images/Benifit-annuity.avif';
import WhoShouldOptImg from '../../assets/images/Annuity-BenefitsImg.avif';
import { Link } from 'react-router-dom'

function Immediate_Annuity_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Immediate Annuity Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Immediate Annuity Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={ImmediateAnnuityInsuranceImg}
                            alt="Immediate Annuity Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Immediate Annuity Insurance is a type of annuity that begins providing regular income payments almost immediately after the initial investment is made. It is designed to offer a steady income stream, often used for retirement or long-term financial security.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Immediate Annuity Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Immediate Income Payments:</strong> Begins payouts immediately after the purchase of the annuity.</li>
                            <li><strong>✅ Lifetime Income Option:</strong> Provides a guaranteed income for life, reducing the risk of outliving savings.</li>
                            <li><strong>✅ Fixed or Variable Payments:</strong> Offers both fixed and variable payment options depending on the plan chosen.</li>
                            <li><strong>✅ Flexibility:</strong> Allows customization of payout frequency (monthly, quarterly, or annually).</li>
                            <li><strong>✅ No Ongoing Contributions:</strong> Requires a one-time premium or lump sum payment to start receiving benefits.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Immediate Annuity Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Immediate Annuity Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Immediate Annuity Insurance:</strong></p>
                            <ul>
                                <li>Provides a reliable and predictable income source, ideal for retirement planning.</li>
                                <li>Helps reduce the risk of running out of money during retirement.</li>
                                <li>Offers peace of mind with guaranteed income for a set period or for life.</li>
                                <li>Eliminates the need for ongoing contributions, with a one-time investment to secure income.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Immediate Annuity Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Immediate Annuity Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Fixed Immediate Annuity:</strong> Offers a set, predictable amount of income for the duration of the annuity.</li>
                            <li><strong>2️⃣ Variable Immediate Annuity:</strong> Offers income that can fluctuate based on the performance of underlying investments.</li>
                            <li><strong>3️⃣ Joint Immediate Annuity:</strong> Provides income for both partners and continues after one partner's death.</li>
                            <li><strong>4️⃣ Period Certain Immediate Annuity:</strong> Provides income for a specific period, ensuring payments even if the annuitant passes away before the period ends.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Immediate Annuity Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Immediate Annuity Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Immediate Annuity Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals nearing retirement who need a steady and predictable income stream.</li>
                                <li>✔ Retirees looking for a guaranteed income to cover essential living expenses.</li>
                                <li>✔ People who want to reduce the risk of outliving their savings.</li>
                                <li>✔ Those who prefer a one-time lump sum payment rather than ongoing contributions.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Immediate_Annuity_Info