import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import BenefitsImg from '../../assets/images/travel-insurance.avif';
import SingleTripTravelInsuranceImg from '../../assets/images/single-travel.avif';
import WhoShouldOptImg from '../../assets/images/Single_Trip_Travel_Insurance.avif';
import { Link } from 'react-router-dom'

function Single_Travel_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Single Trip Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Single Trip Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={SingleTripTravelInsuranceImg}
                            alt="Single Trip Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Single Trip Travel Insurance is a policy that provides coverage for one specific journey, offering financial protection against medical emergencies, trip cancellations, baggage loss, and other unforeseen travel-related incidents. It is ideal for individuals, couples, or families planning a one-time trip.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Single Trip Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ One-Time Coverage:</strong> Protection for a single trip, covering the entire duration of your journey.</li>
                            <li><strong>✅ Medical Emergencies & Evacuation:</strong> Covers medical expenses, hospital stays, and emergency evacuation if required.</li>
                            <li><strong>✅ Trip Cancellation & Delay:</strong> Provides financial protection against trip disruptions due to unforeseen circumstances.</li>
                            <li><strong>✅ Lost or Delayed Baggage:</strong> Offers compensation for lost, stolen, or delayed baggage.</li>
                            <li><strong>✅ Customizable Plans:</strong> Flexible coverage for solo travelers, families, or business travelers.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Single Trip Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Single Trip Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why Choose Single Trip Travel Insurance?</strong></p>
                            <ul>
                                <li>Affordable and comprehensive coverage for short-term trips.</li>
                                <li>Ensures peace of mind with medical and travel protection.</li>
                                <li>Helps recover financial losses due to trip cancellations or delays.</li>
                                <li>Provides support in case of lost documents, baggage, or personal belongings.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Single Trip Travel Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Single Trip Travel Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Individual Single Trip Insurance:</strong> Coverage for solo travelers.</li>
                            <li><strong>2️⃣ Family Single Trip Insurance:</strong> Covers the entire family under one plan.</li>
                            <li><strong>3️⃣ Business Single Trip Insurance:</strong> Ideal for short-term work-related travels.</li>
                            <li><strong>4️⃣ Senior Citizen Single Trip Insurance:</strong> Special plans for older travelers with additional health benefits.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Single Trip Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Single Trip Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Single Trip Travel Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Tourists taking a one-time vacation.</li>
                                <li>✔ Business travelers going for short-term work assignments.</li>
                                <li>✔ Students traveling abroad for short-term study programs.</li>
                                <li>✔ Senior citizens looking for temporary travel coverage.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Single_Travel_Info