import './Loan_Service.css'

function Loan_Service() {
    return (
        <>
            <div className="mf-login-investment-row">
                {/* Left Side - Text Section */}
                <div className="mf-login-investment-text">
                    <div className="mf-login-investment-text-container">
                        <div className="mf-login-investment-title">
                            <h1 className="mf-login-investment-h2">Loan Category</h1>
                        </div>
                        <div className="mf-login-investment-description">
                            <ul>
                                <li><strong className="mf-strong">Lower Interest Rates:</strong> Competitive interest rates make loans affordable, reducing the overall cost of borrowing.</li>
                                <li><strong className="mf-strong">Quick Approval and Disbursal:</strong> Streamlined processes ensure fast approval and quick disbursal of funds.</li>
                                <li><strong className="mf-strong">No Need to Liquidate Investments:</strong> Secure loans without selling off your valuable investments.</li>
                                <li><strong className="mf-strong">Use Funds for Various Purposes:</strong> Loans can be used for personal expenses, business needs, education, or medical emergencies.</li>
                                <li><strong className="mf-strong">Credit Score Independence:</strong> Some loan options are available even for individuals with lower credit scores.</li>
                                <li><strong className="mf-strong">Potential Tax Benefits:</strong> Certain loans, like home loans and education loans, provide tax benefits under applicable tax laws.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Right Side - Image Section */}
                <div className="mff-login-investment-image"></div>
            </div>
        </>
    );
}

export default Loan_Service;
