import React, { useState, useEffect, useRef } from 'react';
import './Life_Term_Insurance.css'; // Updated CSS import
import Regular_Term from '../../assets/images/Regular_Term_Life_Insurance.avif';
import Endowment_Plan from '../../assets/images/Endowment_Plan.jpg';
import Money_Back_Plan from '../../assets/images/Money_Back_Plan.jpg';
import Group_Life from '../../assets/images/Group_Life_Insurance.jpg';
import Child_Plan from '../../assets/images/Child_Plan_Life_Insurance.jpg';
import Accidental_Death_Disability from '../../assets/images/Accidental_Death_Disability.jpg';
import Affordable_Premiums from '../../assets/images/Affordable_Premiums.png';
import simple_and_clear from '../../assets/images/simple_and_clear.png';
import Flexible_Terms from '../../assets/images/Flexible_Terms.png';
import life_term_insurances from '../../assets/images/insurancehero.avif'; // Add correct path to your image
import life_term_insurance from '../../assets/images/lifeinsurence.jpg';
import ulip_insurance from '../../assets/images/ulip-insurance.jpg';
import Nri_Insurance from '../../assets/images/Group-Travel-Insurance.avif';
import { Link } from 'react-router-dom';
import { ImCross } from 'react-icons/im';
import { link } from 'd3';

function Life_Term_Insurance() {
    const carousell = useRef(null);
    const intervalRef = useRef(null); // Ref to store the interval ID
    const [angle, setAngle] = useState(0); // State to store the rotation angle

    const carousellItems = [
        {
            img: Regular_Term, // Add appropriate image path for Term Life Insurance
            title: 'Term Life Insurance',
            description: 'Provides affordable coverage for a fixed term, ensuring financial security for your loved ones in case of unforeseen circumstances.',
            link: '/term-insurance' // Add the appropriate link
        },
        {
            img: Endowment_Plan, // Add appropriate image path for Endowment Plans
            title: 'Endowment Plans',
            description: 'Offers a combination of insurance and savings, providing both risk cover and a lump sum benefit at maturity or death.',
            link: '/endowment-plans' // Add the appropriate link
        },
        {
            img: Money_Back_Plan, 
            title: 'Money-Back Plans',
            description: 'Provides periodic payouts during the policy term and a lump sum amount at maturity, offering a dual benefit of protection and savings.',
            link: '/money-back-plans' 
        },
        {
            img: Group_Life, // Add appropriate image path for Group Life Insurance
            title: 'Group Life Insurance',
            description: 'Provides life coverage for groups of people, usually provided by employers or organizations as a benefit to employees or members.',
            link: '/group-life-insurance' // Add the appropriate link
        },
        {
            img: Child_Plan, // Add appropriate image path for Child Plans
            title: 'Child Plans',
            description: 'Designed to secure your child\'s future education and marriage with the added benefit of life coverage and savings.',
            link: '/child-plans' // Add the appropriate link
        },
        {
            img: Accidental_Death_Disability, // Add appropriate image path for Accidental Death and Disability Plans
            title: 'Accidental Death and Disability Plans',
            description: 'Provides financial protection in the event of accidental death or disability, offering a lump sum payout or regular income.',
            link: '/accidental-death-disability-plans' // Add the appropriate link
        },
        {
            img: Nri_Insurance, // Add appropriate image path for NRI Insurance
            title: 'NRI Insurance',
            description: 'Tailored insurance plans designed for Non-Resident Indians (NRIs), ensuring financial security and investment growth while living abroad.',
            link: '/nri-insurance'
        },
        {
            img: ulip_insurance, // Add appropriate image path for ULIP Insurance
            title: 'ULIP Insurance',
            description: 'A Unit Linked Insurance Plan (ULIP) that combines investment and insurance, allowing policyholders to grow wealth while securing life coverage.',
            link: '/ulip-insurance' // Updated the appropriate link
        }
    ];

    const totalCards = carousellItems.length;
    const rotationAngle = 360 / totalCards;

    const startSliding = () => {
        intervalRef.current = setInterval(() => {
            setAngle((prevAngle) => {
                const newAngle = prevAngle - rotationAngle;
                if (carousell.current) {
                    carousell.current.style.transform = `translateZ(-25rem) rotateY(${newAngle}deg)`;
                }
                return newAngle;
            });
        }, 2000);
    };

    const stopSliding = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        startSliding(); // Start the sliding on component mount
        return () => stopSliding(); // Clear the interval on component unmount
    }, [rotationAngle]);

    return (
        <>
            <div className="new-header">
                <Link to="/life-Insurance" className="login-close-link">
                    <ImCross className="login-close-icon" />
                </Link>
                <h1 className="new-title">Life Insurance</h1>
                <p className="new-subtitle">Affordable and Flexible Protection for Your Family</p>

                <div className="new-intro-section">
                    <p className="new-intro-text">
                        Life Insurance provides long-term financial protection for your loved ones in the event of your passing. It offers various plans, including term, whole life, and endowment options, ensuring that your family's future is secured, whether you're here or not. Life insurance helps cover expenses like mortgages, education, and daily living costs, providing peace of mind for your family.
                    </p>
                </div>

                <div className="new-features-section">
                    <h2 className="new-section-title">Key Features</h2>
                    <div className="new-features">
                        <div className="new-feature-card">
                            <img src={Affordable_Premiums} className="new-type-image" alt="Affordable Premiums"></img>
                            <h3>Affordable Premiums</h3>
                            <p> Term life insurance policies are significantly more affordable compared to permanent insurance plans,
                                making them a budget-friendly option for most individuals and families.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={simple_and_clear} className="new-type-image" alt="Simple and Clear"></img>
                            <h3>Simple and Clear</h3>
                            <p>These policies come with straightforward and easy-to-understand terms,
                                offering pure life coverage without any confusing investment components.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={Flexible_Terms} className="new-type-image" alt="Flexible Terms"></img>
                            <h3>Flexible Terms</h3>
                            <p>With a variety of term lengths to choose from,
                                you can select the duration that best aligns with your specific financial goals and personal needs.</p>
                        </div>
                    </div>
                </div>

                <div className="carousell-container">
                    <h2 className="home_insurance-section-title">Types of Life Insurance</h2>
                    <div className="carousell">
                        <div
                            className="carousell__cards"
                            ref={carousell}
                            onMouseEnter={stopSliding} // Stop sliding on mouse enter
                            onMouseLeave={startSliding} // Resume sliding on mouse leave
                        >
                            {carousellItems.map((item, index) => (
                                <Link
                                    to={item.link} // Link to the desired page
                                    key={index}
                                    className="carousell__card"
                                    style={{
                                        transform: `rotateY(${index * rotationAngle}deg) translateZ(25rem)`
                                    }}
                                >
                                    <div className="carousell__img-container">
                                        <img className="carousell__img" src={item.img} alt={`Cover for ${item.title}`} />
                                    </div>
                                    <div className="carousell__content">
                                        <h3 className="carousell__title">{item.title}</h3>
                                        <p className="carousell__para">{item.description}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="insurance-cta-container">
                    <div className="insurance-cta-image-container">
                        <img
                            src={life_term_insurances}
                            alt="Life term insurance illustration"
                            className="insurance-cta-image"
                        />
                    </div>
                    <div className="insurance-cta-content">
                        <h1 className="insurance-cta-title">Why Choose Life Insurance?</h1>
                        <p className="insurance-cta-description">
                            Life insurance is a crucial step in ensuring the financial security of your loved ones in case of the unexpected.
                            It provides a safety net to protect your family’s financial future. Whether you opt for term life, whole life, or other plans,
                            life insurance can cover funeral expenses, mortgages, and daily living costs, making sure your family is well-cared for in your absence.
                        </p>
                        <ul className="insurance-cta-benefits">
                            <li><strong>Comprehensive Protection:</strong> Life insurance ensures your family is financially supported in case of untimely demise.</li>
                            <li><strong>Financial Security:</strong> Covers your family’s future needs, including housing, education, and daily expenses.</li>
                            <li><strong>Variety of Plans:</strong> Choose from different coverage options (Term, Whole, Endowment) to suit your needs.</li>
                            <li><strong>Cash Value (Whole Life):</strong> Some policies accumulate cash value you can borrow against or withdraw.</li>
                            <li><strong>Tax Benefits:</strong> Premiums may be tax-deductible, and the death benefit is often tax-free.</li>
                            <li><strong>Peace of Mind:</strong> Ensure your loved ones are financially secure, reducing future worries.</li>
                        </ul>
                        <p className="insurance-cta-footer">
                            Secure your family’s future with life insurance—protect what matters most.
                        </p>

                        <Link to="/Signup">
                            <button className="insurance-cta-button">Secure your Future</button>
                        </Link>
                    </div>
                </div>

                <div className="new-benefits-section">
                    <h2 className="new-section-title">Benefits of Life Insurance</h2>
                    <div className='new-benefits-inner-section'>
                        <div>
                            <ul className="new-benefits-list">
                                <li>Life insurance provides financial protection for your loved ones, ensuring they are supported in case of your unexpected passing.</li>
                                <li>It is an affordable way to secure the future of your family, with options ranging from term life to whole life insurance, allowing you to select a policy that fits your needs and budget.</li>
                                <li>Life insurance policies are generally easy to understand, with clear terms, no complicated investment structures, and a simple death benefit payout to beneficiaries.</li>
                                <li>It covers you for various life stages, ensuring that your family is financially protected during important milestones like buying a home, raising children, or preparing for retirement.</li>
                                <li>Life insurance policies, especially whole life and endowment plans, can build cash value over time, which you can use for loans, withdrawals, or other financial needs.</li>
                                <li>Depending on the plan, life insurance can offer valuable tax benefits, potentially reducing your overall tax liability while securing financial coverage for your loved ones.</li>
                                <li>For more personalized advice and assistance, book a free appointment with our experts today to discuss the best life insurance options for you.</li>
                            </ul>

                            <div className="new-cta-section">
                                <Link to='/book-free-appointment' className="new-cta-button">Book free appointment</Link>
                            </div>
                        </div>
                        <div>
                            <img src={life_term_insurance} alt="Benefits of Life Term Insurance" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Life_Term_Insurance;