import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import GuaranteedPeriodAnnuityInsuranceImg from '../../assets/images/Annuity-BenefitsImg.avif';
import BenefitsImg from '../../assets/images/Deferred_Annuity.avif';
import WhoShouldOptImg from '../../assets/images/Annuity_Certain.webp';
import { Link } from 'react-router-dom'

function Guaranteed_Annuity_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Guaranteed Period Annuity Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Guaranteed Period Annuity Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={GuaranteedPeriodAnnuityInsuranceImg}
                            alt="Guaranteed Period Annuity Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Guaranteed Period Annuity Insurance is a type of annuity that guarantees income payments for a fixed period, regardless of whether the annuitant is alive or not. It ensures that the payments continue for the agreed-upon period, offering security to beneficiaries in case of early death.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Guaranteed Period Annuity Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Guaranteed Payments:</strong> Ensures income payments for a fixed period, even if the annuitant passes away.</li>
                            <li><strong>✅ Financial Security:</strong> Provides assurance of payments for a set number of years, regardless of life expectancy.</li>
                            <li><strong>✅ Customizable Duration:</strong> Offers flexibility to choose the guaranteed payment period based on individual preferences.</li>
                            <li><strong>✅ No Risk of Outliving Payments:</strong> The income continues for the chosen period, protecting against the risk of outliving savings.</li>
                            <li><strong>✅ Beneficiary Protection:</strong> Payments may be passed on to beneficiaries if the annuitant dies during the guaranteed period.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Guaranteed Period Annuity Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Guaranteed Period Annuity Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Guaranteed Period Annuity Insurance:</strong></p>
                            <ul>
                                <li>Offers a stable and predictable income for a fixed period, perfect for financial planning.</li>
                                <li>Reduces the uncertainty of outliving your savings by guaranteeing income payments.</li>
                                <li>Provides protection for your beneficiaries, ensuring they receive payments if you pass away early.</li>
                                <li>Customizable options allow you to select a guaranteed period that aligns with your financial goals.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Guaranteed Period Annuity Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Guaranteed Period Annuity Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ 5-Year Guaranteed Period:</strong> Offers income payments for a guaranteed 5-year period.</li>
                            <li><strong>2️⃣ 10-Year Guaranteed Period:</strong> Guarantees income for a 10-year period, providing more extended coverage.</li>
                            <li><strong>3️⃣ 15-Year Guaranteed Period:</strong> Ensures payment for a 15-year period, offering long-term financial security.</li>
                            <li><strong>4️⃣ Lifetime with Guaranteed Period:</strong> Combines lifetime income with a guaranteed payment period, offering the best of both worlds.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Guaranteed Period Annuity Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Guaranteed Period Annuity Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Guaranteed Period Annuity Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals who want a guaranteed income for a fixed period, regardless of their life expectancy.</li>
                                <li>✔ Retirees looking for stable and predictable income to cover essential living expenses.</li>
                                <li>✔ Those who want to protect their beneficiaries by ensuring they receive payments in case of early death.</li>
                                <li>✔ People who prefer long-term financial planning with customized options for payment durations.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Guaranteed_Annuity_Info