import Navbar from '../../pages/navbar/Navbar'
import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer';
import TenantCoverInsuranceImg from '../../assets/images/home_insurance.avif';
import BenefitsTenant from '../../assets/images/Home-Content-Cover.avif';
import WhoShouldOptImg from '../../assets/images/Structural-Cover-Ben.jpg';
import { Link } from 'react-router-dom'

function Tenant_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Tenant Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Tenant Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={TenantCoverInsuranceImg}
                            alt="Tenant Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Tenant Cover Insurance provides protection for tenants against potential financial liabilities or damage they may cause while renting a property. It helps cover expenses like property damage, theft, or legal issues that might arise during the tenancy.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Tenant Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Property Damage Protection:</strong> Covers damage caused to rental property during the tenancy.</li>
                            <li><strong>✅ Theft Coverage:</strong> Protects tenants from loss or theft of personal belongings within the rental property.</li>
                            <li><strong>✅ Legal Expense Coverage:</strong> Supports legal costs in case of disputes with landlords or other tenants.</li>
                            <li><strong>✅ Personal Liability Protection:</strong> Provides coverage for accidental injury to others or damage to their property.</li>
                            <li><strong>✅ Loss of Rent Coverage:</strong> Protects tenants from situations where they are unable to pay rent due to unforeseen circumstances.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Tenant Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsTenant}
                            alt="Benefits of Tenant Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Tenant Cover Insurance:</strong></p>
                            <ul>
                                <li>Provides financial protection in case of accidental property damage or theft during the tenancy.</li>
                                <li>Covers legal expenses arising from disputes with landlords or other tenants.</li>
                                <li>Reduces the risk of financial burden due to liability claims or loss of rent payments.</li>
                                <li>Offers peace of mind to tenants, knowing they are covered for unexpected situations.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Tenant Cover Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Tenant Cover Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Standard Tenant Cover:</strong> Basic coverage for property damage, theft, and personal liability.</li>
                            <li><strong>2️⃣ Comprehensive Tenant Cover:</strong> Full protection, including legal expenses, loss of rent, and accidental damage.</li>
                            <li><strong>3️⃣ Renters Legal Protection:</strong> Specialized coverage for legal costs in case of landlord-tenant disputes.</li>
                            <li><strong>4️⃣ Customizable Tenant Insurance:</strong> Tailored coverage options based on individual needs and property type.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Tenant Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Tenant Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Tenant Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Tenants who rent residential or commercial properties and want financial protection.</li>
                                <li>✔ Individuals who want to avoid costly legal disputes or property damage charges.</li>
                                <li>✔ People renting properties with valuable personal belongings that need protection.</li>
                                <li>✔ Those seeking peace of mind regarding potential tenant-related liabilities.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Tenant_Cover_Info