import { Link } from 'react-router-dom';
import './About_Development.css';

function About_Development() {
    return (
        <>
            <div className="About_Vision_container">
                <div className="About_Vision_left-section">
                    <div className="About_Vision_card About_Vision_vision">
                        <h2 className='About_Vision_h2'>Our Vision</h2>
                        <p className='About_Vision_para'>
                            Our vision is to empower individuals and businesses with innovative financial solutions. We aim to be the leading provider of life insurance, financial planning, portfolio management services (PMS), and loan solutions that contribute to financial security and prosperity.
                        </p>
                    </div>
                    <div className="About_Vision_card About_Vision_mission">
                        <h2 className='About_Vision_h2'>Our Mission</h2>
                        <p className='About_Vision_para'>
                            We are committed to delivering personalized financial services with a focus on life insurance, comprehensive financial planning, PMS, and loan services. Our mission is to ensure our clients' financial growth and security with strategies tailored to their unique needs.
                        </p>
                    </div>
                    <div className="About_Vision_card About_Vision_history">
                        <h2 className='About_Vision_h2'>Our History</h2>
                        <p className='About_Vision_para'>
                            With over two decades of experience in the financial industry, Upholic has grown from a small advisory service to a full-fledged financial solutions provider. Our team’s expertise in life insurance, financial planning, PMS, and loan services has been trusted by thousands of clients over the years.
                        </p>
                    </div>
                </div>
                <div className="About_Vision_right-section">
                    <h1 className='About_Vision_h1'>Our Plan Makes You Feel More Comfortable in Managing Your Finances</h1>
                    <p className='About_Vision_para'>
                        Whether you're looking to secure your future with life insurance, optimize your investments through financial planning, or explore loan options for your business or personal needs, we provide holistic solutions for all your financial goals.
                    </p>
                    <Link to="/book-free-appointment"><button className='About_Vision_button'>For more Benfits</button></Link>
                </div>
            </div>
        </>
    )
}

export default About_Development