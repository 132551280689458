import { useEffect, useRef } from "react";
import "./Testimonials.css";
import sandeep from '../../assets/images/111_profile.png';
import Satish from '../../assets/images/110_profile.png';
import Ram_lal from '../../assets/images/Ram_lal_adh.png';
import Ram_dulare from '../../assets/images/Ram_dulare.jpg';

function Testimonials() {
    const scrollRef = useRef(null);

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        if (scrollContainer) {
            const clone = scrollContainer.innerHTML;
            scrollContainer.innerHTML += clone; // Duplicate for smooth scrolling
        }
    }, []);

    return (
        <section className="testimonials-section">
            <div className="testimonials-container">
                <h2 className="testimonials-title">What Our Clients Say</h2>
                <p className="testimonials-subtitle">Hear from our satisfied customers about their experiences.</p>
                <div className="scroll-wrapper">
                    <div className="scroll-content" ref={scrollRef}>
                        <div className="testimonial-card">
                            <img className="testimonial-avatar" src={Ram_dulare} alt="Ram Dulare" />
                            <p className="testimonial-text">"The deposit process was seamless and quick. Highly recommend their services!"</p>
                            <div className="testimonial-footer">
                                <h3 className="testimonial-service">Deposit</h3>
                                <span className="testimonial-author">Ram Dulare</span>
                            </div>
                        </div>

                        <div className="testimonial-card">
                            <img className="testimonial-avatar" src={Ram_lal} alt="Ram Lal" />
                            <p className="testimonial-text">"Fast and secure deposits. The team is very supportive and professional."</p>
                            <div className="testimonial-footer">
                                <h3 className="testimonial-service">Deposit</h3>
                                <span className="testimonial-author">Ram Lal</span>
                            </div>
                        </div>

                        <div className="testimonial-card">
                            <img className="testimonial-avatar" src={sandeep} alt="Sandeep Shukla" />
                            <p className="testimonial-text">"PMS services here have transformed my portfolio. Great expertise and insights!"</p>
                            <div className="testimonial-footer">
                                <h3 className="testimonial-service">PMS</h3>
                                <span className="testimonial-author">Sandeep Shukla</span>
                            </div>
                        </div>

                        <div className="testimonial-card">
                            <img className="testimonial-avatar" src={Satish} alt="Satish Kumar" />
                            <p className="testimonial-text">"Excellent PMS services! My investments are now yielding great returns."</p>
                            <div className="testimonial-footer">
                                <h3 className="testimonial-service">PMS</h3>
                                <span className="testimonial-author">Satish Kumar</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;