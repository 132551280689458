import { Link } from "react-router-dom";
import MutualFundImage from "../../assets/images/about_pms_img.jpg";

function Portfolio_Build() {
  return (
    <>
      <div className="mutual-fund-try-container">
        <div className="mutual-fund-try-content" data-aos="fade-up">
          <h3 className="mutual-fund-try-subtitle" data-aos="fade-up">
            Portfolio Management
          </h3>
          <h1 className="mutual-fund-try-title" data-aos="fade-up">
            Optimize Your Wealth with Professional Portfolio Management
          </h1>
          <p className="mutual-fund-try-description" data-aos="fade-up">
            Our expert Portfolio Management Services help you strategically invest and grow your wealth with personalized financial solutions.
          </p>
          <Link to="/Signup">
            <button className="mutual-fund-try-button" data-aos="fade-up">
              Get Started
            </button>
          </Link>
        </div>
        <div className="mutual-fund-try-image-container" data-aos="fade-up">
          <img
            src={MutualFundImage}
            alt="Portfolio Management illustration"
            className="mutual-fund-try-image"
          />
        </div>
      </div>
    </>
  );
}

export default Portfolio_Build;