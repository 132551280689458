import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Pms_insert_user_kyc.css";

function Pms_insert_user_kyc() {
  const initialFormData = {
    username: "",
    user_id: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    mobile_number: "",
    email_address: "",
    marital_status: "",
    gender: "",
    nominee_type: "",
    nominee_name: "",
    bank_name: "",
    bank_account: "",
    ifsc_code: "",
    category: "",
    deposit_id: "", // Add deposit_id
  };

  const [formData, setFormData] = useState(initialFormData);
  const [aadhaarImage, setAadhaarImage] = useState(null);
  const [panImage, setPanImage] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    if (formData.user_id) {
      fetchUserData();
    }
  }, [formData.user_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // User ID validation: only numbers
    if (name === "user_id" && !/^\d*$/.test(value)) {
      return; // Only numbers are allowed for user_id
    }

    // First and Last Name validation: only alphabets and space (no numbers or special characters)
    if (name === "first_name" || name === "last_name" || name === "nominee_name") {
      if (!/^[A-Za-z\s]*$/.test(value)) {
        return; // Only alphabetic characters and space allowed
      }
    }

    // Mobile number validation: only numbers allowed
    if (name === "mobile_number" && !/^\d*$/.test(value)) {
      return; // Only numbers are allowed for mobile number
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileInputChange = (e) => {
    const { name, files } = e.target;
    if (name === "aadhaar_image") {
      setAadhaarImage(files[0]);
    } else if (name === "pan_image") {
      setPanImage(files[0]);
    } else if (name === "profile_picture") {
      setProfilePicture(files[0]);
    }
  };

  const validatePhoneNumber = () => {
    const phone = formData.mobile_number;
    if (phone.length !== 10 || !/^\d{10}$/.test(phone)) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      return false;
    }
    return true;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validatePhoneNumber()) {
      return;
    }
  
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append("aadhaar_image", aadhaarImage);
    formDataToSend.append("pan_image", panImage);
    formDataToSend.append("profile_picture", profilePicture);
  
    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/userkyc.php",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("kyc response:", response);
  
      if (response.data.success) {
        setSuccessMessage(response.data.message || "Record inserted successfully!");
        setErrorMessage("");
        resetForm();
      } else {
        setErrorMessage(response.data.message || "Operation failed");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      if (error.response) {
        // Server responded with a status code outside 2xx
        setErrorMessage(
          error.response.data?.message || 
          `Server error: ${error.response.status}`
        );
      } else if (error.request) {
        // No response received
        setErrorMessage("No response from server. Please try again later.");
      } else {
        // Something happened in setting up the request
        setErrorMessage("Request setup error: " + error.message);
      }
      setSuccessMessage("");
    }
  };



  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `https://www.upholic.in/Upholic_Api/fetch_kyc.php?user_id=${formData.user_id}`
      );
      console.log("fetch kyc response:", response);

      if (response.data.status === "success") {
        const fetchedData = response.data.data;

        setFormData((prevData) => ({
          ...prevData,
          ...{
            username: fetchedData.username || fetchedData["Username"] || "",
            first_name: fetchedData.first_name || fetchedData["Name"] || "",
            last_name: fetchedData.last_name || "",
            date_of_birth: fetchedData.date_of_birth || "",
            mobile_number:
              fetchedData.mobile_number || fetchedData["Phone-Number"] || "",
            email_address:
              fetchedData.email_address || fetchedData["E-Mail"] || "",
            marital_status: fetchedData.marital_status
              ? fetchedData.marital_status.charAt(0).toUpperCase() +
              fetchedData.marital_status.slice(1).toLowerCase()
              : "",
            gender: fetchedData.gender
              ? fetchedData.gender.charAt(0).toUpperCase() +
              fetchedData.gender.slice(1)
              : "",
            nominee_type: fetchedData.nominee_type
              ? fetchedData.nominee_type.charAt(0).toUpperCase() +
              fetchedData.nominee_type.slice(1)
              : "",
            nominee_name: fetchedData.nominee_name || "",
            bank_name: fetchedData.bank_name || "",
            bank_account: fetchedData.bank_account || "",
            ifsc_code: fetchedData.ifsc_code || "",
            category: fetchedData.category
              ? fetchedData.category.charAt(0).toUpperCase() +
              fetchedData.category.slice(1)
              : "",
            deposit_id: fetchedData.deposit_id || "", // Add deposit_id
          },
          user_id: prevData.user_id,
        }));
      } else {
        setErrorMessage(response.data.message);
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorMessage("An error occurred while fetching user data.");
      setSuccessMessage("");
    }
  };

  const resetForm = () => {
    setFormData({
      ...initialFormData,
      user_id: "",
    });
    setAadhaarImage(null);
    setPanImage(null);
    setProfilePicture(null);
    setErrorMessage("");
  };

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <>
      <div className="viewall-container">
        <div className="section">
          <div className="section-title" onClick={toggleAccordion}>
            KYC (Customer Data)
          </div>
          {accordionOpen && (
            <>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              <form
                className="Pms_insert_user_kyc-form"
                onSubmit={handleSubmit}
              >
                <div className="Pms_insert_user_kyc-row">
                  <label>User ID:</label>
                  <input
                    type="text"
                    name="user_id"
                    value={formData.user_id}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Username:</label>
                  <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="Pms_insert_user_kyc-row">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Date Of Birth</label>
                  <input
                    type="date"
                    name="date_of_birth"
                    value={formData.date_of_birth}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="Pms_insert_user_kyc-row">
                  <label>Phone number:</label>
                  <input
                    type="text"
                    name="mobile_number"
                    value={formData.mobile_number}
                    onChange={handleInputChange}
                    required
                    maxLength={10}
                    placeholder="Enter 10-digit phone number"
                  />
                </div>
                <div className="Pms_insert_user_kyc-row">
                  <label>Email:</label>
                  <input
                    type="text"
                    name="email_address"
                    value={formData.email_address}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="Pms_insert_user_kyc-row">
                  <label htmlFor="marital_status">Marital Status:</label>
                  <select
                    id="marital_status"
                    name="marital_status"
                    value={formData.marital_status || ""}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">-- Select --</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Gender:</label>
                  <select
                    name="gender"
                    value={formData.gender || ""}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="Pms_insert_user_kyc-row">
                  <label>Nominee type:</label>
                  <select
                    type="text"
                    name="nominee_type"
                    value={formData.nominee_type || ""}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">-- Select Nominee Type --</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Parent">Parent</option>
                    <option value="Sibling">Sibling</option>
                    <option value="Relative">Relative</option>
                    <option value="Friend">Friend</option>
                    <option value="Legal Guardian">Legal Guardian</option>
                    <option value="Trustee">Trustee</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Nominee Name:</label>
                  <input
                    type="text"
                    name="nominee_name"
                    value={formData.nominee_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Aadhaar Image:</label>
                  <input
                    type="file"
                    name="aadhaar_image"
                    accept="image/png, image/jpeg"
                    onChange={handleFileInputChange}
                    required
                  />
                </div>
                <div className="Pms_insert_user_kyc-row">
                  <label>Pan Image:</label>
                  <input
                    type="file"
                    name="pan_image"
                    accept="image/png, image/jpeg"
                    onChange={handleFileInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Profile Picture:</label>
                  <input
                    type="file"
                    name="profile_picture"
                    accept="image/png, image/jpeg"
                    onChange={handleFileInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Bank Name:</label>
                  <select
                    type="text"
                    name="bank_name"
                    value={formData.bank_name}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a bank</option>
                    <option value="Axis Bank Ltd.">Axis Bank Ltd.</option>
                    <option value="Bandhan Bank Ltd.">Bandhan Bank Ltd.</option>
                    <option value="CSB Bank Limited">CSB Bank Limited</option>
                    <option value="City Union Bank Ltd.">
                      City Union Bank Ltd.
                    </option>
                    <option value="DCB Bank Ltd.">DCB Bank Ltd.</option>
                    <option value="Dhanlaxmi Bank Ltd.">
                      Dhanlaxmi Bank Ltd.
                    </option>
                    <option value="Federal Bank Ltd.">Federal Bank Ltd.</option>
                    <option value="HDFC Bank Ltd.">HDFC Bank Ltd.</option>
                    <option value="ICICI Bank Ltd.">ICICI Bank Ltd.</option>
                    <option value="IndusInd Bank Ltd.">
                      IndusInd Bank Ltd.
                    </option>
                    <option value="IDFC FIRST Bank Limited">
                      IDFC FIRST Bank Limited
                    </option>
                    <option value="Jammu & Kashmir Bank Ltd.">
                      Jammu & Kashmir Bank Ltd.
                    </option>
                    <option value="Karnataka Bank Ltd.">
                      Karnataka Bank Ltd.
                    </option>
                    <option value="Karur Vysya Bank Ltd.">
                      Karur Vysya Bank Ltd.
                    </option>
                    <option value="Kotak Mahindra Bank Ltd.">
                      Kotak Mahindra Bank Ltd.
                    </option>
                    <option value="Nainital bank Ltd.">
                      Nainital bank Ltd.
                    </option>
                    <option value="RBL Bank Ltd.">RBL Bank Ltd.</option>
                    <option value="South Indian Bank Ltd.">
                      South Indian Bank Ltd.
                    </option>
                    <option value="Tamilnad Mercantile Bank Ltd.">
                      Tamilnad Mercantile Bank Ltd.
                    </option>
                    <option value="YES Bank Ltd.">YES Bank Ltd.</option>
                    <option value="IDBI Bank Limited">IDBI Bank Limited</option>
                    <option value="Bank of Baroda">Bank of Baroda</option>
                    <option value="Bank of India">Bank of India</option>
                    <option value="Bank of Maharashtra">
                      Bank of Maharashtra
                    </option>
                    <option value="Canara Bank">Canara Bank</option>
                    <option value="Central Bank of India">
                      Central Bank of India
                    </option>
                    <option value="Indian Bank">Indian Bank</option>
                    <option value="Indian Overseas Bank">
                      Indian Overseas Bank
                    </option>
                    <option value="Punjab & Sind Bank">
                      Punjab & Sind Bank
                    </option>
                    <option value="Punjab National Bank">
                      Punjab National Bank
                    </option>
                    <option value="State Bank of India">
                      State Bank of India
                    </option>
                    <option value="UCO Bank">UCO Bank</option>
                    <option value="Union Bank of India">
                      Union Bank of India
                    </option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Bank Account.No:</label>
                  <input
                    type="text"
                    name="bank_account"
                    value={formData.bank_account}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>IFSC Code:</label>
                  <input
                    type="text"
                    name="ifsc_code"
                    value={formData.ifsc_code}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Category:</label>
                  <select
                    name="category"
                    value={formData.category || ""}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">-- Select --</option>
                    <option value="Deposit">Deposit</option>
                    <option value="Loan">Loan</option>
                    <option value="Life Insurance">Life Insurance</option>
                    <option value="PMS">PMS</option>
                  </select>
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <label>Deposit ID:</label>
                  <input
                    type="text"
                    name="deposit_id"
                    value={formData.deposit_id}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="Pms_insert_user_kyc-row">
                  <button type="submit" className="edit-button">
                    Insert
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Pms_insert_user_kyc;