import Copyright from "../../pages/copyright/Copyright";
import Footer from "../../pages/footer/Footer";
import Navbar from "../../pages/navbar/Navbar";
import { Link } from "react-router-dom";
import ULIPInsuranceImg from '../../assets/images/ulip-insurance.jpg';
import BenefitsULIPInsuranceImg from '../../assets/images/ulip_ben_insurance.jpg';
import WhoShouldOptULIPImg from '../../assets/images/who-ulip.jpg';

function Ulip_Insurance_Info() {
    return (
        <>
            <Navbar/>
            <div className="Individual-Health-containerr">
                {/* Section 1: What is ULIP Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is ULIP Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={ULIPInsuranceImg}
                            alt="ULIP Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                ULIP (Unit Linked Insurance Plan) is a unique insurance product that combines life insurance coverage with investment opportunities. It allows policyholders to invest in various funds while ensuring financial protection for their loved ones.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of ULIP Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Dual Benefit:</strong> Provides life insurance coverage along with investment growth.</li>
                            <li><strong>✅ Flexibility:</strong> Allows switching between equity, debt, and balanced funds.</li>
                            <li><strong>✅ Tax Benefits:</strong> Premiums and maturity proceeds are eligible for tax exemptions under Indian tax laws.</li>
                            <li><strong>✅ Wealth Creation:</strong> Helps build a corpus for future financial goals.</li>
                            <li><strong>✅ Partial Withdrawal:</strong> Offers the option to withdraw funds after a lock-in period.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of ULIP Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsULIPInsuranceImg}
                            alt="Benefits of ULIP Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 For Policyholders:</strong></p>
                            <ul>
                                <li>Investment opportunities to grow wealth over time.</li>
                                <li>Life cover to secure the financial future of dependents.</li>
                                <li>Option to switch funds based on market conditions.</li>
                                <li>Tax savings under Section 80C and Section 10(10D) of the Income Tax Act.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of ULIP Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of ULIP Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Equity ULIPs:</strong> Invests primarily in equity markets for higher returns.</li>
                            <li><strong>2️⃣ Debt ULIPs:</strong> Focuses on stable returns with lower risk.</li>
                            <li><strong>3️⃣ Balanced ULIPs:</strong> Mix of equity and debt investments for moderate risk.</li>
                            <li><strong>4️⃣ Child ULIPs:</strong> Helps secure a child's future education and expenses.</li>
                            <li><strong>5️⃣ Retirement ULIPs:</strong> Ensures a steady income post-retirement.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for ULIP Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for ULIP Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptULIPImg}
                            alt="Who Should Opt for ULIP Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals looking for a combination of investment and insurance.</li>
                                <li>✔ People seeking long-term wealth creation.</li>
                                <li>✔ Parents planning for their child's higher education.</li>
                                <li>✔ Investors looking for tax-saving options with market-linked returns.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                        <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer/>
            <Copyright/>
        </>
    )
}

export default Ulip_Insurance_Info