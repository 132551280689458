import React from 'react'
import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import ComprehensiveBikeInsuranceImg from '../../assets/images/Comprehensive-Bike-Insurances.avif';
import BenefitsImg from '../../assets/images/Comprehensive_Bike_Insurance.jpg';
import WhoShouldOptImg from '../../assets/images/Third_Party_Bike_Insurance.avif';
import { Link } from 'react-router-dom'

function Comperhensive_Bike_Info() {
  return (
    <>
    <Navbar />
    <div className="Individual-Health-containerr">
        {/* Section 1: What is Comprehensive Bike Insurance? */}
        <section className="Individual-Health-section">
            <h2>What is Comprehensive Bike Insurance?</h2>
            <div className="Individual-Health-section-content">
                <img
                    src={ComprehensiveBikeInsuranceImg}
                    alt="Comprehensive Bike Insurance"
                    className="Individual-Health-section-img"
                />
                <div className="Individual-Health-section-text">
                    <p className='Individual-Health-section-para'>
                        Comprehensive Bike Insurance provides extensive coverage for both own damage and third-party liabilities. It covers a wide range of risks including theft, fire, natural calamities, and accidents, ensuring complete protection for your bike.
                    </p>
                </div>
            </div>
        </section>

        {/* Section 2: Key Features */}
        <section className="Individual-Health-section">
            <h2>Key Features of Comprehensive Bike Insurance</h2>
            <div className="Individual-Health-section-content">
                <ul>
                    <li><strong>✅ Own Damage Coverage:</strong> Covers repair or replacement costs of your bike in case of accidents, fire, or theft.</li>
                    <li><strong>✅ Third-Party Liability:</strong> Includes legal protection for damages or injuries caused to third parties.</li>
                    <li><strong>✅ Theft and Fire Protection:</strong> Provides compensation for loss or damage due to theft or fire.</li>
                    <li><strong>✅ Natural Calamities Coverage:</strong> Covers damages caused by natural disasters like floods, earthquakes, etc.</li>
                    <li><strong>✅ Add-On Covers:</strong> Flexible add-ons to enhance coverage, such as zero depreciation or engine protection.</li>
                </ul>
            </div>
        </section>

        {/* Section 3: Benefits */}
        <section className="Individual-Health-section">
            <h2>Benefits of Comprehensive Bike Insurance</h2>
            <div className="Individual-Health-section-content">
                <img
                    src={BenefitsImg}
                    alt="Benefits of Comprehensive Bike Insurance"
                    className="Individual-Health-section-img"
                />
                <div className="Individual-Health-section-text">
                    <p><strong>🎯 Why You Need Comprehensive Bike Insurance:</strong></p>
                    <ul>
                        <li>Provides extensive protection for both your bike and third-party liabilities.</li>
                        <li>Covers a wide range of risks, including accidents, theft, fire, and natural disasters.</li>
                        <li>Offers peace of mind with all-around coverage for your bike.</li>
                        <li>Flexible coverage options with the possibility of add-ons for enhanced protection.</li>
                    </ul>
                </div>
            </div>
        </section>

        {/* Section 4: Coverage Details */}
        <section className="Individual-Health-section">
            <h2>Coverage Details</h2>
            <div className="Individual-Health-section-content">
                <ul>
                    <li><strong>✔ Own Damage:</strong> Covers all types of damage to your bike, including accidents, theft, and fire.</li>
                    <li><strong>✔ Third-Party Liability:</strong> Includes coverage for any injury or damage caused to third parties or their property.</li>
                    <li><strong>✔ Natural Calamities:</strong> Protects against damage caused by events like floods, earthquakes, or storms.</li>
                    <li><strong>✔ Theft and Fire:</strong> Provides compensation in case your bike is stolen or damaged by fire.</li>
                    <li><strong>✔ Add-Ons:</strong> Add additional protection for your bike with options like engine protection or roadside assistance.</li>
                </ul>
            </div>
        </section>

        {/* Section 5: Who Should Opt for Comprehensive Bike Insurance? */}
        <section className="Individual-Health-section">
            <h2>Who Should Opt for Comprehensive Bike Insurance?</h2>
            <div className="Individual-Health-section-content">
                <img
                    src={WhoShouldOptImg}
                    alt="Who Should Opt for Comprehensive Bike Insurance?"
                    className="Individual-Health-section-img"
                />
                <div className="Individual-Health-section-text">
                    <ul>
                        <li>✔ Bike owners who want complete protection for their vehicle.</li>
                        <li>✔ Individuals who want to secure against both own damage and third-party liabilities.</li>
                        <li>✔ People who want added coverage for natural calamities and theft.</li>
                        <li>✔ New bike owners looking for extensive coverage right from the start.</li>
                    </ul>
                </div>
            </div>
        </section>

        {/* Call-to-Action Section */}
        <div className="Individual-Health-contact-container">
            <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
            <Link to="/book-free-appointment">
            <button className="Individual-Health-contact-button">Free Appointment</button>
            </Link>
        </div>
    </div>
    <Footer />
    <Copyright />
</>
  )
}

export default Comperhensive_Bike_Info