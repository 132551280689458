import React, { useEffect, useState, useRef } from 'react';
import Affordable_Premiums from '../../assets/images/Affordable_Premiums.png';
import simple_and_clear from '../../assets/images/simple_and_clear.png';
import Flexible_Terms from '../../assets/images/Flexible_Terms.png';
import retirement_insurance from '../../assets/images/retirement_Plan.jpg';
import deferred_annuity from '../../assets/images/Deferred_Annuity.avif';
import immediate_annuity from '../../assets/images/Immediate_Annuity.jpg';
import certain_guaranteed from '../../assets/images/Annuity_Certain.webp';
import national_scheme from '../../assets/images/National_Pension_Scheme.jpg';
import pension_plan from '../../assets/images/Pension_Plans_with_Life_Cover.webp';
import retirement_insurances from '../../assets/images/Senior_Citizen_Travel_Insurance.jpeg';
import { Link } from 'react-router-dom';
import { ImCross } from 'react-icons/im';
import { link } from 'd3';

function Retirement_Plan() {
    const carousell = useRef(null);
    const intervalRef = useRef(null); // Ref to store the interval ID
    const [angle, setAngle] = useState(0); // State to store the rotation angle

    const carousellItems = [
        {
            img: deferred_annuity,
            title: 'Deferred Annuity',
            description: 'Allows you to invest over time and receive payouts after retirement, offering a steady income stream for your future.',
            link: '/deferred-annuity'
        },
        {
            img: immediate_annuity,
            title: 'Immediate Annuity',
            description: 'Begins payouts immediately after a lump-sum investment, providing instant financial security post-retirement.',
            link: '/immediate-annuity'
        },
        {
            img: certain_guaranteed,
            title: 'Annuity Certain/Guaranteed Period Annuity',
            description: 'Ensures a fixed income for a guaranteed period, offering financial stability and predictable payouts during retirement.',
            link: '/guaranteed-period-annuity'
        },
        {
            img: national_scheme,
            title: 'National Pension Scheme (NPS)',
            description: 'A government-backed retirement savings scheme that provides market-linked returns and tax benefits.',
            link: '/national-pension-scheme'
        },
        {
            img: pension_plan,
            title: 'Pension Plans with Life Cover',
            description: 'Combines retirement savings with life insurance coverage, ensuring financial security for you and your family.',
            link: '/pension-plans-life-cover'
        }
    ];

    const totalCards = carousellItems.length;
    const rotationAngle = 360 / totalCards;

    const startSliding = () => {
        intervalRef.current = setInterval(() => {
            setAngle((prevAngle) => {
                const newAngle = prevAngle - rotationAngle;
                if (carousell.current) {
                    carousell.current.style.transform = `translateZ(-25rem) rotateY(${newAngle}deg)`;
                }
                return newAngle;
            });
        }, 2000);
    };

    const stopSliding = () => {
        clearInterval(intervalRef.current);
    };

    useEffect(() => {
        startSliding(); // Start the sliding on component mount
        return () => stopSliding(); // Clear the interval on component unmount
    }, [rotationAngle]);

    return (
        <>
            <div className="home_insurance-header-section">
                <Link to="/life-Insurance" className="login-close-link">
                    <ImCross className="login-close-icon" />
                </Link>
                <h1 className="home_insurance-main-title">Retirement Plan</h1>
                <p className="home_insurance-subtitle">Secure Your Future with a Comprehensive Retirement Plan</p>

                <div className="home_insurance-intro-section">
                    <p className="home_insurance-intro-text">
                        A retirement plan provides financial security and peace of mind for your post-work life, ensuring a steady income and a worry-free future.
                    </p>
                </div>

                <div className="new-features-section">
                    <h2 className="new-section-title">Key Features</h2>
                    <div className="new-features">
                        <div className="new-feature-card">
                            <img src={Affordable_Premiums} className="new-type-image" alt="Affordable Premiums"></img>
                            <h3>Affordable Premiums</h3>
                            <p>Retirement plans are designed to be cost-effective while providing long-term financial stability.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={simple_and_clear} className="new-type-image" alt="Simple and Clear"></img>
                            <h3>Simple and Clear</h3>
                            <p>Easy-to-understand plans that ensure clarity in benefits and payouts, helping you plan effectively.</p>
                        </div>
                        <div className="new-feature-card">
                            <img src={Flexible_Terms} className="new-type-image" alt="Flexible Terms"></img>
                            <h3>Flexible Terms</h3>
                            <p>Customize your retirement plan with flexible options that suit your lifestyle and financial goals.</p>
                        </div>
                    </div>
                </div>


                <div className="carousell-container">
                    <h2 className="home_insurance-section-title">Types of Retirement Plan</h2>
                    <div className="carousell">
                        <div
                            className="carousell__cards"
                            ref={carousell}
                            onMouseEnter={stopSliding} // Stop sliding on mouse enter
                            onMouseLeave={startSliding} // Resume sliding on mouse leave
                        >
                            {carousellItems.map((item, index) => (
                                <Link
                                    to={item.link}   // Link to the desired page
                                    key={index}
                                    className="carousell__card"
                                    style={{
                                        transform: `rotateY(${index * rotationAngle}deg) translateZ(25rem)`
                                    }}
                                >
                                    <div className="carousell__img-container">
                                        <img className="carousell__img" src={item.img} alt={`Cover for ${item.title}`} />
                                    </div>
                                    <div className="carousell__content">
                                        <h3 className="carousell__title">{item.title}</h3>
                                        <p className="carousell__para">{item.description}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="insurance-cta-container">
                    <div className="insurance-cta-image-container">
                        <img
                            src={retirement_insurance}  // Replace with the image for retirement plan
                            alt="Retirement plan illustration"
                            className="insurance-cta-image"
                        />
                    </div>
                    <div className="insurance-cta-content">
                        <h1 className="insurance-cta-title">Why Choose a Retirement Plan?</h1>
                        <p className="insurance-cta-description">
                            A retirement plan is essential to ensure financial stability during your retirement years. It provides a consistent income stream to cover living expenses once you are no longer working. A well-structured retirement plan helps you maintain your lifestyle, cover healthcare costs, and achieve financial freedom after retirement.
                        </p>
                        <ul className="insurance-cta-benefits">
                            <li><strong>Long-Term Security:</strong> Provides steady income after retirement, protecting you from outliving savings.</li>
                            <li><strong>Flexible Contributions:</strong> Contribute based on your financial situation with adjustable options.</li>
                            <li><strong>Diversified Investments:</strong> Choose from stocks, bonds, and mutual funds to grow your savings.</li>
                            <li><strong>Tax Benefits:</strong> Enjoy tax deductions on contributions, reducing your taxable income.</li>
                            <li><strong>Emergency Access:</strong> Access funds in emergencies, depending on your retirement plan.</li>
                            <li><strong>Peace of Mind:</strong> Know your financial needs will be covered during retirement, allowing you to relax.</li>
                        </ul>
                        <p className="insurance-cta-footer">
                            Plan for your future today—ensure a comfortable and secure retirement.
                        </p>

                        <Link to="/Signup">
                            <button className="insurance-cta-button">Start Your Retirement Plan</button>
                        </Link>
                    </div>
                </div>

                <div className="home_insurance-benefits-section">
                    <h2 className="home_insurance-section-title-ben">Benefits of Retirement Plans</h2>
                    <div className="home_insurance-benefits-inner-section">
                        <div>
                            <ul className="home_insurance-benefits-list">
                                <li>Provides financial independence during retirement, allowing you to maintain your lifestyle.</li>
                                <li>Helps you prepare for unexpected expenses, such as medical emergencies or inflation.</li>
                                <li>Offers tax benefits, helping you save more for your golden years.</li>
                                <li>Ensures peace of mind, knowing your future financial needs are taken care of.</li>
                                <li>Flexible options to tailor plans according to your goals and retirement timeline.</li>
                                <li>Discover more benefits by booking a free appointment with our team of experts to choose the right retirement plan for you.</li>
                            </ul>
                            <div className="home_insurance-cta-section">
                                <Link to='/book-free-appointment' className="home_insurance-cta-button">Book free appointment</Link>
                            </div>
                        </div>
                        <div>
                            <img src={retirement_insurances} alt="Benefits of Retirement Plans" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Retirement_Plan;
