import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Modal.css";

function Modal({ user, onClose }) {
  const [kycData, setKycData] = useState(null);
  const [depositData, setDepositData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("kyc");

  useEffect(() => {
    if (!user || !user.user_id) {
      setError("Invalid user ID provided.");
      setLoading(false);
      return;
    }

    const fetchUserData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch KYC data
        const kycResponse = await axios.get(
          `https://www.upholic.in/Upholic_Api/fetch_user_kyc.php?user_id=${user.user_id}${user.deposit_id ? `&deposit_id=${user.deposit_id}` : ''}`
        );
        
        if (kycResponse.data.status === "error") {
          throw new Error(kycResponse.data.message || "Failed to fetch KYC data");
        }
        setKycData(kycResponse.data.data);

        // Fetch deposit data if deposit_id exists
        if (user.deposit_id) {
          const depositResponse = await axios.get(
            `https://www.upholic.in/Upholic_Api/fetch_deposite.php?deposit_id=${user.deposit_id}`
          );
          if (depositResponse.data.status === "success") {
            setDepositData(depositResponse.data.data);
          }
        }
      } catch (err) {
        console.error("Error:", err);
        setError(err.message || "Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user]);

  const formatDate = (dateString) => {
    if (!dateString) return "Not available";
    const date = new Date(dateString);
    return date.toLocaleDateString('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatCurrency = (amount) => {
    if (!amount) return "₹0";
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  if (!user) return null;

  return (
    <div className="pop-pms-modal-overlay" onClick={onClose}>
      <div className="pop-pms-modal-container" onClick={e => e.stopPropagation()}>
        <button className="pop-pms-modal-close-btn" onClick={onClose}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6L18 18" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>

        <div className="pop-pms-modal-header">
          <div className="pop-pms-user-avatar">
            {kycData?.first_name?.charAt(0) || 'U'}
          </div>
          <div className="pop-pms-user-info">
            <h2>{kycData?.first_name || 'User'} {kycData?.last_name}</h2>
            <div className="pop-pms-user-meta">
              <span>User ID: {user.user_id}</span>
              {user.deposit_id && <span>Deposit ID: {user.deposit_id}</span>}
            </div>
          </div>
        </div>

        <div className="pop-pms-modal-tabs">
          <button 
            className={`pop-pms-tab-btn ${activeTab === 'kyc' ? 'active' : ''}`}
            onClick={() => setActiveTab('kyc')}
          >
            KYC Details
          </button>
          {depositData && (
            <button 
              className={`pop-pms-tab-btn ${activeTab === 'deposit' ? 'active' : ''}`}
              onClick={() => setActiveTab('deposit')}
            >
              Deposit Details
            </button>
          )}
        </div>

        {loading ? (
          <div className="pop-pms-modal-loading">
            <div className="pop-pms-spinner"></div>
            <p>Loading user data...</p>
          </div>
        ) : error ? (
          <div className="pop-pms-modal-error">
            <div className="pop-pms-error-icon">!</div>
            <h3>Error Loading Data</h3>
            <p>{error}</p>
            <button className="pop-pms-retry-btn" onClick={() => window.location.reload()}>
              Retry
            </button>
          </div>
        ) : (
          <div className="pop-pms-modal-content">
            {activeTab === 'kyc' && kycData && (
              <div className="pop-pms-kyc-details">
                <div className="pop-pms-details-section">
                  <h3>Personal Information</h3>
                  <div className="pop-pms-details-grid">
                    <DetailItem label="Username" value={kycData.username} />
                    <DetailItem label="Date of Birth" value={formatDate(kycData.date_of_birth)} />
                    <DetailItem label="Mobile Number" value={kycData.mobile_number} />
                    <DetailItem label="Email Address" value={kycData.email_address} />
                    <DetailItem label="Marital Status" value={kycData.marital_status} />
                    <DetailItem label="Gender" value={kycData.gender} />
                    <DetailItem label="Category" value={kycData.category} />
                  </div>
                </div>

                <div className="pop-pms-details-section">
                  <h3>Nominee & Bank Details</h3>
                  <div className="pop-pms-details-grid">
                    <DetailItem label="Nominee Type" value={kycData.nominee_type} />
                    <DetailItem label="Nominee Name" value={kycData.nominee_name} />
                    <DetailItem label="Bank Name" value={kycData.bank_name} />
                    <DetailItem label="Bank Account" value={kycData.bank_account} />
                    <DetailItem label="IFSC Code" value={kycData.ifsc_code} />
                  </div>
                </div>

                <div className="pop-pms-details-section">
                  <h3>Document Verification</h3>
                  <div className="pop-pms-document-grid">
                    <DocumentItem 
                      label="Aadhaar Card" 
                      url={kycData.aadhaar_image} 
                    />
                    <DocumentItem 
                      label="PAN Card" 
                      url={kycData.pan_image} 
                    />
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'deposit' && depositData && (
              <div className="pop-pms-deposit-details">
                <div className="pop-pms-details-section">
                  <h3>Investment Summary</h3>
                  <div className="pop-pms-summary-cards">
                    <SummaryCard 
                      icon="currency" 
                      label="Principal Amount" 
                      value={formatCurrency(depositData.amount)} 
                    />
                    <SummaryCard 
                      icon="growth" 
                      label="Current Value" 
                      value={formatCurrency(depositData.final_amount)} 
                    />
                    <SummaryCard 
                      icon="wallet" 
                      label="Amount Received" 
                      value={formatCurrency(depositData.amount_received)} 
                    />
                  </div>
                </div>

                <div className="pop-pms-details-section">
                  <h3>Deposit Details</h3>
                  <div className="pop-pms-details-grid">
                    <DetailItem label="Product" value={depositData.product} />
                    <DetailItem label="Interest Rate" value={`${depositData.rate}%`} />
                    <DetailItem label="Investment Date" value={formatDate(depositData.date_of_investment)} />
                    <DetailItem label="Payment Method" value={depositData.payment} />
                    <DetailItem label="Monthly Interest" value={formatCurrency(depositData.monthly_interest)} />
                    <DetailItem label="Quarterly Payment" value={formatCurrency(depositData.quarterly_payment)} />
                    <DetailItem label="Yearly Interest" value={formatCurrency(depositData.yearly_interest)} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

// Helper components
const DetailItem = ({ label, value }) => (
  <div className="pop-pms-detail-item">
    <strong>{label}:</strong>
    <span>{value || "Not available"}</span>
  </div>
);

const DocumentItem = ({ label, url }) => (
  <div className="pop-pms-document-item">
    <strong>{label}:</strong>
    {url ? (
      <a href={url} target="_blank" rel="noopener noreferrer" className="document-link">
        View {label}
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 6H6V10M6 6L10 10M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z" stroke="#7C5CFC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </a>
    ) : (
      <span className="pop-pms-document-missing">Not available</span>
    )}
  </div>
);

const SummaryCard = ({ icon, label, value }) => {
  const getIcon = () => {
    switch(icon) {
      case 'currency':
        return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1V23M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6" stroke="#7C5CFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        );
      case 'growth':
        return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 20L20 7M20 7H14M20 7V13" stroke="#7C5CFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        );
      case 'wallet':
        return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V9C21 7.89543 20.1046 7 19 7L5 7C3.89543 7 3 6.10457 3 5ZM3 5C3 3.89543 3.89543 3 5 3H17" stroke="#7C5CFC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 14C16 14.5523 16.4477 15 17 15H18C18.5523 15 19 14.5523 19 14C19 13.4477 18.5523 13 18 13H17C16.4477 13 16 13.4477 16 14Z" fill="#7C5CFC"/>
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="pop-pms-summary-card">
      <div className="pop-pms-card-icon">
        {getIcon()}
      </div>
      <div className="pop-pms-card-content">
        <h4>{label}</h4>
        <h3>{value}</h3>
      </div>
    </div>
  );
};

export default Modal;