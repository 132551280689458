import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Carousel.scss"; // Import your existing CSS file
import investmentvid from '../../assets/video/upholicinvestment.vid.mp4'; // Ensure the video is optimized for web

function Carousel() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) { // Trigger content disappearing after scrolling 100px
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="carousel-container">
      <div className="video-wrapper">
        <video className="carousel-video" autoPlay loop muted>
          <source src={investmentvid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="carousel-gradient-overlay"></div>
      </div>
      <div className={`carousel-content ${isScrolled ? "scrolled" : ""}`}>
        <div className="carousel-subtitle">Invest with Confidence</div>
        <h1 className="carousel-main-title">
          "Smart investments today pave the way for a prosperous tomorrow."
        </h1>
        <Link to="/signup" className="carousel-cta-button">
          Book Appointment
        </Link>
      </div>
    </div>
  );
}

export default Carousel;
