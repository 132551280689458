import "./Copyright.scss";

function Copyright() {
  return (
    <div className="copyright-container">
      <div className="copyright-content">
        <div className="copyright-text">
          &copy;{" "}
          <a className="copyright-company">
            www.upholic.in
          </a>
          , All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default Copyright;
