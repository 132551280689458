import "./Coming_Soon.scss"; // Import CSS file for styling

function Coming_Soon() {
  return (
    <div className="page">
      <div className="newsletter-col col">
        <div className="newslatter middle">
          <h2>We Will Coming Soon</h2>
          <h4>Subscribe to get notification when we start</h4>
          <form>
            <input type="text" placeholder="Enter Your Email" />
            <button type="button" className="newslatter-button">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Coming_Soon;
