import mf_image_AboutUs from '../../assets/images/mutual_fund_bg_img.jpg';
import './Mf_AboutUs.css'; // Updated CSS file name

function Mf_AboutUs() {
  return (
    <>
      <div className="mf-about-us-container">
        <img
          src={mf_image_AboutUs}
          alt="Benefits of Mutual Funds Background"
          className="mf-about-us-background"
        />

        {/* Main Content Container */}
        <div className="mf-about-us-content">
          <h2 className="mf-about-us-heading">Benefits of Mutual Funds</h2>

          {/* Cards Container */}
          <div className="mf-about-us-cards-container">
            <div className="mf-about-us-card dark-theme">
              <h3>Professional Management</h3>
              <p>Mutual funds are managed by experienced professionals who make investment decisions based on market research.</p>
            </div>
            <div className="mf-about-us-card light-theme">
              <h3>Diversification</h3>
              <p>
                Investing in mutual funds spreads your investment across multiple assets, reducing the risk associated with individual stocks.
              </p>
            </div>
            <div className="mf-about-us-card dark-theme">
              <h3>Liquidity</h3>
              <p>Mutual funds offer high liquidity, allowing investors to buy or sell their investments with ease.</p>
            </div>
            <div className="mf-about-us-card light-theme">
              <h3>Tax Benefits</h3>
              <p>
                Certain mutual funds, like ELSS, provide tax benefits under Section 80C, making them a great tax-saving option.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mf_AboutUs;
