import GroupLifeImg from '../../assets/images/Group_Life_Insurances.jpg';
import WhoShouldOptImg from '../../assets/images/Group_Life_Insurance.jpg';
import BenefitsImg from '../../assets/images/Benefits_Group.png';
import Navbar from '../../pages/navbar/Navbar';
import Footer from '../../pages/footer/Footer';
import Copyright from '../../pages/copyright/Copyright';
import { Link } from 'react-router-dom';

function Group_Li_Info() {
    return (
        <>
            <Navbar />
            <div className="child-plans-container">
                {/* Section 1: What is Group Life Insurance? */}
                <section className="child-plans-section">
                    <h2>What is Group Life Insurance?</h2>
                    <div className="section-content">
                        <img 
                            src={GroupLifeImg} 
                            alt="Group Life Insurance" 
                            className="section-img" 
                        />
                        <div className="section-text">
                            <p>
                                Group Life Insurance is a single policy that provides life insurance coverage to a group of people, typically employees of a company, members of an organization, or professional groups. It offers financial protection to the insured members' families in case of an unfortunate event.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="child-plans-section">
                    <h2>Key Features of Group Life Insurance</h2>
                    <div className="section-content">
                        <ul>
                            <li><strong>✅ Affordable Coverage:</strong> Since it's a group policy, premiums are lower than individual life insurance.</li>
                            <li><strong>✅ Employer-Sponsored:</strong> Most organizations provide it as an employee benefit, either fully paid or partially subsidized.</li>
                            <li><strong>✅ No Medical Check-up:</strong> Generally, members are covered without medical tests, making it accessible to all.</li>
                            <li><strong>✅ Coverage for Family:</strong> Some plans offer spouse and dependent coverage at an additional cost.</li>
                            <li><strong>✅ Flexible Policy Terms:</strong> Can be customized based on the employer’s needs, including add-ons like accidental death benefits.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="child-plans-section">
                    <h2>Benefits of Group Life Insurance</h2>
                    <div className="section-content">
                        <img 
                            src={BenefitsImg} 
                            alt="Benefits of Group Life Insurance" 
                            className="section-img" 
                        />
                        <div className="section-text">
                            <p><strong>🎯 For Employees/Members:</strong></p>
                            <ul>
                                <li>Financial Security: Provides a lump sum payout to the nominee in case of the insured's demise.</li>
                                <li>Cost-Effective: Cheaper than buying an individual life insurance policy.</li>
                                <li>Easy Enrollment: Automatic coverage upon joining the organization.</li>
                                <li>Peace of Mind: Ensures that the insured’s family is protected financially.</li>
                            </ul>
                            <p><strong>🏢 For Employers/Organizations:</strong></p>
                            <ul>
                                <li>Boosts Employee Morale & Retention: A great perk that increases job satisfaction.</li>
                                <li>Tax Benefits: Employers may get tax deductions on premiums paid.</li>
                                <li>Strengthens Employee Welfare: Shows concern for employees’ well-being.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Group Life Insurance */}
                <section className="child-plans-section">
                    <h2>Types of Group Life Insurance Policies</h2>
                    <div className="section-content">
                        <ul>
                            <li><strong>1️⃣ Term Group Life Insurance –</strong> Provides coverage for a specified term, usually as long as the individual is part of the group.</li>
                            <li><strong>2️⃣ Group Credit Life Insurance –</strong> Pays off the insured’s outstanding loans in case of death.</li>
                            <li><strong>3️⃣ Group Universal Life Insurance –</strong> Offers both life coverage and a savings component.</li>
                            <li><strong>4️⃣ Group Accidental Death & Dismemberment (AD&D) Insurance –</strong> Covers death or disability due to accidents.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Group Life Insurance? */}
                <section className="child-plans-section">
                    <h2>Who Should Opt for Group Life Insurance?</h2>
                    <div className="section-content">
                        <img 
                            src={WhoShouldOptImg} 
                            alt="Who Should Opt for Group Life Insurance?" 
                            className="section-img" 
                        />
                        <div className="section-text">
                            <ul>
                                <li>✔ Employers looking to offer benefits to employees.</li>
                                <li>✔ Organizations, Associations & Unions providing security to members.</li>
                                <li>✔ Banks & Financial Institutions covering borrowers for outstanding loans.</li>
                                <li>✔ Educational Institutions securing faculty and staff members.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <div class="term-contact-container">
                    <h3>" Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button class="term-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    );
}

export default Group_Li_Info;
