import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import { Link } from 'react-router-dom'
import OwnDamageCoverImg from '../../assets/images/Benefit-Personal-Accident-Insurance.avif';
import BenefitsImg from '../../assets/images/Personal_Accident_Cover.webp';
import WhoShouldOptImg from '../../assets/images/Personal_Accident_Insurance.avif';

function Own_Damage_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Own Damage Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Own Damage Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={OwnDamageCoverImg}
                            alt="Own Damage Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Own Damage Cover Insurance provides financial protection for your vehicle against damages caused by accidents, natural disasters, theft, fire, or vandalism. It is an add-on to third-party insurance and ensures that your vehicle repairs or replacements are covered.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Own Damage Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Accident Coverage:</strong> Pays for repairs or replacement costs after an accident.</li>
                            <li><strong>✅ Natural Disaster Protection:</strong> Covers damages due to floods, earthquakes, and storms.</li>
                            <li><strong>✅ Theft Protection:</strong> Offers compensation if your vehicle is stolen.</li>
                            <li><strong>✅ Fire and Explosion Coverage:</strong> Covers damages from fire, explosions, and self-ignition.</li>
                            <li><strong>✅ Vandalism and Riots:</strong> Provides financial security against damages caused by riots or vandalism.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Own Damage Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Own Damage Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why Choose Own Damage Cover Insurance?</strong></p>
                            <ul>
                                <li>Comprehensive financial protection against unexpected vehicle damages.</li>
                                <li>Provides peace of mind for costly repairs and replacements.</li>
                                <li>Ensures compensation in case of vehicle theft.</li>
                                <li>Reduces out-of-pocket expenses after an accident.</li>
                                <li>Can be customized with additional add-ons like engine protection and roadside assistance.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Coverage Details */}
                <section className="Individual-Health-section">
                    <h2>Coverage Details</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✔ Accidental Damage:</strong> Covers repairs and replacement costs.</li>
                            <li><strong>✔ Theft Compensation:</strong> Provides coverage if your vehicle is stolen.</li>
                            <li><strong>✔ Natural Calamities:</strong> Covers damages due to floods, earthquakes, and storms.</li>
                            <li><strong>✔ Fire & Explosions:</strong> Protection against vehicle damage due to fire.</li>
                            <li><strong>❌ Third-Party Liabilities:</strong> Not included; requires separate third-party insurance.</li>
                            <li><strong>❌ Wear and Tear:</strong> Excludes damage due to normal wear and tear.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Own Damage Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Own Damage Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Own Damage Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Car or bike owners looking for financial protection beyond third-party insurance.</li>
                                <li>✔ Individuals living in areas prone to natural disasters.</li>
                                <li>✔ Vehicle owners who want to protect against theft or vandalism.</li>
                                <li>✔ People who use their vehicles frequently for long-distance travel.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Own_Damage_Cover_Info