import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import PersonalAccidentCoverForRidersImg from '../../assets/images/Personal_Accident_Cover_for_Riders.jpg';
import BenefitsImg from '../../assets/images/bike-third.avif';
import WhoShouldOptImg from '../../assets/images/bike-insurance.avif';
import { Link } from 'react-router-dom'

function Personal_Accident_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Personal Accident Cover for Riders? */}
                <section className="Individual-Health-section">
                    <h2>What is Personal Accident Cover for Riders?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={PersonalAccidentCoverForRidersImg}
                            alt="Personal Accident Cover for Riders"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Personal Accident Cover for Riders provides financial protection in case of accidental injuries or death while riding a bike. It ensures compensation for medical expenses, hospitalization, and disability caused by accidents, as well as support for your family in case of death.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Personal Accident Cover for Riders</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Death Coverage:</strong> Offers financial protection in case of the rider's death due to an accident.</li>
                            <li><strong>✅ Disability Benefits:</strong> Provides compensation for permanent or temporary disability caused by an accident.</li>
                            <li><strong>✅ Medical Expenses Coverage:</strong> Covers medical expenses, hospitalization, and rehabilitation costs for injuries sustained in an accident.</li>
                            <li><strong>✅ Riders' Protection:</strong> Specially designed for bike riders to ensure their safety while on the road.</li>
                            <li><strong>✅ Family Support:</strong> Provides support to the rider's family in case of death or severe injury.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Personal Accident Cover for Riders</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Personal Accident Cover for Riders"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Personal Accident Cover for Riders:</strong></p>
                            <ul>
                                <li>Provides financial security for the rider in case of severe injury or death.</li>
                                <li>Covers hospitalization, medical treatments, and rehabilitation for accident-related injuries.</li>
                                <li>Offers peace of mind for both riders and their families by ensuring protection in case of an accident.</li>
                                <li>Protects against the loss of income in case of permanent disability due to an accident.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Coverage Details */}
                <section className="Individual-Health-section">
                    <h2>Coverage Details</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✔ Death Coverage:</strong> Compensation to the rider’s family in case of accidental death.</li>
                            <li><strong>✔ Temporary Disability:</strong> Covers partial disability with compensation for medical expenses and loss of income.</li>
                            <li><strong>✔ Permanent Disability:</strong> Full compensation for permanent disability due to an accident.</li>
                            <li><strong>✔ Medical Expenses:</strong> Covers the rider’s medical bills, including hospitalization, surgeries, and follow-up care.</li>
                            <li><strong>✔ Accident Protection:</strong> Specific protection for riders in case of a road accident while riding a bike.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Personal Accident Cover for Riders? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Personal Accident Cover for Riders?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Personal Accident Cover for Riders?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Bike riders looking for financial protection in case of an accident.</li>
                                <li>✔ Individuals who regularly commute on a bike and want to secure themselves against unforeseen accidents.</li>
                                <li>✔ Families who want peace of mind knowing that their loved one is covered while riding.</li>
                                <li>✔ Anyone who wants comprehensive accident protection while riding a motorcycle or scooter.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Personal_Accident_Cover_Info