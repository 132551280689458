import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import { Link } from 'react-router-dom';
import ThirdPartyCoverImg from '../../assets/images/third-party-car.avif';
import BenefitsImg from '../../assets/images/Third_Party_Liability_Only_Cover.avif';
import WhoShouldOptImg from '../../assets/images/car_third_party.avif';

function Third_Party_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Third Party Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Third Party Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={ThirdPartyCoverImg}
                            alt="Third Party Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Third Party Cover Insurance is a mandatory motor insurance policy that covers damages, injuries, or death caused to a third party due to an accident involving the insured vehicle. This policy does not cover damages to the insured vehicle but provides financial protection against third-party liabilities.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Third Party Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Legal Requirement:</strong> Mandatory under the Motor Vehicles Act.</li>
                            <li><strong>✅ Third-Party Injury Cover:</strong> Covers medical expenses for injuries to a third party.</li>
                            <li><strong>✅ Property Damage Compensation:</strong> Offers financial protection for damages to third-party property.</li>
                            <li><strong>✅ Death Compensation:</strong> Provides compensation in case of third-party death.</li>
                            <li><strong>✅ Affordable Premium:</strong> Lower cost compared to comprehensive insurance policies.</li>
                            <li><strong>✅ Legal Assistance:</strong> Covers legal liabilities arising from road accidents.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Third Party Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Third Party Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why Choose Third Party Cover Insurance?</strong></p>
                            <ul>
                                <li>Ensures financial security against third-party liabilities.</li>
                                <li>Mandatory coverage for all vehicle owners.</li>
                                <li>Provides legal protection in case of third-party claims.</li>
                                <li>Offers compensation for third-party injuries and property damage.</li>
                                <li>Affordable and cost-effective insurance solution.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Coverage Details */}
                <section className="Individual-Health-section">
                    <h2>Coverage Details</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✔ Third-Party Bodily Injury:</strong> Covers medical expenses for injuries to others.</li>
                            <li><strong>✔ Third-Party Death Compensation:</strong> Provides financial aid to the deceased's family.</li>
                            <li><strong>✔ Property Damage:</strong> Covers damages to third-party assets.</li>
                            <li><strong>❌ Own Vehicle Damage:</strong> Not covered under this policy.</li>
                            <li><strong>❌ Personal Accident Cover:</strong> Requires a separate policy.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Third Party Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Third Party Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Third Party Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Vehicle owners looking for legally required insurance.</li>
                                <li>✔ Individuals who do not need own-damage coverage.</li>
                                <li>✔ Cost-conscious drivers seeking budget-friendly insurance.</li>
                                <li>✔ First-time vehicle owners ensuring compliance with legal mandates.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Third_Party_Cover_Info