import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from "universal-cookie";
import ProtectedRoute from "./components/common/ProtectedRoute";
import './App.css';

// Importing all pages
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Sign_up from "./pages/sign-up/Sign_up";
import Dashboard_Main from "./dashboard_/dashborad_main/Dashboard_Main";
import Popup from "./pages/popup/Popup";
import Coming_Soon from "./dashboard_/coming_soon/Coming_Soon";
import Dashboard_Popup from "./dashboard_/dashboard_popup/Dashboard_Popup";
import Pie_Chart from "./dashboard_/pie-chart/Pie_Chart";
import Myprofile from "./dashboard_/myprofile/Myprofile";
import EditProfile from "./dashboard_/myeditprofile/EditProfile";
import Admin_dashboard from "./admin/admin_dashboard/Admin_dashboard";
import Admin_Deposite from "./admin/admin_deposite/Admin_Deposite";
import Pms_dashboard from "./admin/pms_dashboard/Pms_dashboard";
import Document_entry from "./admin/document_entry/Document_entry";
import HR_dashboard from "./admin/hr_dashboard/HR_dashboard";
import HR_resume from "./admin/hr_resume_dashboard/HR_resume";
import Life_insurance_dashboard from "./admin/admin_life_insurance/Life_insurance_dashboard";
// import Life_insurance_home from "./admin/admin_life_insurance_home/Life_insurance_home";
import Life_Insurance_Home from "./LifeInsurance/life_Insurance_home/Life_Insurance_Home";
import Life_Term_Insurance from "./LifeInsurance/life_Term_Insurance/Life_Term_Insurance";
import Home_Insurance from "./LifeInsurance/Home_Insurance/Home_Insurance";
import Health_Insurance from "./LifeInsurance/Health_Insurance/Health_Insurance";
import Travel_Insurance from "./LifeInsurance/Travel_Insurance/Travel_Insurance";
import Car_Insurance from "./LifeInsurance/Car_Insurance/Car_Insurance";
import Bike_Insurance from "./LifeInsurance/Bike_Insurance/Bike_Insurance";
import Retirement_Plan from "./LifeInsurance/Retirement_Plan/Retirement_Plan";
import Free_oppointment from "./LifeInsurance/Free_Opp/Free_oppointment";
import Home_Coming_Soon from "./pages/coming-soon/Home_Coming_Soon";
import Our_Story from "./about/our_story/Our_Story";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import Term_Insurance_Info from "./Life_Insurance_Info/Term_Insurance/Term_Insurance_Info";
import Endowment_Plan_Info from "./Life_Insurance_Info/Endowment_Plans/Endowment_Plan_Info";
import Child_Plan_Info from "./Life_Insurance_Info/Child_Plans/Child_Plan_Info";
import Group_Li_Info from "./Life_Insurance_Info/Group_Life_Insurance/Group_Li_Info";
import Money_Plan from "./Life_Insurance_Info/Money_Back_Plan/Money_Plan";
import Individual_Health_Info from "./Health_Insurance_Info/Individual_Health_Insurance/Individual_Health_Info";
import Admin_Third_Party from "./admin/admin_third_party/Admin_Third_Party";
// import Third_Party_Entry from "./admin/admin_third_party_entry/Third_Party_Entry";
import Accidental_Death_Plans from "./Life_Insurance_Info/Accidental_Death_Plan/Accidental_Death_Plans";
import Family_Health_Info from "./Health_Insurance_Info/Family_Health_Insurance/Family_Health_Info";
import Critical_Illness_Info from "./Health_Insurance_Info/Critical_Illness_Insurance/Critical_Illness_Info";
import Maternity_Insurance_Info from "./Health_Insurance_Info/Maternity_Insurance_Insurance/Maternity_Insurance_Info";
import Personal_Accident_Info from "./Health_Insurance_Info/Personal_Accident_Insurance/Personal_Accident_Info";
import Mediclaim_Insurance_Info from "./Health_Insurance_Info/Mediclaim_Insurance/Mediclaim_Insurance_Info";
import Structural_Cover_Info from "./Home_Insurance_Info/Structural_Cover_Insurance/Structural_Cover_Info";
import Home_Cover_Info from "./Home_Insurance_Info/Home_Content_Cover_Insurance/Home_Cover_Info";
import Fire_Cover_Info from "./Home_Insurance_Info/Fire_Cover_Insurance/Fire_Cover_Info";
import Public_Liability_Cover_Info from "./Home_Insurance_Info/Public_Liability_Insurance/Public_Liability_Cover_Info";
import Theft_Cover_Info from "./Home_Insurance_Info/Theft_Cover_Insurance/Theft_Cover_Info";
import Landlord_Cover_Info from "./Home_Insurance_Info/Landlord_Cover_Insurance/Landlord_Cover_Info";
import Tenant_Cover_Info from "./Home_Insurance_Info/Tenant_Cover_Insurance/Tenant_Cover_Info";
import Deferred_Annuity_Info from "./Retirement_Insurance_Info/Deferred_Annuity_Insurance/Deferred_Annuity_Info";
import Immediate_Annuity_Info from "./Retirement_Insurance_Info/Immediate_Annuity_Insurance/Immediate_Annuity_Info";
import Guaranteed_Annuity_Info from "./Retirement_Insurance_Info/Guaranteed_Period_Annuity_Insurance/Guaranteed_Annuity_Info";
import National_Pension_Info from "./Retirement_Insurance_Info/National_Pension_Insurance/National_Pension_Info";
import Pension_Plans_Cover from "./Retirement_Insurance_Info/Pension_Plans_Cover/Pension_Plans_Cover";
import Domestic_Travel_Info from "./Travel_Insurance_Info/Domestic_Travel_Insurance/Domestic_Travel_Info";
import International_Travel_Info from "./Travel_Insurance_Info/International_Travel_Insurance/International_Travel_Info";
import Group_Travel_Info from "./Travel_Insurance_Info/Group_Travel_Insurance/Group_Travel_Info";
import Single_Travel_Info from "./Travel_Insurance_Info/Single_Travel_Insurance/Single_Travel_Info";
import Senior_Citizen_Travel_Info from "./Travel_Insurance_Info/Senior_Travel_Insurance/Senior_Citizen_Travel_Info";
import Student_Travel_Info from "./Travel_Insurance_Info/Student_Travel_Insurance/Student_Travel_Info";
import Third_Party_Cover_Info from "./Car_Insurance_Info/Third_Party_Cover_Insurance/Third_Party_Cover_Info";
import Motorist_Protection_Info from "./Car_Insurance_Info/Motorist_Protection_Insurance/Motorist_Protection_Info";
import Own_Damage_Cover_Info from "./Car_Insurance_Info/Own_Damage_Cover/Own_Damage_Cover_Info";
import Comprehensive_Car_Info from "./Car_Insurance_Info/Comprehensive_Car_Insurance/Comprehensive_Car_Info";
import Third_Party_Bike_Info from "./Bike_Insurance_Info/Third_Party_Bike_Insurance/Third_Party_Bike_Info";
import Own_Damage_Bike_Info from "./Bike_Insurance_Info/Own_Damage_Bike_Insurance/Own_Damage_Bike_Info";
import Personal_Accident_Cover_Info from "./Bike_Insurance_Info/Personal_Accident_Cover_Insurance/Personal_Accident_Cover_Info";
import Mutal_Fund_Home from "./mutual_fund/Mutal_Fund_Home/Mutal_Fund_Home";
import Portfolio_Home from "./Portfolio_PMS/Portfolio_Home/Portfolio_Home";
import Loan_Home from "./Loan/Loan_Home/Loan_Home";
import Hospital_Cash_Info from "./Health_Insurance_Info/Hospital_Cash_Insurance/Hospital_Cash_Info";
import Comperhensive_Bike_Info from "./Bike_Insurance_Info/Comperhensive_Bike_Insurance/Comperhensive_Bike_Info";
import Nri_Insurance_Info from "./Life_Insurance_Info/Nri_Insurance/Nri_Insurance_Info";
import Ulip_Insurance_Info from "./Life_Insurance_Info/Ulip_Insurance/Ulip_Insurance_Info";
import Admin_PMS_Dashboard from "./admin/admin_pms_dashboard/Admin_PMS_Dashboard";

function App() {
  const cookies = new Cookies();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(""); // Store the user role (admin, hr, etc.)
  const [isLoading, setIsLoading] = useState(true);

  // Check authentication state using cookies
  useEffect(() => {
    const username = cookies.get("username");
    const role = cookies.get("role");
    if (username && role) {
      setIsAuthenticated(true);
      setUserRole(role); // Set the user role if present
    }
    setIsLoading(false); // Mark loading complete
  }, []);

  // Handle login
  const handleLogin = (username, role) => {
    setIsAuthenticated(true);
    setUserRole(role); // Save the role after login
    cookies.set("username", username, { path: "/" });
    cookies.set("role", role, { path: "/" }); // Save role in cookies
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Our_Story />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/signup" element={<Sign_up />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/life-Insurance" element={< Life_Insurance_Home />} />
        <Route path="/mutual-fund" element={<Mutal_Fund_Home/>} />
        <Route path="/life-insurances" element={<Life_Term_Insurance />} />
        <Route path="/home-insurance" element={<Home_Insurance />} />
        <Route path="/health-insurance" element={<Health_Insurance />} />
        <Route path="/travel-insurance" element={<Travel_Insurance />} />
        <Route path="/car-insurance" element={<Car_Insurance />} />
        <Route path="/bike-insurance" element={<Bike_Insurance />} />
        <Route path="/retirement-plan" element={<Retirement_Plan />} />
        <Route path="/book-free-appointment" element={<Free_oppointment />} />
        <Route path="/term-insurance" element={<Term_Insurance_Info />} />
        <Route path="/commingsoon" element={<Home_Coming_Soon />} />
        <Route path="/endowment-plans" element={<Endowment_Plan_Info />} />
        <Route path="/child-plans" element={<Child_Plan_Info />} />
        <Route path="group-life-insurance" element={<Group_Li_Info />} />
        <Route path="/accidental-death-disability-plans" element={<Accidental_Death_Plans/>}/>
        <Route path="/money-back-plans" element={<Money_Plan />} />
        <Route path="/individual-health" element={<Individual_Health_Info />} />
        <Route path="/family-health" element={<Family_Health_Info/>} />
        <Route path="/critical-illness" element={<Critical_Illness_Info/>}/>
        <Route path="/maternity-insurance" element={<Maternity_Insurance_Info/>} />
        <Route path="/personal-accident" element={<Personal_Accident_Info/>} />
        <Route path="/mediclaim-insurance" element={<Mediclaim_Insurance_Info/>} />
        <Route path="/hospital-cash" element={<Hospital_Cash_Info/>} />
        <Route path="/structural_cover" element={<Structural_Cover_Info/>} />
        <Route path="/home-content-cover" element={<Home_Cover_Info/>} />
        <Route path="/fire-cover" element={<Fire_Cover_Info/>} />
        <Route path="/public-liability-cover" element={<Public_Liability_Cover_Info/>} />
        <Route path="/theft-cover" element={<Theft_Cover_Info/>} />
        <Route path="/landlord-cover" element={<Landlord_Cover_Info/>} />
        <Route path="/tenant-cover" element={<Tenant_Cover_Info/>} />
        <Route path="/deferred-annuity" element={<Deferred_Annuity_Info/>} />
        <Route path="/immediate-annuity" element={<Immediate_Annuity_Info/>} />
        <Route path="/guaranteed-period-annuity" element={<Guaranteed_Annuity_Info/>} />
        <Route path="/national-pension-scheme" element={<National_Pension_Info/>} />
        <Route path="/pension-plans-life-cover" element={<Pension_Plans_Cover/>} />
        <Route path="/domestic-travel" element={<Domestic_Travel_Info/>} />
        <Route path="/international-travel-insurance" element={<International_Travel_Info/>} />
        <Route path="/group-travel-insurance" element={<Group_Travel_Info/>} />
        <Route path="/single-travel-insurance" element={<Single_Travel_Info/>} />
        <Route path="/senior-citizen-travel-insurance" element={<Senior_Citizen_Travel_Info/>} />
        <Route path="/student-travel-insurance" element={<Student_Travel_Info/>} />
        <Route path="/third-party-cover-insurance" element={<Third_Party_Cover_Info/>} />
        <Route path="/uninsured-motorist-protection-insurance" element={<Motorist_Protection_Info/>}/>
        <Route path="/own-damage-cover-insurance" element={<Own_Damage_Cover_Info/>} />
        <Route path="/personal-accident-cover" element={<Personal_Accident_Info/>} />
        <Route path="/comprehensive-car-insurance" element={<Comprehensive_Car_Info/>} />
        <Route path="/third-party-bike-insurance" element={<Third_Party_Bike_Info/>} />
        <Route path="/comprehensive-bike-insurance" element={<Comperhensive_Bike_Info/>} />
        <Route path="/own-damage-bike-insurance" element={<Own_Damage_Bike_Info/>} />
        <Route path="/personal-accident-cover-bike" element={<Personal_Accident_Cover_Info/>} />
        <Route path="/portfolio-management-services" element={<Portfolio_Home/>} />
        <Route path="/structural-cover" element={<Structural_Cover_Info/>} />
        <Route path="/nri-insurance" element={<Nri_Insurance_Info/>} />
        <Route path="/ulip-insurance" element={<Ulip_Insurance_Info/>} />
        <Route path="/loan" element={<Loan_Home/>} />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              element={<Dashboard_Main />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/comingsoon"
          element={
            <ProtectedRoute
              element={<Coming_Soon />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/dashboard_popup"
          element={
            <ProtectedRoute
              element={<Dashboard_Popup />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/piechart"
          element={
            <ProtectedRoute
              element={<Pie_Chart />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/myprofile"
          element={
            <ProtectedRoute
              element={<Myprofile />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/editprofile"
          element={
            <ProtectedRoute
              element={<EditProfile />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        {/* Admin Routes */}
        <Route
          path="/admindashboard"
          element={
            <ProtectedRoute
              element={<Admin_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/admin-deposit"
          element={
            <ProtectedRoute
              element={<Admin_Deposite />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/pms-dashboard"
          element={
            <ProtectedRoute
              element={<Pms_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/li-dashboard"
          element={
            <ProtectedRoute
              element={<Life_insurance_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/admin-pms"
          element={
            <ProtectedRoute
              element={<Admin_PMS_Dashboard/>}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/admin_document"
          element={
            <ProtectedRoute
              element={<Document_entry />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/hr-dashboard"
          element={
            <ProtectedRoute
              element={<HR_dashboard />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/hr-resume"
          element={
            <ProtectedRoute
              element={<HR_resume />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        <Route
          path="/third-party"
          element={
            <ProtectedRoute
              element={<Admin_Third_Party />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        />
        {/* <Route
          path="/third-party-entry"
          element={
            <ProtectedRoute
              element={<Third_Party_Entry />}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              userRole={userRole}
            />
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;