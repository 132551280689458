import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./About.scss";
import deposite_home from "../../../src/assets/images/about_deposit_img.jpg";
import li_home from "../../assets/images/about_li_img.jpg";
import fd_home from "../../assets/images/about_fd_img.jpg";
import PMS_home from "../../assets/images/about_pms_img.jpg";
import { Link } from "react-router-dom";

function About() {
  // useEffect(() => {
  //   AOS.init({ duration: 1000 });
  // }, []);

  return (
    <>
      <section className="about_home_section">
        <h2
          className="about_home_h2"
          data-aos="fade-right"
        >
          "What are your investment ambitions?"
        </h2>
        <p
          className="about_home_para"
          data-aos="fade-up"
        >
          Multiple goals are encouraged—feel free to select all that resonate
          with you.
        </p>
        <div className="about_home_container">
          <Link 
          // to="/commingsoon"
          to="/life-Insurance"
            className="about_home_card"
            data-aos="zoom-in"
          >
            <div className="about_home_card-inner">
              <div className="about_home_box">
                <div className="about_home_imgBox">
                  <img
                    className="about_home_img"
                    src={li_home}
                    alt="Life Insurance"
                  />
                </div>
                <div className="about_home_icon">
                  <h3 className="about_home_h3">Life Insurance</h3>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/mutual-fund"
            className="about_home_card"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="about_home_card-inner">
              <div className="about_home_box">
                <div className="about_home_imgBox">
                  <img
                    className="about_home_img"
                    src={fd_home}
                    alt="Mutual Funds"
                  />
                </div>
                <div className="about_home_icon">
                  <h3 className="about_home_h3">Mutual Funds</h3>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/portfolio-management-services"
            className="about_home_card"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div className="about_home_card-inner">
              <div className="about_home_box">
                <div className="about_home_imgBox">
                  <img
                    className="about_home_img"
                    src={PMS_home}
                    alt="Portfolio Management Service"
                  />
                </div>
                <div className="about_home_icon">
                  <h3 className="about_home_h3">PMS</h3>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/loan"
            className="about_home_card"
            data-aos="zoom-in"
            data-aos-delay="600"
          >
            <div className="about_home_card-inner">
              <div className="about_home_box">
                <div className="about_home_imgBox">
                  <img
                    className="about_home_img"
                    src={deposite_home}
                    alt="Loan Services"
                  />
                </div>
                <div className="about_home_icon">
                  <h3 className="about_home_h3">Loan</h3>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </section>
    </>
  );
}

export default About;
