import React, { useEffect, useState } from 'react';
import './PMS_Popup.css';

const PMS_Popup = ({ user_id, onClose }) => {
  const [userData, setUserData] = useState({
    user_info: null,
    investment_summary: null,
    stock_holdings: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`https://www.upholic.in/Upholic_Api/fetch_pms_user_details.php?user_id=${user_id}`);
        console.log(response);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || "Failed to fetch user details");
        }

        setUserData({
          user_info: data.user_info,
          investment_summary: data.investment_summary,
          stock_holdings: data.stock_holdings || []
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [user_id]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-IN');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const formatPercentage = (value) => {
    return `${parseFloat(value).toFixed(2)}%`;
  };

  if (loading) {
    return (
      <div className="pms-modal-overlay">
        <div className="pms-modal-container loading">
          <div className="spinner"></div>
          <p>Loading portfolio data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="pms-modal-overlay">
        <div className="pms-modal-container error">
          <div className="error-icon">!</div>
          <h3>Error Loading Data</h3>
          <p>{error}</p>
          <button className="retry-btn" onClick={() => window.location.reload()}>
            Retry
          </button>
          <button className="close-btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="pms-modal-overlay">
      <div className="pms-modal-container">
        {/* Modal Header */}
        <div className="modal-header">
          <div className="user-avatar">
            {userData.user_info.full_name.charAt(0)}
          </div>
          <div className="user-info">
            <h2>{userData.user_info.full_name}'s Portfolio</h2>
            <p className="user-meta">
              <span>@{userData.user_info.username}</span>
              <span>{userData.user_info.email}</span>
              <span>{userData.user_info.phone}</span>
            </p>
          </div>
          <button className="close-btn" onClick={onClose}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18M6 6L18 18" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>

        {/* Navigation Tabs */}
        <div className="modal-tabs">
          <button 
            className={`tab-btn ${activeTab === 'overview' ? 'active' : ''}`}
            onClick={() => setActiveTab('overview')}
          >
            Overview
          </button>
          <button 
            className={`tab-btn ${activeTab === 'holdings' ? 'active' : ''}`}
            onClick={() => setActiveTab('holdings')}
          >
            Holdings
          </button>
          <button 
            className={`tab-btn ${activeTab === 'performance' ? 'active' : ''}`}
            onClick={() => setActiveTab('performance')}
          >
            Performance
          </button>
        </div>

        {/* Overview Tab */}
        {activeTab === 'overview' && (
          <div className="tab-content">
            <div className="summary-cards">
              <div className="summary-card primary">
                <div className="card-icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 1V23M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <div className="card-content">
                  <h4>Current Value</h4>
                  <h3>{formatCurrency(userData.investment_summary.current_value)}</h3>
                </div>
              </div>

              <div className="summary-card">
                <div className="card-icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 8V16M8 12H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <div className="card-content">
                  <h4>Total Invested</h4>
                  <h3>{formatCurrency(userData.investment_summary.total_invested)}</h3>
                </div>
              </div>

              <div className="summary-card">
                <div className="card-content">
                  <h4>Growth</h4>
                  <h3 className={userData.investment_summary.growth_percentage >= 0 ? 'positive' : 'negative'}>
                    {formatPercentage(userData.investment_summary.growth_percentage)}
                  </h3>
                </div>
                <div className={`trend-icon ${userData.investment_summary.growth_percentage >= 0 ? 'up' : 'down'}`}>
                  {userData.investment_summary.growth_percentage >= 0 ? (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 10L8 6L12 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  ) : (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 6L8 10L12 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  )}
                </div>
              </div>
            </div>

            <div className="portfolio-chart">
              <div className="chart-placeholder">
                <p>Portfolio Growth Chart</p>
                <div className="chart-line"></div>
              </div>
            </div>

            <div className="sector-allocation">
              <h3>Sector Allocation</h3>
              <div className="allocation-chart">
                {userData.stock_holdings.slice(0, 5).map((stock, index) => (
                  <div key={index} className="sector-item">
                    <div className="sector-color" style={{ backgroundColor: getSectorColor(stock.sector) }}></div>
                    <span>{stock.sector}</span>
                    <span className="sector-percent">
                      {((stock.value_at_current_market_price / userData.investment_summary.current_value) * 100).toFixed(1)}%
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Holdings Tab */}
        {activeTab === 'holdings' && (
          <div className="tab-content">
            <div className="holdings-table-container">
              <table className="holdings-table">
                <thead>
                  <tr>
                    <th>Stock</th>
                    <th>Quantity</th>
                    <th>Avg Cost</th>
                    <th>Invested</th>
                    <th>Current Value</th>
                    <th>PnL</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.stock_holdings.map((stock, index) => (
                    <tr key={index}>
                      <td>
                        <div className="stock-info">
                          <div className="stock-color" style={{ backgroundColor: getSectorColor(stock.sector) }}></div>
                          <div>
                            <strong>{stock.stock}</strong>
                            <small>{stock.sector}</small>
                          </div>
                        </div>
                      </td>
                      <td>{stock.quantity}</td>
                      <td>{formatCurrency(stock.average_price)}</td>
                      <td>{formatCurrency(stock.value_of_cost)}</td>
                      <td>{formatCurrency(stock.value_at_current_market_price)}</td>
                      <td className={stock.unrealizedPNL >= 0 ? 'positive' : 'negative'}>
                        {formatCurrency(stock.unrealizedPNL)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Performance Tab */}
        {activeTab === 'performance' && (
          <div className="tab-content">
            <div className="performance-metrics">
              <div className="metric-card">
                <h4>First Investment</h4>
                <p>{formatDate(userData.investment_summary.first_investment_date)}</p>
              </div>
              <div className="metric-card">
                <h4>Unrealized PnL</h4>
                <p className={userData.investment_summary.unrealized_pnl >= 0 ? 'positive' : 'negative'}>
                  {formatCurrency(userData.investment_summary.unrealized_pnl)}
                </p>
              </div>
              <div className="metric-card">
                <h4>Realized PnL</h4>
                <p className={userData.investment_summary.realized_pnl >= 0 ? 'positive' : 'negative'}>
                  {formatCurrency(userData.investment_summary.realized_pnl)}
                </p>
              </div>
            </div>
            <div className="performance-chart">
              <div className="chart-placeholder">
                <p>Performance Over Time</p>
                <div className="chart-line"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Helper function for sector colors
const getSectorColor = (sector) => {
  const colors = {
    'Energy': '#FF6384',
    'Finance': '#36A2EB',
    'Banking': '#FFCE56',
    'Pharmaceuticals': '#4BC0C0',
    'Metals': '#9966FF',
    'Tech': '#FF9F40'
  };
  return colors[sector] || '#CCCCCC';
};

export default PMS_Popup;