import './Mutal_Fund_Info_Login.scss';

function Mutal_Fund_Info_Login() {
    return (
        <>
            <div className="mf-login-investment-row">
                {/* Left Side - Text Section */}
                <div className="mf-login-investment-text">
                    <div className="mf-login-investment-text-container">
                        <div className="mf-login-investment-title">
                            <h1 className="mf-login-investment-h2">Mutual Fund Category</h1>
                        </div>
                        <div className="mf-login-investment-description">
                            <ul>
                                <li><strong className="mf-strong">Equity Funds:</strong> Invest in stocks of companies across various sectors and market capitalizations. These funds have the potential for high returns but are also subject to higher risks.</li>
                                <li><strong className="mf-strong">Debt Funds:</strong> Primarily invest in fixed-income securities like bonds, government securities, and money market instruments. These funds offer stable returns with lower risks compared to equity funds.</li>
                                <li><strong className="mf-strong">Balanced Funds:</strong> Invest in a mix of both equity and debt instruments, aiming to provide a balance between growth and stability. The allocation between equity and debt can vary based on the fund’s objective.</li>
                                <li><strong className="mf-strong">Index Funds:</strong> Mimic the performance of a specific market index (e.g., Nifty 50, Sensex) by investing in the same securities as the index. These funds offer diversification and relatively low expense ratios.</li>
                                <li><strong className="mf-strong">Sector Funds:</strong> Concentrate investments in specific sectors or industries, such as banking, technology, or healthcare. These funds carry higher risks but can generate higher returns during favorable market conditions.</li>
                                <li><strong className="mf-strong">Tax-saving Funds:</strong> Also known as Equity Linked Saving Schemes (ELSS), these funds offer tax benefits under Section 80C of the Income Tax Act. They primarily invest in equities and have a lock-in period of three years.</li>
                                <li><strong className="mf-strong">Money Market Funds:</strong> Invest in short-term fixed-income instruments like Treasury Bills, Commercial Papers, and Certificates of Deposit. These funds are suitable for investors seeking low-risk and liquidity.</li>
                                <li><strong className="mf-strong">Gilt Funds:</strong> Invest in government securities (gilts) issued by the central or state governments. These funds carry the lowest credit risk as they are backed by the government but may have moderate returns.</li>
                                <li><strong className="mf-strong">Exchange-Traded Funds:</strong> Similar to index funds, ETFs track specific indices but can be bought and sold on stock exchanges like individual stocks. They offer diversification, flexibility, and intra-day trading capabilities.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Right Side - Image Section */}
                <div className="mf-login-investment-image"></div>
            </div>
        </>
    );
}

export default Mutal_Fund_Info_Login;
