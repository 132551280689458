import { Link } from 'react-router-dom'
import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import StudentTravelInsuranceImg from '../../assets/images/student-travel.avif';
import BenefitsImg from '../../assets/images/Student_Travel_Insurance.avif';
import WhoShouldOptImg from '../../assets/images/student_insurance-travel.avif';

function Student_Travel_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Student Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Student Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={StudentTravelInsuranceImg}
                            alt="Student Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Student Travel Insurance is a specialized policy designed for students traveling abroad for higher education. It provides financial protection against medical emergencies, trip cancellations, lost baggage, study interruptions, and other unexpected risks. With this coverage, students can focus on their studies without worrying about unforeseen expenses.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Student Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Medical Coverage:</strong> Covers hospitalization, emergency medical treatments, and health check-ups.</li>
                            <li><strong>✅ Study Interruption Cover:</strong> Provides financial assistance in case of unexpected academic interruptions.</li>
                            <li><strong>✅ Trip Cancellation & Delay:</strong> Protection against financial losses due to unforeseen cancellations or delays.</li>
                            <li><strong>✅ Lost Baggage & Documents:</strong> Covers loss of baggage, passport, and other essential items.</li>
                            <li><strong>✅ Sponsor Protection:</strong> Financial security in case of the sponsor's demise or inability to continue support.</li>
                            <li><strong>✅ 24/7 Assistance:</strong> Round-the-clock support for emergencies while studying abroad.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Student Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Student Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why Choose Student Travel Insurance?</strong></p>
                            <ul>
                                <li>Ensures students are financially protected against unexpected medical emergencies.</li>
                                <li>Covers losses due to trip delays, cancellations, and interruptions.</li>
                                <li>Provides coverage for lost academic materials and personal belongings.</li>
                                <li>Offers sponsor protection to cover tuition fees in case of financial hardship.</li>
                                <li>Provides access to 24/7 emergency assistance while studying abroad.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Student Travel Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Student Travel Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Short-Term Student Travel Insurance:</strong> Coverage for short-duration study programs or exchange programs.</li>
                            <li><strong>2️⃣ Long-Term Student Travel Insurance:</strong> Ideal for students pursuing full-time degrees abroad.</li>
                            <li><strong>3️⃣ Medical Travel Insurance:</strong> Provides extensive health coverage while studying abroad.</li>
                            <li><strong>4️⃣ Comprehensive Student Travel Insurance:</strong> Covers academic interruptions, tuition fees, and travel-related risks.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Student Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Student Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Student Travel Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Students planning to study abroad for short-term or long-term courses.</li>
                                <li>✔ Students traveling internationally for higher education or research programs.</li>
                                <li>✔ Students seeking financial protection for academic interruptions and unexpected medical expenses.</li>
                                <li>✔ Individuals who want to safeguard their study plans from unexpected travel disruptions.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Student_Travel_Info