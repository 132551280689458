// import './Term_Insurance_Info.css';
import term_insurance from '../../assets/images/term-insurance-plan.avif';
import BenefitsTermInsuranceImg from '../../assets/images/benifit-term.jpg';
import WhoShouldOptTermImg from '../../assets/images/who-term-insurance.jpg';
import Navbar from '../../pages/navbar/Navbar';
import Footer from '../../pages/footer/Footer';
import Copyright from '../../pages/copyright/Copyright';
import { Link } from 'react-router-dom';

function Term_Insurance_Info() {
    return (
        <>
            <Navbar />
            <div className="child-plans-container">
                {/* Section 1: What is Term Insurance? */}
                <section className="child-plans-section">
                    <h2>What is Term Insurance?</h2>
                    <div className="section-content">
                        <img
                            src={term_insurance}
                            alt="Term Insurance"
                            className="section-img"
                        />
                        <div className="section-text">
                            <p>
                                Term Insurance is a pure life insurance policy that provides financial security to your loved ones in case of an unfortunate event.
                                It offers high coverage at affordable premiums and ensures peace of mind for your family's future.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="child-plans-section">
                    <h2>Key Features of Term Insurance</h2>
                    <div className="section-content">
                        <ul>
                            <li><strong>High Life Coverage:</strong> Get a large sum assured at low premiums.</li>
                            <li><strong>Affordable Premiums:</strong> Cost-effective protection for your family.</li>
                            <li><strong>Payout Options:</strong> Choose between a lump sum, monthly income, or a combination of both.</li>
                            <li><strong>Tax Benefits:</strong> Premiums paid are eligible for tax deductions under applicable laws.</li>
                            <li><strong>Customizable Add-ons:</strong> Option to include riders like critical illness and accidental death benefits.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="child-plans-section">
                    <h2>Benefits of Term Insurance</h2>
                    <div className="section-content">
                        <img
                            src={BenefitsTermInsuranceImg}
                            alt="Benefits of Term Insurance"
                            className="section-img"
                        />
                        <div className="section-text">
                            <ul>
                                <li>✔ Ensures financial security for your family in case of untimely demise.</li>
                                <li>✔ Provides peace of mind with long-term protection.</li>
                                <li>✔ Flexibility to choose policy tenure and payout methods.</li>
                                <li>✔ Helps in loan repayment and other liabilities in your absence.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Who Should Opt for Term Insurance? */}
                <section className="child-plans-section">
                    <h2>Who Should Opt for Term Insurance?</h2>
                    <div className="section-content">
                        <div className="section-text">
                            <ul>
                                <li>✔ Individuals looking for a cost-effective life insurance plan.</li>
                                <li>✔ People with financial dependents, such as spouses, children, or elderly parents.</li>
                                <li>✔ Anyone seeking long-term financial protection for their loved ones.</li>
                                <li>✔ Individuals with loans or liabilities who want to secure their family’s future.</li>
                            </ul>
                        </div>
                        <img
                            src={WhoShouldOptTermImg}
                            alt="Who Should Opt for Term Insurance?"
                            className="section-img"
                        />
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="term-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                        <button className="term-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>

    );
}

export default Term_Insurance_Info;
