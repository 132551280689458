import Navbar from '../../pages/navbar/Navbar'
import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import ThirdPartyBikeInsuranceImg from '../../assets/images/bike-insurance.avif';
import BenefitsImg from '../../assets/images/Own_Damage_Bike_Insurance.jpg';
import WhoShouldOptImg from '../../assets/images/bike-third.avif';
import { Link } from 'react-router-dom'

function Third_Party_Bike_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Third-Party Bike Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Third-Party Bike Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={ThirdPartyBikeInsuranceImg}
                            alt="Third-Party Bike Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Third-Party Bike Insurance provides coverage against legal liabilities arising from accidents caused by your bike. It covers bodily injuries and property damage to third parties, ensuring that you're legally protected in case of an accident.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Third-Party Bike Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Third-Party Liability:</strong> Covers legal liabilities for third-party injuries or damages caused by your bike.</li>
                            <li><strong>✅ Legal Protection:</strong> Provides legal cover in case you're sued due to an accident involving your bike.</li>
                            <li><strong>✅ Bodily Injury Coverage:</strong> Covers medical expenses for injuries sustained by third parties in an accident.</li>
                            <li><strong>✅ Property Damage Coverage:</strong> Covers damages caused to third-party property due to an accident involving your bike.</li>
                            <li><strong>✅ Mandatory Coverage:</strong> Required by law in most countries to legally operate a bike on public roads.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Third-Party Bike Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Third-Party Bike Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Third-Party Bike Insurance:</strong></p>
                            <ul>
                                <li>Provides financial protection from legal liabilities in case of an accident involving your bike.</li>
                                <li>Protects against damages caused to third parties, including bodily injuries and property damage.</li>
                                <li>Meets the legal requirements to operate your bike on public roads.</li>
                                <li>Affordable and cost-effective coverage for bike owners.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Coverage Details */}
                <section className="Individual-Health-section">
                    <h2>Coverage Details</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✔ Third-Party Liability:</strong> Covers injuries or property damage to third parties caused by your bike.</li>
                            <li><strong>✔ Bodily Injury:</strong> Provides coverage for medical expenses for third-party injuries in an accident.</li>
                            <li><strong>✔ Property Damage:</strong> Pays for damages caused to third-party property during an accident.</li>
                            <li><strong>✔ Legal Fees:</strong> Covers legal expenses incurred in defending yourself in court if you’re sued due to an accident.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Third-Party Bike Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Third-Party Bike Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Third-Party Bike Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ All bike owners who want to comply with the law and protect against third-party liabilities.</li>
                                <li>✔ Individuals who want affordable coverage for their bike.</li>
                                <li>✔ Anyone looking for protection against legal and financial risks arising from accidents.</li>
                                <li>✔ New bike owners who are just starting out and need basic insurance coverage.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Third_Party_Bike_Info