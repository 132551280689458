import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Admin_dashboard.css";
import adminlogo from "../../assets/images/upholic.newlogo.png";
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";
// import PMS_Popup from "../admin_pms_popup/PMS_Popup";

function Admin_dashboard() {
  const [selectedOption, setSelectedOption] = useState("ytd"); // Set default to 'ytd'
  const [date, setDate] = useState(""); // For storing selected date when 'Custom' is selected
  const [totalAmount, setTotalAmount] = useState(0); // To store the total amount fetched from the API
  const [message, setMessage] = useState(""); // To store API message
  const [userdepositeCount, setUserdepositeCount] = useState(0); // For user deposit count
  const [userCount, setUserCount] = useState(0); // Define userCount as a state (default value: 100)
  const [totalDepositSum, setTotalDepositSum] = useState(0); // State for Total Deposit Amount
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [PMSuserCount, setPMSuserCount] = useState(0);
  const [valueOfCost, setValueOfCost] = useState(0);
  const [LIuserCount, setLIuserCount] = useState(0);
  const [insuredAmount, setInsuredAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]); // Initialize data as an empty array
  // const [isPMSPopupOpen, setIsPMSPopupOpen] = useState(false);
  


  const handleStartDateChange = (e) => {
    setStartDate(e.target.value); // Update start date
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value); // Update end date
  };

  // Handle change in MTD/YTD dropdown
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Handle change in date input (when 'Custom' is selected)
  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  // Combine fetchData logic
  const fetchData = async () => {
    try {
      // Format the dates to match 'YYYY/MM/DD'
      const formattedStartDate = startDate.split("-").join("/");
      const formattedEndDate = endDate.split("-").join("/");

      const requestData = {
        option: selectedOption,
        ...(selectedOption === "custom" && {
          start_date: formattedStartDate, // Send formatted date
          end_date: formattedEndDate, // Send formatted date
        }),
      };

      // First API call (for deposit data)
      const depositResponse = await axios.post(
        "https://www.upholic.in/Upholic_Api/admin_deposit_total.php",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setTotalAmount(depositResponse.data.total_amount);
      setUserdepositeCount(depositResponse.data.userdepositeCount);
      setTotalDepositSum(depositResponse.data.total_deposit_sum);

      // Second API call (for PMS user count)
      const pmsResponse = await axios.get(
        "https://www.upholic.in/Upholic_Api/count_pms_fund_users.php"
      );
      if (pmsResponse.data.status === "success") {
        setPMSuserCount(pmsResponse.data.user_count);
      } else {
        console.error("Error fetching user count:", pmsResponse.data.message);
      }

      const costResponse = await axios.post(
        "https://www.upholic.in/Upholic_Api/get_pms_fund_data.php",
        {
          option: selectedOption,
          start_date: formattedStartDate,
          end_date: formattedEndDate
        },
        {
          headers: {
            "Content-Type": "application/json"  // Make sure this is correct
          }
        }
      );
      setValueOfCost(costResponse.data.value_of_cost || 0); // Update state with the fetched data

      // Fourth API call (for insured amount)
      const insuredResponse = await axios.post(
        "https://www.upholic.in/Upholic_Api/li_main_dashboard.php",
        {
          selectedOption,
          startDate,
          endDate,
        }
      );
      if (insuredResponse.data.success) {
        setInsuredAmount(insuredResponse.data.totalAmount);
      } else {
        console.error(
          "Error fetching insured amount:",
          insuredResponse.data.message
        );
      }

    } catch (error) {
      console.error("There was an error fetching the data:", error);
    }
  };

  // Use effect hook to trigger the fetchData when selectedOption or date changes
  useEffect(() => {
    if (selectedOption) {
      fetchData(); // Fetch data when selectedOption or date changes
    }
  }, [selectedOption, date]);

  // Calculate the sum (convert to numbers)
  const totalCount = Number(PMSuserCount) + Number(userdepositeCount) + Number(LIuserCount);

  useEffect(() => {
    // Fetch total customer count from the backend API
    const fetchUserCount = async () => {
      try {
        const response = await axios.get("https://www.upholic.in/Upholic_Api/li_main_dashboard.php");
        if (response.data.success) {
          setLIuserCount(response.data.count);
        } else {
          console.error("Error fetching user count:", response.data.message);
        }
      } catch (error) {
        console.error("Error during API call:", error.message);
      }
    };

    fetchUserCount();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://www.upholic.in/Upholic_Api/cards_data_fetch.php');
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else {
          setData([]); // Ensure data is always an array
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;


  return (
    <div>
      <Admin_sidebar_links />
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">
          <div className="admin_dashboard_container">
            <div className="admin_dashboard_logo_img">
              <img
                src={adminlogo}
                alt="Logo"
                className="admin_dashboard_image_logo"
              />
            </div>
            <div className="admin_dashboard_search_bar">
              <input type="text" placeholder="Search here" />
              <ion-icon name="search" className="search-icon"></ion-icon>
            </div>
            <div className="admin_dashboard_logo2_bar">
              <h1 className="admin_dashboard_page_heading">Dashboard</h1>
            </div>
          </div>

          {/* Dropdown for selecting the option */}
          <div className="admin_dashboard_additionalInfo">
            <div className="admin_dashboard_mtd_ytd">
              <label htmlFor="mtd_ytd">Custom / MTD / YTD:</label>
              <select
                id="mtd_ytd"
                name="mtd_ytd"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)} // Update selectedOption
              >
                <option value="">Select</option>
                <option value="custom">Custom (Custom-to-Date)</option>
                <option value="mtd">MTD (Month-to-Date)</option>
                <option value="ytd">YTD (Year-to-Date)</option>
              </select>
            </div>

            {/* Show Date Inputs only when 'Custom' is selected */}
            {selectedOption === "custom" && (
              <div className="admin_dashboard_date_range">
                <div>
                  <label htmlFor="start_date">Start Date:</label>
                  <input
                    type="date"
                    id="start_date"
                    name="start_date"
                    value={startDate}
                    onChange={handleStartDateChange} // Handle change for start date
                  />
                </div>
                <div>
                  <label htmlFor="end_date">End Date:</label>
                  <input
                    type="date"
                    id="end_date"
                    name="end_date"
                    value={endDate}
                    onChange={handleEndDateChange} // Handle change for end date
                  />
                </div>

                {/* Fetch Data Button inside the custom date range section */}
                <button className="fetch-data-button" onClick={fetchData}>
                  Fetch Data
                </button>
              </div>
            )}
          </div>

          {/* Cards */}
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_numbers">
                  {totalCount}
                </div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_numbers">
                  {totalDepositSum > 0 ? `₹${totalDepositSum}` : "₹0"}
                </div>
                <div className="admin_dashboard_deposit_cardName">
                  Deposit Amt
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_numbers">₹{valueOfCost}</div>
                <div className="admin_dashboard_deposit_cardName">PMS Amt</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_numbers">₹{insuredAmount}</div>
                <div className="admin_dashboard_deposit_cardName">LI Amt</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
                <div className="admin_dashboard_deposit_cardName">Loan Amt</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PMS section  */}
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">

          {/* Cards */}
          <h1 className="admin_dashboard_card_name">PMS</h1>
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
                <div className="admin_dashboard_deposit_numbers">
                  {PMSuserCount}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>


            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">PMS Amt</div>
                <div className="admin_dashboard_deposit_numbers">₹{valueOfCost}</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Appreciated Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  revenue Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Life Insurance section  */}
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">


          {/* Cards */}
          <h1 className="admin_dashboard_card_name">Life Insurance</h1>
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
                <div className="admin_dashboard_deposit_numbers">
                  {LIuserCount}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  INSURED Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹{insuredAmount}</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  revenue Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  renewal Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Deposit  section */}
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">


          {/* Cards */}
          <h1 className="admin_dashboard_card_name">Deposit</h1>
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
                <div className="admin_dashboard_deposit_numbers">
                  {userdepositeCount} {/* Display the userdepositeCount */}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Deposit Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">
                  {totalDepositSum > 0 ? `₹${totalDepositSum}` : "₹0"}{" "}
                  {/* Show Total Deposit Sum */}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">debt Amt</div>
                <div className="admin_dashboard_deposit_numbers">
                  {/* {message && <p>{message}</p>} */}
                  {totalAmount > 0 ? `₹${totalAmount}` : "₹0"}{" "}
                  {/* Show total amount */}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  revenue Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Loan section */}
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">


          {/* Cards */}
          <h1 className="admin_dashboard_card_name">Loan</h1>
          <div className="admin_dashboard_deposit_cardBox">
            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Total Customer
                </div>
                <div className="admin_dashboard_deposit_numbers">
                  {userCount}
                </div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="people"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  Loan Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">debt Amt</div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>

            <div className="admin_dashboard_deposit_card">
              <div>
                <div className="admin_dashboard_deposit_cardName">
                  revenue Amt
                </div>
                <div className="admin_dashboard_deposit_numbers">₹0</div>
              </div>
              <div className="admin_dashboard_deposit_iconBx">
                <ion-icon name="cash"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Third Party section */}
      <div className="admin_dashboard_Card">
        <div className="admin_dashboard_area">


          {/* Cards */}
          <h1 className="admin_dashboard_card_name">Third Party</h1>
          <div className="admin_dashboard_deposit_cardBox">
            {data.map((item, index) => (
              <div className="admin_dashboard_deposit_card" key={index}>
                <div>
                  <div className="admin_dashboard_deposit_cardName">{item.name}</div>
                  <div className="admin_dashboard_deposit_numbers">{item.count}</div>
                </div>
                <div className="admin_dashboard_deposit_iconBx">
                  <ion-icon name="cash"></ion-icon>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin_dashboard;