import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import UninsuredMotoristImg from '../../assets/images/Comprehensive_Car_Insurance.jpg';
import BenefitsImg from '../../assets/images/car_insurance.avif';
import WhoShouldOptImg from '../../assets/images/Comprehensive-Car-Insurance.avif';
import { Link } from 'react-router-dom'

function Motorist_Protection_Info() {
    return (
        <>
            <Navbar/>
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Uninsured Motorist Protection Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Uninsured Motorist Protection Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={UninsuredMotoristImg}
                            alt="Uninsured Motorist Protection Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Uninsured Motorist Protection Insurance provides financial coverage if you're involved in an accident with a driver who does not have insurance. It helps cover medical expenses, lost wages, and damages when the at-fault party is uninsured or underinsured.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Uninsured Motorist Protection Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Protection Against Uninsured Drivers:</strong> Covers damages when the at-fault driver has no insurance.</li>
                            <li><strong>✅ Underinsured Motorist Coverage:</strong> Helps when the responsible party’s insurance is insufficient.</li>
                            <li><strong>✅ Medical Expense Coverage:</strong> Pays for injuries and hospital bills.</li>
                            <li><strong>✅ Lost Wages Compensation:</strong> Covers income lost due to accident-related injuries.</li>
                            <li><strong>✅ Hit-and-Run Coverage:</strong> Provides financial protection in case of a hit-and-run incident.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Uninsured Motorist Protection Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Uninsured Motorist Protection Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why Choose Uninsured Motorist Protection Insurance?</strong></p>
                            <ul>
                                <li>Ensures financial protection against uninsured drivers.</li>
                                <li>Covers medical expenses and lost wages.</li>
                                <li>Provides peace of mind in hit-and-run situations.</li>
                                <li>Protects against underinsured drivers.</li>
                                <li>Offers additional security beyond standard auto insurance.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Coverage Details */}
                <section className="Individual-Health-section">
                    <h2>Coverage Details</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✔ Medical Expenses:</strong> Covers hospitalization and treatment costs.</li>
                            <li><strong>✔ Lost Wages Compensation:</strong> Pays for income loss due to injury.</li>
                            <li><strong>✔ Hit-and-Run Accidents:</strong> Provides coverage when the driver cannot be identified.</li>
                            <li><strong>✔ Property Damage:</strong> Covers damage to your vehicle in some cases.</li>
                            <li><strong>❌ Intentional Damage:</strong> Not covered under this policy.</li>
                            <li><strong>❌ Own Fault Accidents:</strong> Requires separate collision coverage.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Uninsured Motorist Protection Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Uninsured Motorist Protection Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Uninsured Motorist Protection Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Drivers who want extra protection against uninsured motorists.</li>
                                <li>✔ Individuals living in areas with a high number of uninsured drivers.</li>
                                <li>✔ Vehicle owners seeking additional financial security.</li>
                                <li>✔ Anyone looking for comprehensive accident coverage beyond standard policies.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer/>
            <Copyright/>
        </>
    )
}

export default Motorist_Protection_Info