
function Loan_Card() {
    return (
        <>
            <div className="mf-investment-services-container">
                <h1 className="mf-investment-services-title">Loan Services</h1>
                <p className="mf-investment-services-description">
                    Our expert loan services help you unlock financial opportunities, ensuring flexibility and stability through tailored lending solutions.
                </p>
                <div className="mf-investment-services-grid">
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">💵</div>
                        <h2 className="mf-investment-services-heading">Flexible Loan Options</h2>
                        <p className="mf-investment-services-text">
                            Choose from a variety of loan options tailored to your needs, offering flexible terms and competitive rates.
                        </p>
                    </div>
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">🔍</div>
                        <h2 className="mf-investment-services-heading">Expert Guidance</h2>
                        <p className="mf-investment-services-text">
                            Receive professional advice to help you make informed decisions about the best loan options available for you.
                        </p>
                    </div>
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">🏡</div>
                        <h2 className="mf-investment-services-heading">Home Loans</h2>
                        <p className="mf-investment-services-text">
                            Secure the home of your dreams with our tailored home loan solutions offering affordable rates and flexible repayment terms.
                        </p>
                    </div>
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">📊</div>
                        <h2 className="mf-investment-services-heading">Loan Eligibility</h2>
                        <p className="mf-investment-services-text">
                            Get a clear understanding of your loan eligibility and the options available to you based on your financial profile.
                        </p>
                    </div>
                    <div className="mf-investment-services-card">
                        <div className="mf-investment-services-icon">⏳</div>
                        <h2 className="mf-investment-services-heading">Quick Approval</h2>
                        <p className="mf-investment-services-text">
                            Experience fast loan processing and approval, ensuring quick access to the funds you need.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loan_Card