import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import LandlordCoverInsuranceImg from '../../assets/images/Landlord_cover.jpg';
import BenefitsImg from '../../assets/images/WhoShouldOpttheft.avif';
import WhoShouldOptImg from '../../assets/images/who-Content-Cover.avif';
import { Link } from 'react-router-dom';

function Landlord_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Landlord Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Landlord Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={LandlordCoverInsuranceImg}
                            alt="Landlord Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Landlord Cover Insurance offers protection to property owners against financial loss or damage related to their rental properties. It covers risks such as property damage, loss of rental income, and legal expenses arising from disputes with tenants.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Landlord Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Property Damage Coverage:</strong> Protects against damage to rental property caused by tenants or unforeseen events.</li>
                            <li><strong>✅ Loss of Rent Protection:</strong> Covers loss of rental income due to tenants not paying rent or property being uninhabitable.</li>
                            <li><strong>✅ Legal Expense Coverage:</strong> Provides coverage for legal fees related to eviction or tenant disputes.</li>
                            <li><strong>✅ Accidental Damage:</strong> Covers accidental damage to furniture, fixtures, and fittings in the property.</li>
                            <li><strong>✅ Tenant Default:</strong> Offers protection against tenants defaulting on rent or causing damage to the property.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Landlord Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Landlord Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Landlord Cover Insurance:</strong></p>
                            <ul>
                                <li>Protects against property damage or loss caused by tenants or natural events.</li>
                                <li>Safeguards rental income in case of tenant default or property damage.</li>
                                <li>Offers financial coverage for legal issues related to tenant disputes or evictions.</li>
                                <li>Reduces the financial risk for landlords and provides peace of mind.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Landlord Cover Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Landlord Cover Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Basic Landlord Cover:</strong> Covers essential protection against property damage and loss of rental income.</li>
                            <li><strong>2️⃣ Comprehensive Landlord Cover:</strong> Extensive protection that includes property damage, tenant disputes, and legal expenses.</li>
                            <li><strong>3️⃣ Landlord Legal Protection:</strong> Specialized coverage for landlord-related legal issues, including eviction proceedings.</li>
                            <li><strong>4️⃣ Rent Guarantee Insurance:</strong> Provides rent payment protection in case tenants fail to pay rent on time.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Landlord Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Landlord Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Landlord Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Landlords who rent out residential or commercial properties to tenants.</li>
                                <li>✔ Property owners seeking protection against financial loss due to property damage or tenant defaults.</li>
                                <li>✔ Those who want peace of mind regarding legal disputes or eviction processes.</li>
                                <li>✔ Individuals looking to safeguard their rental income and assets from unforeseen risks.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />

        </>
    )
}

export default Landlord_Cover_Info