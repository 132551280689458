import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import FirePublicLiabilityCoverInsuranceImg from '../../assets/images/who-fire.avif';
import BenefitsImg from '../../assets/images/fire-ben.avif';
import WhoShouldOptImg from '../../assets/images/Public-fire.avif';
import { Link } from 'react-router-dom'

function Public_Liability_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Fire Public Liability Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Fire Public Liability Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={FirePublicLiabilityCoverInsuranceImg}
                            alt="Fire Public Liability Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Fire Public Liability Cover Insurance provides protection against legal and financial liabilities arising from fire-related incidents. It ensures coverage for property damage and bodily injuries caused to third parties due to fire hazards.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Fire Public Liability Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Third-Party Protection:</strong> Covers legal liabilities arising from fire-related damages to third parties.</li>
                            <li><strong>✅ Property Damage Coverage:</strong> Ensures financial aid for damaged properties due to fire incidents.</li>
                            <li><strong>✅ Legal Expense Coverage:</strong> Supports legal fees associated with fire-related claims.</li>
                            <li><strong>✅ Customizable Plans:</strong> Flexible coverage options based on business or individual needs.</li>
                            <li><strong>✅ Financial Security:</strong> Protects against unforeseen financial losses due to fire liabilities.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Fire Public Liability Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Fire Public Liability Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Fire Public Liability Cover Insurance:</strong></p>
                            <ul>
                                <li>Provides financial security against fire-related third-party claims.</li>
                                <li>Covers legal liabilities arising from fire damage.</li>
                                <li>Flexible plans suitable for businesses and property owners.</li>
                                <li>Reduces the financial burden of fire-related liabilities.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Fire Public Liability Cover Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Fire Public Liability Cover Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Standard Liability Cover:</strong> Covers basic third-party fire-related liabilities.</li>
                            <li><strong>2️⃣ Comprehensive Liability Cover:</strong> Extensive protection including property damage and legal expenses.</li>
                            <li><strong>3️⃣ Business Fire Liability Cover:</strong> Special coverage designed for business premises and commercial properties.</li>
                            <li><strong>4️⃣ Customized Plans:</strong> Tailored coverage based on risk assessment and business requirements.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Fire Public Liability Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Fire Public Liability Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Fire Public Liability Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Business owners securing their commercial properties from fire liabilities.</li>
                                <li>✔ Homeowners ensuring protection against fire-related third-party claims.</li>
                                <li>✔ Landlords protecting rental properties from fire-related legal risks.</li>
                                <li>✔ Anyone seeking legal and financial backup in case of fire-related liabilities.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Public_Liability_Cover_Info