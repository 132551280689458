import React, { useState, useEffect } from "react";
import axios from "axios";

function Deposit_Entry() {
  const [formData, setFormData] = useState({
    userId: "",
    depositId: "",
    name: "",
    amount: "",
    withdrawal: "",
    product: "deposit", // Ensure product is always "deposit"
    amountReceived: "",
    finalAmount: "",
    rate: "",
    transferred: "",
    payment: "",
    monthlyInterest: "",
    quarterlyInterest: "",
    yearlyInterest: "",
    dateOfInvestment: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Success message state
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [calculateMonthly, setCalculateMonthly] = useState(false);
  const [calculateQuarterly, setCalculateQuarterly] = useState(false);
  const [calculateYearly, setCalculateYearly] = useState(false); // Added state for yearly interest calculation

  const fetchData = async (userId) => {
    try {
      // Reset formData before fetching new data
      setFormData({
        userId: userId,
        depositId: "",
        name: "",
        amount: "",
        withdrawal: "",
        product: "deposit",
        amountReceived: "",
        finalAmount: "",
        rate: "",
        transferred: "",
        payment: "",
        monthlyInterest: "",
        quarterlyInterest: "",
        yearlyInterest: "",
        dateOfInvestment: "",
      });

      const response = await axios.get(
        `https://www.upholic.in/Upholic_Api/fetch_deposit.php?user_id=${userId}`
      );
      // console.log(response.data);

      if (response.data && response.data.length > 0) {
        const deposit = response.data[0]; // Assuming response is an array of deposits for the user
        setFormData((prevFormData) => ({
          ...prevFormData,
          userId: deposit.user_id,
          depositId: deposit.deposit_id, // Corrected key
          name: deposit.name || deposit.Name,
          amount: deposit.amount,
          withdrawal: deposit.withdrawal,
          product: deposit.product,
          amountReceived: deposit.amount_received,
          finalAmount: deposit.final_amount,
          rate: deposit.rate,
          transferred: deposit.transferred,
          payment: deposit.payment,
          monthlyInterest: deposit.monthly_interest,
          quarterlyInterest: deposit.quarterly_payment,
          yearlyInterest: deposit.yearly_interest,
          dateOfInvestment: deposit.date_of_investment,
        }));
      } else if (response.data.user) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          userId: response.data.user.Id,
          name: response.data.user.Name,
        }));
        setErrorMessage(
          "No deposit records found for this user. Enter the details."
        );
      } else {
        setErrorMessage("User not found in both deposit and sign_up tables");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("An error occurred while fetching data.");
    }
  };

  useEffect(() => {
    if (formData.userId) {
      fetchData(formData.userId);
    }
  }, [formData.userId]); // Re-fetch when userId changes

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // User ID validation: only numbers
    if (name === "userId" && !/^\d*$/.test(value)) {
      return; // Only numbers are allowed for userId
    }

    // Name validation: only alphabetic characters and spaces
    if (name === "name" && !/^[A-Za-z\s]*$/.test(value)) {
      return; // Only letters and spaces allowed for name
    }

    // Numeric fields validation (allow numbers and decimals)
    const numericFields = [
      "amount",
      "withdrawal",
      "amountReceived",
      "finalAmount",
      "rate",
      "transferred",
      // "payment",
      "monthlyInterest",
      "quarterlyInterest",
      "yearlyInterest",
      "depositId",
    ];

    if (numericFields.includes(name) && !/^\d*\.?\d*$/.test(value)) {
      return; // Only numbers and decimals are allowed
    }

    setFormData((prevFormData) => {
      const updatedData = { ...prevFormData, [name]: value };
      return calculateValues(updatedData, calculateMonthly, calculateQuarterly);
    });
  };

  const toggleMonthlyInterest = () => {
    setCalculateMonthly((prev) => !prev);
    setFormData((prevFormData) =>
      calculateValues(prevFormData, !calculateMonthly, calculateQuarterly)
    );
  };

  const toggleQuarterlyInterest = () => {
    setCalculateQuarterly((prev) => !prev);
    setFormData((prevFormData) =>
      calculateValues(prevFormData, calculateMonthly, !calculateQuarterly)
    );
  };

  const toggleYearlyInterest = () => {
    // This preserves the state across re-renders
    setCalculateYearly((prev) => {
      const newValue = !prev;
      setFormData((prevFormData) =>
        calculateValues(prevFormData, calculateMonthly, calculateQuarterly, newValue)
      );
      return newValue;
    });
  };

  const calculateValues = (data, monthly, quarterly, yearly) => {
    // Parse the amount, withdrawal, and rate from data, defaulting to 0 if not provided
    const amount = parseFloat(data.amount) || 0;
    const withdrawal = parseFloat(data.withdrawal) || 0;
    const rate = parseFloat(data.rate) || 8.5; // Default rate set to 8.5 if not provided

    // Calculate the final amount after withdrawal
    const finalAmount = amount - withdrawal;

    // Convert dateOfInvestment to a Date object
    const investmentDate = new Date(data.dateOfInvestment);
    const currentDate = new Date();

    // Ensure that the investment date is not in the future
    if (investmentDate > currentDate) {
      console.warn('Investment date is in the future. Using current date for calculation.');
      // If the investment date is in the future, set it to the current date
      investmentDate.setTime(currentDate.getTime());
    }

    // Calculate the difference in time between the current date and investment date
    const timeDiff = Math.abs(currentDate - investmentDate);

    // Convert time difference to days (milliseconds to days)
    const daysDiff = timeDiff / (1000 * 3600 * 24); // Convert from milliseconds to days

    // Calculate the yearly interest, proportionate to the number of days passed
    const yearlyInterest = (finalAmount * rate * daysDiff) / (365 * 100); // Annual interest calculation

    let monthlyInterest = null;
    let quarterlyInterest = null;

    // Calculate monthly interest if 'monthly' is true
    if (monthly) {
      // Calculate the number of months that have passed (using the average number of days in a month)
      let monthsDiff = daysDiff / 30.44; // Average days in a month
      // Cap the number of months to 1 (no more than 1 month)
      monthsDiff = Math.min(monthsDiff, 1);

      // Monthly interest = (Final Amount * Annual Rate * Months Passed) / 12 months
      monthlyInterest = (finalAmount * rate * monthsDiff) / (12 * 100); // Cap to 1 month
    }

    // Calculate quarterly interest if 'quarterly' is true
    if (quarterly) {
      // Check if at least 90 days (3 months) have passed since the investment date
      if (daysDiff >= 90) {
        // Quarterly interest = Monthly Interest * 3
        quarterlyInterest = monthlyInterest * 3;
      } else {
        // If less than 90 days have passed, don't show quarterly interest
        quarterlyInterest = null;
      }
    }

    // Return the data with calculated values, formatted to two decimal places where applicable
    return {
      ...data,
      finalAmount: finalAmount.toFixed(2),
      amountReceived: finalAmount.toFixed(2), // Set amountReceived equal to finalAmount
      monthlyInterest: monthly ? monthlyInterest.toFixed(2) : "",
      quarterlyInterest: quarterly ? (quarterlyInterest ? quarterlyInterest.toFixed(2) : "") : "",
      yearlyInterest: yearly ? yearlyInterest.toFixed(2) : "", // Only set if 'yearly' is true
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/deposit_data_entry.php",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        setSuccessMessage("Record inserted successfully!");
        // alert("Data inserted successfully!");
        setFormData({
          userId: "",
          depositId: "",
          name: "",
          amount: "",
          withdrawal: "",
          product: "deposit",
          amountReceived: "",
          finalAmount: "",
          rate: "",
          transferred: "",
          payment: "",
          monthlyInterest: "",
          quarterlyInterest: "",
          yearlyInterest: "",
          dateOfInvestment: "",
          // maturityDate: "",
          // notes: "",
        });
      } else {
        setErrorMessage(response.data.message || "Failed to insert data");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setErrorMessage("An error occurred while submitting the form.");
    }
  };

  const toggleAccordion = () => {
    setAccordionOpen((prev) => !prev);
  };

  return (
    <div className="viewall-container">
      <div className="section">
        <div className="section-title" onClick={toggleAccordion}>
          Deposit Entry (Customer Data)
        </div>
        {accordionOpen && (
          <>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}{" "}
            {/* Display success message */}
            <form className="Pms_insert_user_kyc-form" onSubmit={handleSubmit}>
              <div className="Pms_insert_user_kyc-row">
                <label>User ID</label>
                <input
                  type="text"
                  name="userId"
                  value={formData.userId}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Deposit ID</label>
                <input
                  type="text"
                  name="depositId"
                  value={formData.depositId}
                  onChange={handleInputChange}
                  required />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Amount</label>
                <input
                  type="text"
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Withdrawal</label>
                <input
                  type="text"
                  name="withdrawal"
                  value={formData.withdrawal}
                  onChange={handleInputChange}
                // required
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Product</label>
                <input
                  type="text"
                  name="product"
                  value={formData.product}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Amount Received</label>
                <input
                  type="text"
                  name="amountReceived"
                  value={formData.amountReceived || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Final Amount</label>
                <input
                  type="text"
                  name="finalAmount"
                  value={formData.finalAmount || ""} // Default to empty if value is not set
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Rate</label>
                <input
                  type="text"
                  name="rate"
                  value={formData.rate}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Transferred</label>
                <input
                  type="text"
                  name="transferred"
                  value={formData.transferred}
                  onChange={handleInputChange}
                // required
                />
              </div>
              
              <div className="Pms_insert_user_kyc-row">
                <label>Payment Method</label>
                <select
                  name="payment"
                  value={formData.payment}
                  onChange={handleInputChange}
                >
                  <option value="">-- Select --</option>
                  <option value="Cash">Cash</option>
                  <option value="cheque">cheque</option>
                </select>
              </div>

              <div className="Pms_insert_user_kyc-row">
                <label>Monthly Interest</label>
                <input
                  type="text"
                  name="monthlyInterest"
                  value={formData.monthlyInterest || ""} // Bind to the correct state property
                  readOnly
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Quarterly Interest</label>
                <input
                  type="text"
                  name="quarterlyInterest"
                  value={formData.quarterlyInterest}
                  readOnly
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Date of Investment</label>
                <input
                  type="date"
                  name="dateOfInvestment"
                  value={formData.dateOfInvestment}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>Yearly Interest</label>
                <input
                  type="text"
                  name="yearlyInterest"
                  value={formData.yearlyInterest} // Bind to the correct state property
                  readOnly
                />
              </div>

              {/* Toggles */}
              <div className="Pms_insert_user_kyc-row">
                <label>
                  <input
                    type="checkbox"
                    checked={calculateMonthly}
                    onChange={toggleMonthlyInterest}
                  />
                  Calculate Monthly Interest
                </label>
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>
                  <input
                    type="checkbox"
                    checked={calculateQuarterly}
                    onChange={toggleQuarterlyInterest}
                  />
                  Calculate Quarterly Interest
                </label>
              </div>
              <div className="Pms_insert_user_kyc-row">
                <label>
                  <input
                    type="checkbox"
                    checked={calculateYearly}
                    onChange={toggleYearlyInterest} // Added toggle for yearly interest
                  />
                  Calculate Yearly Interest
                </label>
              </div>
              <div className="Pms_insert_user_kyc-row">
                <button type="submit">Submit</button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default Deposit_Entry;
