import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import InternationalTravelInsuranceImg from '../../assets/images/inter-travel.jpg';
import BenefitsImg from '../../assets/images/International_Travel_Insurance.avif';
import WhoShouldOptImg from '../../assets/images/Domestic-Travel.jpg';
import { Link } from 'react-router-dom'

function International_Travel_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is International Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is International Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={InternationalTravelInsuranceImg}
                            alt="International Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                International Travel Insurance offers coverage for unexpected events and risks that may arise during your overseas travels. It provides protection for medical emergencies, trip cancellations, lost baggage, and more, ensuring a safe and worry-free journey across borders.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of International Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Medical Emergencies:</strong> Covers medical expenses and emergency evacuation during international trips.</li>
                            <li><strong>✅ Trip Cancellations:</strong> Offers compensation for trips canceled due to unforeseen events like illness or natural disasters.</li>
                            <li><strong>✅ Lost or Delayed Baggage:</strong> Provides reimbursement for baggage lost or delayed during your international travels.</li>
                            <li><strong>✅ 24/7 Emergency Assistance:</strong> Access to assistance for medical, legal, and travel-related issues no matter the time zone.</li>
                            <li><strong>✅ Coverage for Travel Delays:</strong> Reimburses extra costs incurred due to travel delays like accommodation and meals.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of International Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of International Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need International Travel Insurance:</strong></p>
                            <ul>
                                <li>Ensures medical expenses are covered while traveling abroad, preventing large unexpected costs.</li>
                                <li>Protects your trip investment by offering compensation for cancellations, delays, or interruptions.</li>
                                <li>Provides peace of mind with access to emergency services and assistance no matter where you are in the world.</li>
                                <li>Reduces the financial burden of dealing with travel-related risks such as lost baggage or missed connections.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of International Travel Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of International Travel Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Basic Travel Insurance:</strong> Covers essential needs such as medical emergencies, trip cancellations, and lost baggage.</li>
                            <li><strong>2️⃣ Comprehensive Travel Insurance:</strong> Extensive coverage including medical, baggage, trip interruptions, and legal assistance.</li>
                            <li><strong>3️⃣ Family Travel Insurance:</strong> Ideal for families traveling together, covering all members with a single policy.</li>
                            <li><strong>4️⃣ Student Travel Insurance:</strong> A cost-effective option for students traveling abroad for study or internships.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for International Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for International Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for International Travel Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ International travelers seeking protection for medical emergencies and travel delays abroad.</li>
                                <li>✔ Business travelers needing coverage for trip cancellations, medical costs, and lost baggage.</li>
                                <li>✔ Families or groups going overseas who want comprehensive coverage for all members.</li>
                                <li>✔ Students studying abroad or taking internships who need affordable travel insurance for international trips.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default International_Travel_Info