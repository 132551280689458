import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar'
import HomeContentCoverInsuranceImg from '../../assets/images/Home-Content-Cover.avif';
import BenefitsImg from '../../assets/images/Home_content_cover.webp';
import WhoShouldOptImg from '../../assets/images/who-Content-Cover.avif';
import { Link } from 'react-router-dom'

function Home_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Home Content Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Home Content Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={HomeContentCoverInsuranceImg}
                            alt="Home Content Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Home Content Cover Insurance provides financial protection for the belongings inside your home. It helps cover losses due to theft, fire, water damage, and other unforeseen events, ensuring peace of mind.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Home Content Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Comprehensive Coverage:</strong> Protects household belongings from theft, fire, and damages.</li>
                            <li><strong>✅ Compensation for Lost Items:</strong> Covers the cost of replacing stolen or damaged goods.</li>
                            <li><strong>✅ Affordable Premiums:</strong> Budget-friendly insurance options for homeowners and renters.</li>
                            <li><strong>✅ Customizable Plans:</strong> Tailor coverage based on your home’s contents and needs.</li>
                            <li><strong>✅ Peace of Mind:</strong> Financial security for unexpected losses.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Home Content Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Home Content Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Home Content Cover Insurance:</strong></p>
                            <ul>
                                <li>Protects valuable household possessions from unexpected damages.</li>
                                <li>Provides financial support for repair or replacement costs.</li>
                                <li>Flexible coverage options for different lifestyles.</li>
                                <li>Ensures peace of mind for homeowners and renters alike.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Home Content Cover Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Home Content Cover Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Basic Home Content Cover:</strong> Essential protection for everyday household items.</li>
                            <li><strong>2️⃣ Comprehensive Cover:</strong> Extensive protection including high-value items.</li>
                            <li><strong>3️⃣ Tenants’ Insurance:</strong> Special coverage designed for renters.</li>
                            <li><strong>4️⃣ Customized Plans:</strong> Tailored coverage based on individual requirements.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Home Content Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Home Content Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Home Content Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Homeowners who want protection for their personal belongings.</li>
                                <li>✔ Renters looking to secure their valuables from theft or damage.</li>
                                <li>✔ Anyone seeking financial support for unexpected household losses.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Home_Cover_Info