import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import SeniorCitizenTravelInsuranceImg from '../../assets/images/senior-travel.avif';
import BenefitsImg from '../../assets/images/Senior_Citizen_Travel_Insurance.jpeg';
import WhoShouldOptImg from '../../assets/images/senior_trevel.avif';
import { Link } from 'react-router-dom';

function Senior_Citizen_Travel_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Senior Citizen Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Senior Citizen Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={SeniorCitizenTravelInsuranceImg}
                            alt="Senior Citizen Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Senior Citizen Travel Insurance is a specialized policy designed for travelers aged 60 and above, offering financial protection against medical emergencies, trip cancellations, lost baggage, and other travel-related risks. It provides comprehensive coverage tailored to meet the unique needs of elderly travelers.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Senior Citizen Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Medical Coverage:</strong> Covers hospitalization, emergency medical treatments, and evacuation.</li>
                            <li><strong>✅ Pre-existing Condition Cover:</strong> Some policies provide limited coverage for pre-existing medical conditions.</li>
                            <li><strong>✅ Trip Cancellation & Interruption:</strong> Protection against financial losses due to unforeseen trip cancellations.</li>
                            <li><strong>✅ Lost Baggage & Documents:</strong> Assistance in case of lost baggage, passports, or other important documents.</li>
                            <li><strong>✅ 24/7 Assistance:</strong> Round-the-clock customer support for any travel-related emergencies.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Senior Citizen Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Senior Citizen Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why Choose Senior Citizen Travel Insurance?</strong></p>
                            <ul>
                                <li>Provides peace of mind for elderly travelers and their families.</li>
                                <li>Covers unexpected medical expenses during international or domestic travel.</li>
                                <li>Financial protection against trip cancellations and delays.</li>
                                <li>Comprehensive assistance for lost baggage, documents, and emergency medical evacuations.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Senior Citizen Travel Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Senior Citizen Travel Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Single Trip Senior Citizen Insurance:</strong> Coverage for a one-time journey.</li>
                            <li><strong>2️⃣ Multi-Trip Senior Citizen Insurance:</strong> Ideal for frequent travelers, offering coverage for multiple trips.</li>
                            <li><strong>3️⃣ Medical Travel Insurance:</strong> Provides extensive medical coverage, including emergency hospitalization.</li>
                            <li><strong>4️⃣ Family Travel Insurance:</strong> Covers elderly individuals traveling with family members.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Senior Citizen Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Senior Citizen Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Senior Citizen Travel Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Elderly individuals planning a domestic or international trip.</li>
                                <li>✔ Senior citizens traveling for leisure, business, or medical purposes.</li>
                                <li>✔ Individuals above 60 who want additional financial protection while traveling.</li>
                                <li>✔ Retirees who frequently travel for vacations or family visits.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Senior_Citizen_Travel_Info