import { Link } from "react-router-dom";
import loanImage from "../../assets/images/loan-img.jpeg";

function Loan_Build() {
    return (
        <>
            <div className="mutual-fund-try-container">
                <div className="mutual-fund-try-content" data-aos="fade-up">
                    <h3 className="mutual-fund-try-subtitle" data-aos="fade-up">
                        Loan 
                    </h3>
                    <h1 className="mutual-fund-try-title" data-aos="fade-up">
                        Unlock Your Financial Potential with Tailored Loan Solutions
                    </h1>
                    <p className="mutual-fund-try-description" data-aos="fade-up">
                        Our expert Loan Services provide flexible and customized lending solutions designed to meet your financial needs.
                    </p>
                    <Link to="/Signup">
                        <button className="mutual-fund-try-button" data-aos="fade-up">
                            Apply Now
                        </button>
                    </Link>
                </div>
                <div className="mutual-fund-try-image-container" data-aos="fade-up">
                    <img
                        src={loanImage}
                        alt="Loan Services illustration"
                        className="mutual-fund-try-image"
                    />
                </div>
            </div>
        </>
    )
}

export default Loan_Build