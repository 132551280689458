import React, { useState, useEffect } from "react";
import "./Pms_stock_entry.css";
import axios from "axios";

function Pms_stock_entry() {
  const [formData, setFormData] = useState({
    user_id: "",
    stock: "",
    sector: "",
    quantity: "",
    average_price: "",
    value_of_cost: "",
    percentage_changes: "",
    stock_invest_date: "",
    current_market_price: "",
    value_at_current_market_price: "",
    unrealizedPNL: "",
    realizedPNL: "",
    exit_date: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(false);

  // Function to handle form data input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(`Input changed: ${name} = ${value}`);

    const updatedFormData = { ...formData, [name]: value };

    // Perform calculations dynamically for fields like quantity and price
    if (name === "quantity" || name === "average_price") {
      const value_of_cost =
        parseFloat(updatedFormData.quantity || 0) *
        parseFloat(updatedFormData.average_price || 0) || "";
      updatedFormData.value_of_cost = value_of_cost;
      // console.log("Calculated Value of Cost:", value_of_cost);
    }

    if (
      name === "current_market_price" ||
      name === "quantity" ||
      name === "average_price"
    ) {
      const valueOfCost =
        parseFloat(updatedFormData.quantity || 0) *
        parseFloat(updatedFormData.average_price || 0) || 0;

      const valueAtCurrentMarketPrice =
        parseFloat(updatedFormData.quantity || 0) *
        parseFloat(updatedFormData.current_market_price || 0) || 0;

      updatedFormData.value_of_cost = valueOfCost || "";
      updatedFormData.value_at_current_market_price =
        valueAtCurrentMarketPrice || "";

      updatedFormData.unrealizedPNL =
        valueAtCurrentMarketPrice - valueOfCost || "";

      updatedFormData.percentage_changes =
        valueOfCost > 0
          ? (
            (parseFloat(updatedFormData.unrealizedPNL || 0) / valueOfCost) *
            100
          ).toFixed(2)
          : "";

      updatedFormData.realizedPNL = "0"; // Adjust this logic as necessary for realized PNL

      // console.log("Calculated Unrealized PNL:", updatedFormData.unrealizedPNL);
      // console.log(
      //   "Calculated Percentage Changes:",
      //   updatedFormData.percentage_changes
      // );
    }

    setFormData(updatedFormData);
  };

  // Function to fetch data when user_id changes
  useEffect(() => {
    const fetchDataByUserId = async () => {
      if (formData.user_id.trim() === "") return;

      // console.log(`Fetching data for User ID: ${formData.user_id}`); 
      try {
        const response = await axios.get(
          `https://www.upholic.in/Upholic_Api/fetchstocks.php?user_id=${formData.user_id}`
        );

        if (response.data.success) {
          // console.log("Data fetched successfully:", response.data.data);

          // Ensure the fetched data is correctly mapped to formData
          const fetchedData = response.data.data;
          // console.log("Fetched Data:", response.data.data); // Verify the structure

          // Update formData with fetched values
          setFormData({
            user_id: fetchedData.user_id || formData.user_id,
            stock: fetchedData.stock || "",
            sector: fetchedData.sector || "",
            quantity: fetchedData.quantity || "",
            average_price: fetchedData.average_price || "",
            value_of_cost: fetchedData.value_of_cost || "",
            percentage_changes: fetchedData.percentage_changes || "",
            stock_invest_date: fetchedData.stock_invest_date || "",
            current_market_price: fetchedData.current_market_price || "",
            value_at_current_market_price:
              fetchedData.value_at_current_market_price || "",
            unrealizedPNL: fetchedData.unrealizedPNL || "",
            realizedPNL: fetchedData.realizedPNL || "",
            exit_date: fetchedData.exit_date || "",
          });

          setSuccessMessage("Data fetched successfully.");
          setErrorMessage("");
        } else {
          // console.log("User ID not found.");
          setErrorMessage("User ID not found.");
          setSuccessMessage("");
          setFormData({
            user_id: formData.user_id,
            stock: "",
            sector: "",
            quantity: "",
            average_price: "",
            value_of_cost: "",
            percentage_changes: "",
            stock_invest_date: "",
            current_market_price: "",
            value_at_current_market_price: "",
            unrealizedPNL: "",
            realizedPNL: "",
            exit_date: "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage(
          error.response?.data?.message || "An unexpected error occurred."
        );
        setSuccessMessage("");
      }
    };

    fetchDataByUserId();
  }, [formData.user_id]); // useEffect triggers when user_id changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form submitted with data:", formData);
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const response = await axios.post(
        "https://www.upholic.in/Upholic_Api/insertstocks.php",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("Server response:", response.data);
      setSuccessMessage("Data inserted successfully.");
      setFormData({
        user_id: "",
        stock: "",
        sector: "",
        quantity: "",
        average_price: "",
        value_of_cost: "",
        percentage_changes: "",
        stock_invest_date: "",
        current_market_price: "",
        value_at_current_market_price: "",
        unrealizedPNL: "",
        realizedPNL: "",
        exit_date: "",
      });
      setErrorMessage("");
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMessage(
        error.response?.data?.message || "An unexpected error occurred."
      );
      setSuccessMessage("");
    }
  };

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
    // console.log("Accordion toggled:", accordionOpen);
  };

  return (
    <>
      <div className="viewall-container">
        <div className="section">
          <div className="section-title" onClick={toggleAccordion}>
            NAV
          </div>
          {accordionOpen && (
            <>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              <form className="investment-form" onSubmit={handleSubmit}>
                <div className="investment-row">
                  <label>User ID</label>
                  <input
                    type="text"
                    name="user_id"
                    value={formData.user_id}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="investment-row">
                  <label>Stock</label>
                  <select
                    id="stock"
                    name="stock"
                    value={formData.stock}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a stock</option>
                    <option value="HDFC Bank">HDFC Bank</option>
                    <option value="ICICI Bank">ICICI Bank</option>
                    <option value="State Bank of India">State Bank of India (SBI)</option>
                    <option value="Kotak Mahindra Bank">Kotak Mahindra Bank Ltd</option>
                    <option value="Axis Bank">Axis Bank</option>
                    <option value="IndusInd Bank">IndusInd Bank</option>
                    <option value="Bajaj Finance">Bajaj Finance</option>
                    <option value="Bajaj Finserv">Bajaj Finserv</option>
                    <option value="HDFC Life Insurance">HDFC Life Insurance</option>
                    <option value="SBI Life Insurance">SBI Life Insurance</option>


                    <option value="Tata Consultancy Services">Tata Consultancy Services (TCS)</option>
                    <option value="Infosys">Infosys</option>
                    <option value="HCL Technologies">HCL Technologies</option>
                    <option value="Wipro">Wipro</option>
                    <option value="Tech Mahindra">Tech Mahindra</option>
                    <option value="L&T Infotech">L&T Infotech</option>
                    <option value="Mindtree">Mindtree</option>
                    <option value="Mphasis">Mphasis</option>
                    <option value="Persistent Systems">Persistent Systems</option>
                    <option value="Coforge">Coforge</option>


                    <option value="Tata Motors">Tata Motors</option>
                    <option value="Maruti Suzuki">Maruti Suzuki</option>
                    <option value="Mahindra & Mahindra (M&M)">Mahindra & Mahindra (M&M)</option>
                    <option value="Bajaj Auto">Bajaj Auto</option>
                    <option value="Hero MotoCorp">Hero MotoCorp</option>
                    <option value="InfosysEicher Motors">Eicher Motors</option>
                    <option value="Ashok Leyland">Ashok Leyland</option>
                    <option value="TVS Motor Company">TVS Motor Company</option>
                    <option value="Escorts Kubota">Escorts Kubota</option>
                    <option value="Bharat Forge">Bharat Forge</option>


                    <option value="Reliance Industries (RIL)">Reliance Industries (RIL)</option>
                    <option value="Oil and Natural Gas Corporation (ONGC)">Oil and Natural Gas Corporation (ONGC)</option>
                    <option value="Bharat Petroleum Corporation Ltd (BPCL)">Bharat Petroleum Corporation Ltd (BPCL)</option>
                    <option value="Hindustan Petroleum Corporation Ltd (HPCL)">Hindustan Petroleum Corporation Ltd (HPCL)</option>
                    <option value="Indian Oil Corporation (IOC)">Indian Oil Corporation (IOC)</option>
                    <option value="NTPC">NTPC</option>
                    <option value="Power Grid Corporation of India">Power Grid Corporation of India</option>
                    <option value="Tata Power">Tata Power</option>
                    <option value="Adani Green Energy">Adani Green Energy</option>
                    <option value="GAIL (India)">GAIL (India)</option>


                    <option value="Hindustan Unilever (HUL)">Hindustan Unilever (HUL)</option>
                    <option value="ITC">ITC</option>
                    <option value="Nestlé India">Nestlé India</option>
                    <option value="Britannia Industries">Britannia Industries</option>
                    <option value="Dabur India">Dabur India</option>
                    <option value="Godrej Consumer Products">Godrej Consumer Products</option>
                    <option value="Marico">Marico</option>
                    <option value="Colgate-Palmolive India">Colgate-Palmolive India</option>
                    <option value="Procter & Gamble Hygiene & Health Care">Procter & Gamble Hygiene & Health Care</option>
                    <option value="Tata Consumer Products">Tata Consumer Products</option>


                    <option value="Sun Pharmaceutical Industries">Sun Pharmaceutical Industries</option>
                    <option value="Dr. Reddy's Laboratories">Dr. Reddy's Laboratories</option>
                    <option value="Cipla">Cipla</option>
                    <option value="Divi's Laboratories">Divi's Laboratories</option>
                    <option value="Aurobindo Pharma">Aurobindo Pharma</option>
                    <option value="Lupin">Lupin</option>
                    <option value="Biocon">Biocon</option>
                    <option value="Glenmark Pharmaceuticals">Glenmark Pharmaceuticals</option>
                    <option value="Apollo Hospitals">Apollo Hospitals</option>
                    <option value="Fortis Healthcare">Fortis Healthcare</option>



                    <option value="Tata Steel">Tata Steel</option>
                    <option value="JSW Steel">JSW Steel</option>
                    <option value="Hindalco Industries">Hindalco Industries</option>
                    <option value="Vedanta Limited">Vedanta Limited</option>
                    <option value="Coal India">Coal India</option>
                    <option value="NMDC LTD">NMDC LTD</option>
                    <option value="Hindustan Zinc">Hindustan Zinc</option>
                    <option value="SAIL (Steel Authority of India)">SAIL (Steel Authority of India)</option>
                    <option value="Jindal Steel & Power">Jindal Steel & Power</option>
                    <option value="National Aluminium Company (NALCO)">National Aluminium Company (NALCO)</option>


                    <option value="Larsen & Toubro (L&T)">Larsen & Toubro (L&T)</option>
                    <option value="UltraTech Cement">UltraTech Cement</option>
                    <option value="Ambuja Cements">Ambuja Cements</option>
                    <option value="Shree Cement">Shree Cement</option>
                    <option value="ACC Limited">ACC Limited</option>
                    <option value="Grasim Industries">Grasim Industries</option>
                    <option value="Adani Ports & SEZ">Adani Ports & SEZ</option>
                    <option value="IRB Infrastructure Developers">IRB Infrastructure Developers</option>
                    <option value="NCC Limited">NCC Limited</option>
                    <option value="Dilip Buildcon">Dilip Buildcon</option>





                    <option value="Bharti Airtel">Bharti Airtel</option>
                    <option value="Reliance Jio">Reliance Jio</option>
                    <option value="Vodafone Idea">Vodafone Idea</option>
                    <option value="Tata Communications">Tata Communications</option>


                    <option value="Zomato">Zomato</option>
                    <option value="Nykaa">Nykaa</option>
                    <option value="Trent Limited">Trent Limited</option>
                    <option value="Avenue Supermarts">Avenue Supermarts</option>
                    <option value="Future Retail">Future Retail</option>


                    <option value="Adani Enterprises">Adani Enterprises</option>
                    <option value="Adani Green Energy">Adani Green Energy</option>
                    <option value="Adani Transmission">Adani Transmission</option>
                    <option value="Asian Paints">Asian Paints</option>
                    <option value="Titan Company">Titan Company</option>
                    <option value="Berger Paints">Berger Paints</option>
                    <option value="Pidilite Industries">Pidilite Industries</option>
                    <option value="Havells India">Havells India</option>
                    <option value="Siemens India">Siemens India</option>
                    <option value="Cummins India">Cummins India</option>




                    <option value="Bandhan Bank">Bandhan Bank</option>
                    <option value="IDFC First Bank">IDFC First Bank</option>
                    <option value="Punjab National Bank">Punjab National Bank (PNB)</option>
                    <option value="Bank of Baroda">Bank of Baroda</option>
                    <option value="Canara Bank">Canara Bank</option>
                    <option value="Union Bank of India">Union Bank of India</option>
                    <option value="Federal Bank">Federal Bank</option>
                    <option value="RBL Bank">RBL Bank</option>
                    <option value="Yes Bank">Yes Bank</option>
                    <option value="AU Small Finance Bank">AU Small Finance Bank</option>
                    <option value="Cholamandalam Investment and Finance">Cholamandalam Investment and Finance</option>
                    <option value="Shriram Transport Finance">Shriram Transport Finance</option>
                    <option value="Muthoot Finance">Muthoot Finance</option>
                    <option value="Piramal Enterprises">Piramal Enterprises</option>
                    <option value="Piramal Enterprises">Piramal Enterprises</option>



                    <option value="Hexaware Technologies">Hexaware Technologies</option>
                    <option value="Cyient">Cyient</option>
                    <option value="Zensar Technologies">Zensar Technologies</option>
                    <option value="L&T Technology Services">L&T Technology Services</option>
                    <option value="Firstsource Solutions">Firstsource Solutions</option>
                    <option value="KPIT Technologies">KPIT Technologies</option>
                    <option value="Sonata Software">Sonata Software</option>
                    <option value="NIIT Technologies">NIIT</option>
                    <option value="Rolta India">Rolta India</option>
                    <option value="Tata Elxsi">Tata Elxsi</option>




                    <option value="Motherson Sumi Systems">Motherson Sumi Systems</option>
                    <option value="Bosch India">Bosch India</option>
                    <option value="Exide Industries">Exide Industries</option>
                    <option value="Amara Raja Batteries">Amara Raja Batteries</option>
                    <option value="MRF Tyres">MRF Tyres</option>
                    <option value="Apollo Tyres">Apollo Tyres</option>
                    <option value="CEAT Limited">CEAT Limited</option>
                    <option value="Bharat Forge">Bharat Forge</option>
                    <option value="Endurance Technologies">Endurance Technologies</option>
                    <option value="Sona BLW Precision Forgings">Sona BLW Precision Forgings</option>




                    <option value="Torrent Power">Torrent Power</option>
                    <option value="JSW Energy">JSW Energy</option>
                    <option value="NHPC Limited">NHPC Limited</option>
                    <option value="SJVN Limited">SJVN Limited</option>
                    <option value="CESC Limited">CESC Limited</option>
                    <option value="Gujarat State Petronet">Gujarat State Petronet</option>
                    <option value="Petronet LNG">Petronet LNG</option>
                    <option value="Indraprastha Gas Limited (IGL)">Indraprastha Gas Limited (IGL)</option>
                    <option value="Mahanagar Gas Limited (MGL)">Mahanagar Gas Limited (MGL)</option>
                    <option value="Adani Total Gas">Adani Total Gas</option>




                    <option value="Emami">Emami</option>
                    <option value="Jyothy Labs">Jyothy Labs</option>
                    <option value="Bajaj Consumer Care">Bajaj Consumer Care</option>
                    <option value="United Spirits">United Spirits</option>
                    <option value="United Breweries">United Breweries</option>
                    <option value="Radico Khaitan">Radico Khaitan</option>
                    <option value="Gillette India">Gillette India</option>
                    <option value="Bata India">Bata India</option>
                    <option value="VIP Industries">VIP Industries</option>
                    <option value="Relaxo Footwears">Relaxo Footwears</option>




                    <option value="Cadila Healthcare (Zydus Lifesciences)">Cadila Healthcare</option>
                    <option value="Torrent Pharmaceuticals">Torrent Pharmaceuticals</option>
                    <option value="Alkem Laboratories">Alkem Laboratories</option>
                    <option value="Ipca Laboratories">Ipca Laboratories</option>
                    <option value="Laurus Labs">Laurus Labs</option>
                    <option value="Natco Pharma">Natco Pharma</option>
                    <option value="Abbott India">Abbott India</option>
                    <option value="Sanofi India">Sanofi India</option>
                    <option value="Pfizer India">Pfizer India</option>
                    <option value="Metropolis Healthcare">Metropolis Healthcare</option>




                    <option value="Jindal Stainless">Jindal Stainless</option>
                    <option value="MOIL Limited">MOIL Limited</option>
                    <option value="Ratnamani Metals & Tubes">Ratnamani Metals & Tubes</option>
                    <option value="APL Apollo Tubes">APL Apollo Tubes</option>
                    <option value="Welspun Corp">Welspun Corp</option>
                    <option value="Jindal Saw">Jindal Saw</option>
                    <option value="Graphite India">Graphite India</option>
                    <option value="HEG Limited">HEG Limited</option>
                    <option value="NALCO">NALCO</option>
                    <option value="Gujarat Mineral Development Corporation">Gujarat Mineral Development Corporation</option>



                    <option value="GMR Infrastructure">GMR Infrastructure</option>
                    <option value="GVK Power & Infrastructure">GVK Power & Infrastructure</option>
                    <option value="Jaiprakash Associates">Jaiprakash Associates</option>
                    <option value="KNR Constructions">KNR Constructions</option>
                    <option value="PNC Infratech">PNC Infratech</option>
                    <option value="Sadbhav Engineering">Sadbhav Engineering</option>
                    <option value="Ashoka Buildcon">Ashoka Buildcon</option>
                    <option value="IRB Infrastructure">IRB Infrastructure</option>
                    <option value="NCC Limited">NCC Limited</option>
                    <option value="Hindustan Construction Company (HCC)">Hindustan Construction Company (HCC)</option>




                    <option value="Tata Teleservices (Maharashtra)">Tata Teleservices (Maharashtra)</option>
                    <option value="MTNL">MTNL</option>
                    <option value="ITI Limited">ITI Limited</option>
                    <option value="Sterlite Technologies">Sterlite Technologies</option>
                    <option value="Tejas Networks">Tejas Networks</option>



                    <option value="Pidilite Industries">Pidilite Industries</option>
                    <option value="Asian Paints">Asian Paints</option>
                    <option value="Berger Paints">Berger Paints</option>
                    <option value="Kansai Nerolac">Kansai Nerolac</option>
                    <option value="Akzo Nobel India">Akzo Nobel India</option>
                    <option value="SRF Limited">SRF Limited</option>
                    <option value="UPL Limited">UPL Limited</option>
                    <option value="Aarti Industries">Aarti Industries</option>
                    <option value="Deepak Nitrite">Deepak Nitrite</option>
                    <option value="Vinati Organics">Vinati Organics</option>





                    <option value="Arvind Limited">Arvind Limited</option>
                    <option value="Raymond">Raymond</option>
                    <option value="KPR Mill">KPR Mill</option>
                    <option value="Page Industries (Jockey India)">Page Industries (Jockey India)</option>
                    <option value="Welspun India">Welspun India</option>
                    <option value="Vardhman Textiles">Vardhman Textiles</option>
                    <option value="Trident Limited">Trident Limited</option>
                    <option value="Bombay Dyeing">Bombay Dyeing</option>
                    <option value="Indo Count Industries">Indo Count Industries</option>
                    <option value="Lux Industries">Lux Industries</option>





                    <option value="InterGlobe Aviation (IndiGo)">InterGlobe Aviation (IndiGo)</option>
                    <option value="SpiceJet">SpiceJet</option>
                    <option value="Blue Dart Express">Blue Dart Express</option>
                    <option value="Container Corporation of India">Container Corporation of India (CONCOR)</option>
                    <option value="Allcargo Logistics">Allcargo Logistics</option>
                    <option value="Gateway Distriparks">Gateway Distriparks</option>
                    <option value="Mahindra Logistics">Mahindra Logistics</option>
                    <option value="TCI Express">TCI Express</option>
                    <option value="VRL Logistics">VRL Logistics</option>
                    <option value="Snowman Logistics">Snowman Logistics</option>
                    
                    <option value="Zee Entertainment Enterprises">Zee Entertainment Enterprises</option>
                    <option value="Sun TV Network">Sun TV Network</option>
                    <option value="Dish TV India">Dish TV India</option>
                    <option value="Hathway Cable & Datacom">Hathway Cable & Datacom</option>
                    <option value="PVR Limited">PVR Limited</option>
                    <option value="Inox Leisure">Inox Leisure</option>
                    <option value="Eros International">Eros International</option>
                    <option value="Network18 Media & Investments">Network18 Media & Investments</option>
                    <option value="HT Media">HT Media</option>
                    <option value="DB Corp">DB Corp</option>
                    <option value="Saregama India">Saregama India</option>
                    <option value="Tips Industries">Tips Industries</option>
                    <option value="Nazara Technologies">Nazara Technologies</option>
                    <option value="Shemaroo Entertainment">Shemaroo Entertainment</option>
                    <option value="Prime Focus Limited">Prime Focus Limited</option>




                    <option value="DLF Limited">DLF Limited</option>
                    <option value="Godrej Properties">Godrej Properties</option>
                    <option value="Oberoi Realty">Oberoi Realty</option>
                    <option value="Prestige Estates Projects">Prestige Estates Projects</option>
                    <option value="Sobha Limited">Sobha Limited</option>
                    <option value="Brigade Enterprises">Brigade Enterprises</option>
                    <option value="Mahindra Lifespace Developers">Mahindra Lifespace Developers</option>
                    <option value="Sunteck Realty">Sunteck Realty</option>
                    <option value="Indiabulls Real Estate">Indiabulls Real Estate</option>
                    <option value="Phoenix Mills">Phoenix Mills</option>
                    <option value="Lodha Group (Macrotech Developers)">Lodha Group (Macrotech Developers)</option>
                    <option value="Anant Raj Limited">Anant Raj Limited</option>
                    <option value="Kolte-Patil Developers">Kolte-Patil Developers</option>
                    <option value="Puravankara Limited">Puravankara Limited</option>
                    <option value="Sobha Limited">Sobha Limited</option>



                    <option value="Indian Railway Catering and Tourism Corporation (IRCTC)">Indian Railway Catering and Tourism Corporation (IRCTC)</option>
                    <option value="Container Corporation of India (CONCOR)">Container Corporation of India (CONCOR)</option>
                    <option value="Coal India Limited">Coal India Limited</option>
                    <option value="Bharat Heavy Electricals Limited (BHEL)">Bharat Heavy Electricals Limited (BHEL)</option>
                    <option value="GAIL (India)">GAIL (India)</option>
                    <option value="Hindustan Aeronautics Limited (HAL)">Hindustan Aeronautics Limited (HAL)</option>
                    <option value="Mazagon Dock Shipbuilders">Mazagon Dock Shipbuilders</option>
                    <option value="Rail Vikas Nigam Limited (RVNL)">Rail Vikas Nigam Limited (RVNL)</option>
                    <option value="IRCON International">IRCON International</option>
                    <option value="RITES Limited">RITES Limited</option>




                    <option value="Karnataka Bank">Karnataka Bank</option>
                    <option value="Karur Vysya Bank">Karur Vysya Bank</option>
                    <option value="South Indian Bank">South Indian Bank</option>
                    <option value="DCB Bank">DCB Bank</option>
                    <option value="Equitas Small Finance Bank">Equitas Small Finance Bank</option>
                    <option value="Ujjivan Small Finance Bank">Ujjivan Small Finance Bank</option>
                    <option value="City Union Bank">City Union Bank</option>
                    <option value="Indian Bank">Indian Bank</option>
                    <option value="Indian Overseas Bank">Indian Overseas Bank</option>
                    <option value="Central Bank of India">Central Bank of India</option>
                    <option value="Bank of India">Bank of India</option>
                    <option value="Bank of Maharashtra">Bank of Maharashtra</option>
                    <option value="Punjab & Sind Bank">Punjab & Sind Bank</option>
                    <option value="L&T Finance Holdings">L&T Finance Holdings</option>
                    <option value="Edelweiss Financial Services">Edelweiss Financial Services</option>
                    <option value="Motilal Oswal Financial Services">Motilal Oswal Financial Services</option>
                    <option value="IIFL Finance">IIFL Finance</option>
                    <option value="Mahindra & Mahindra Financial Services">Mahindra & Mahindra Financial Services</option>
                    <option value="SREI Infrastructure Finance">SREI Infrastructure Finance</option>
                    <option value="3i Infotech">3i Infotech</option>



                    <option value="Force Motors">Force Motors</option>
                    <option value="Mahindra CIE Automotive">Mahindra CIE Automotive</option>
                    <option value="Balkrishna Industries (BKT)">Balkrishna Industries (BKT)</option>
                    <option value="Ramkrishna Forgings">Ramkrishna Forgings</option>




                    <option value="Gujarat Gas">Gujarat Gas</option>
                    <option value="V-Mart Retail">V-Mart Retail</option>
                    <option value="Jubilant FoodWorks (Domino’s India)">Jubilant FoodWorks (Domino’s India)</option>
                    <option value="Dr. Lal PathLabs">Dr. Lal PathLabs</option>
                    <option value="Thyrocare Technologies">Thyrocare Technologies</option>
                    <option value="Narayana Hrudayalaya">Narayana Hrudayalaya</option>
                    <option value="Jindal Stainless">Jindal Stainless</option>
                    <option value="NMDC Steel LTD">NMDC Steel LTD</option>
                    <option value="GTL Infrastructure">GTL Infrastructure</option>
                    <option value="Paytm (One97 Communications)">Paytm (One97 Communications)</option>
                    <option value="Atul Limited">Atul Limited</option>
                    <option value="Navin Fluorine International">Navin Fluorine International</option>
                    <option value="Gujarat Fluorochemicals">Gujarat Fluorochemicals</option>
                    <option value="PI Industries">PI Industries</option>
                    <option value="Tata Chemicals">Tata Chemicals</option>
                    <option value="Alok Industries">Alok Industries</option>
                    <option value="Nahar Spinning Mills">Nahar Spinning Mills</option>
                    <option value="Sutlej Textiles">Sutlej Textiles</option>
                    <option value="RSWM Limited">RSWM Limited</option>
                    <option value="Siyaram Silk Mills">Siyaram Silk Mills</option>
                    <option value="Garden Reach Shipbuilders & Engineers (GRSE)">Garden Reach Shipbuilders & Engineers (GRSE)</option>
                    {" "}
                  </select>
                </div>
                <div className="investment-row">
                  <label>Sector</label>
                  <input
                    type="text"
                    name="sector"
                    value={formData.sector}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Quantity</label>
                  <input
                    type="number"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Average Price</label>
                  <input
                    type="number"
                    step="0.01"
                    name="average_price"
                    value={formData.average_price}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Value of Cost</label>
                  <input
                    type="text"
                    name="value_of_cost"
                    value={formData.value_of_cost}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Percentage Changes</label>
                  <input
                    type="text"
                    name="percentage_changes"
                    value={formData.percentage_changes}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Stock Invest Date</label>
                  <input
                    type="date"
                    name="stock_invest_date"
                    value={formData.stock_invest_date}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Current Market Price</label>
                  <input
                    type="number"
                    step="0.01"
                    name="current_market_price"
                    value={formData.current_market_price}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <label>Value at Current Market Price</label>
                  <input
                    type="text"
                    name="value_at_current_market_price"
                    value={formData.value_at_current_market_price}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Unrealized PNL</label>
                  <input
                    type="text"
                    name="unrealizedPNL"
                    value={formData.unrealizedPNL}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Realized PNL</label>
                  <input
                    type="text"
                    name="realizedPNL"
                    value={formData.realizedPNL}
                    readOnly
                  />
                </div>
                <div className="investment-row">
                  <label>Exit Date</label>
                  <input
                    type="date"
                    name="exit_date"
                    value={formData.exit_date}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="investment-row">
                  <button type="submit" className="edit-button">
                    Insert
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Pms_stock_entry;
