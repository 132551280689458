import './Endowment_Plan_Info.css';
import EndowmentPlanImg from '../../assets/images/Endowment_Plan.jpg';
import SavingPractice from '../../assets/images/Saving_Practice.png';
import LongerTerm from '../../assets/images/Longer_Term.png';
import TaxBenefits from '../../assets/images/Tax_Benefits.png';
import FutureGoals from '../../assets/images/Future_Goals.png';
import DeathBenefits from '../../assets/images/Death_Benefits.png';
import GuaranteedReturns from '../../assets/images/Guaranteed_Returns.png';
import PensionFund from '../../assets/images/Pension_Fund.png';
import Navbar from '../../pages/navbar/Navbar';
import Footer from '../../pages/footer/Footer';
import Copyright from '../../pages/copyright/Copyright';
import { Link } from 'react-router-dom';

function Endowment_Plan_Info() {
    return (
        <>
            <Navbar />
            <div className='endowment-container'>
                <h1 className='endowment-heading'>Endowment Insurance</h1>
                <div className='endowment-content'>
                    <div className='endowment-image-container'>
                        <img src={EndowmentPlanImg} alt='Endowment Insurance' className='endowment-image' />
                    </div>
                    <div className='endowment-text-container'>
                        <h3 className='endowment-subheading'>What are Endowment Insurance Plans?</h3>
                        <p className='endowment-descriptionn'>
                            Endowment insurance plans provide both protection and savings benefits...
                        </p>
                        <button className='learn-more-btn'>Learn More</button>
                    </div>
                </div>
                <div className='endowment-benefits-container'>
                    <h2 className='endowment-benefits-title'>Benefits of Endowment Plans</h2>
                    <div className='endowment-benefits-row'>
                        <div className='endowment-benefits-item'>
                            <img src={SavingPractice} className='endowment-benefits-img' alt='Saving Practice' />
                            <h3 className='endowment-benefits-heading'>Saving Practice</h3>
                        </div>
                        <div className='endowment-benefits-item'>
                            <img src={LongerTerm} className='endowment-benefits-img' alt='Longer Term' />
                            <h3 className='endowment-benefits-heading'>Longer Term</h3>
                        </div>
                        <div className='endowment-benefits-item'>
                            <img src={TaxBenefits} className='endowment-benefits-img' alt='Tax Benefits' />
                            <h3 className='endowment-benefits-heading'>Tax Benefits</h3>
                        </div>
                        <div className='endowment-benefits-item'>
                            <img src={FutureGoals} className='endowment-benefits-img' alt='Future Goals' />
                            <h3 className='endowment-benefits-heading'>Future Goals</h3>
                        </div>
                    </div>
                    <div className='endowment-benefits-row'>
                        <div className='endowment-benefits-item'>
                            <img src={DeathBenefits} className='endowment-benefits-img' alt='Death Benefits' />
                            <h3 className='endowment-benefits-heading'>Death Benefits</h3>
                        </div>
                        <div className='endowment-benefits-item'>
                            <img src={GuaranteedReturns} className='endowment-benefits-img' alt='Guaranteed Returns' />
                            <h3 className='endowment-benefits-heading'>Guaranteed Returns</h3>
                        </div>
                        <div className='endowment-benefits-item'>
                            <img src={PensionFund} className='endowment-benefits-img' alt='Pension Fund' />
                            <h3 className='endowment-benefits-heading'>Pension Fund</h3>
                        </div>
                    </div>
                </div>
                <div className='endowment-contact-container'>
                    <h3 className='endowment-descriptionn'>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className='endowment-contact-button'>Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    );
}

export default Endowment_Plan_Info;