import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import FireCoverInsuranceImg from '../../assets/images/Fire_cover.avif';
import BenefitsImg from '../../assets/images/Fire-Cover-Insurance-Be.avif';
import WhoShouldOptImg from '../../assets/images/who-fire.avif';
import { Link } from 'react-router-dom'

function Fire_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Fire Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Fire Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={FireCoverInsuranceImg}
                            alt="Fire Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Fire Cover Insurance provides financial protection against damages caused by fire incidents. It ensures coverage for property and belongings, helping individuals recover from fire-related losses with minimal financial burden.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Fire Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Property Protection:</strong> Covers damages to homes, businesses, and personal belongings.</li>
                            <li><strong>✅ Financial Assistance:</strong> Helps in rebuilding and replacing damaged assets.</li>
                            <li><strong>✅ Flexible Coverage:</strong> Customizable plans to suit different property types.</li>
                            <li><strong>✅ Affordable Premiums:</strong> Cost-effective solutions for fire protection.</li>
                            <li><strong>✅ Peace of Mind:</strong> Security against unexpected fire-related losses.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Fire Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Fire Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Fire Cover Insurance:</strong></p>
                            <ul>
                                <li>Provides financial security against fire damage.</li>
                                <li>Supports rebuilding and restoration efforts.</li>
                                <li>Flexible plans for residential and commercial properties.</li>
                                <li>Reduces financial burden in case of unforeseen fire incidents.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Fire Cover Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Fire Cover Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Basic Fire Insurance:</strong> Covers essential fire-related damages.</li>
                            <li><strong>2️⃣ Comprehensive Fire Cover:</strong> Extensive protection including property and belongings.</li>
                            <li><strong>3️⃣ Business Fire Insurance:</strong> Special coverage designed for businesses.</li>
                            <li><strong>4️⃣ Customized Plans:</strong> Tailored coverage based on property type and needs.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Fire Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Fire Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Fire Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Homeowners looking to protect their property from fire damage.</li>
                                <li>✔ Business owners securing their commercial assets.</li>
                                <li>✔ Anyone seeking financial backup in case of fire-related losses.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />

        </>
    )
}

export default Fire_Cover_Info