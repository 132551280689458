import Copyright from "../../pages/copyright/Copyright";
import Footer from "../../pages/footer/Footer";
import Navbar from "../../pages/navbar/Navbar";
import { Link } from "react-router-dom";
import NRIInsuranceImg from '../../assets/images/Group-Travel-Insurance.avif';
import BenefitsNRIInsuranceImg from '../../assets/images/Group_Life_Insurance.jpg';
import WhoShouldOptNRIImg from '../../assets/images/family_insurance.avif';

function Nri_Insurance_Info() {
    return (
        <>
            <Navbar/>
            <div className="Individual-Health-containerr">
                {/* Section 1: What is NRI Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is NRI Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={NRIInsuranceImg}
                            alt="NRI Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                NRI Insurance is a specialized insurance policy designed to provide financial security and investment opportunities to Non-Resident Indians (NRIs). These plans cover health, life, and investment-linked policies, ensuring peace of mind for NRIs and their families living in India or abroad.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of NRI Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Global Coverage:</strong> Provides coverage for NRIs residing abroad with options for health and life protection.</li>
                            <li><strong>✅ Investment Benefits:</strong> Many plans offer market-linked returns along with life cover.</li>
                            <li><strong>✅ Tax Savings:</strong> Premiums paid may be eligible for tax benefits under Indian tax laws.</li>
                            <li><strong>✅ Family Protection:</strong> Ensures financial security for family members residing in India.</li>
                            <li><strong>✅ Flexible Premium Payment:</strong> Options to pay premiums in INR or foreign currency.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of NRI Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsNRIInsuranceImg}
                            alt="Benefits of NRI Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 For NRIs:</strong></p>
                            <ul>
                                <li>Financial security for family members in India in case of emergencies.</li>
                                <li>Tax-efficient investment options with wealth-building opportunities.</li>
                                <li>Flexibility to choose from various policy terms and premium payment options.</li>
                                <li>Access to worldwide medical and life insurance benefits.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of NRI Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of NRI Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Life Insurance Plans:</strong> Provides financial security for family members in case of an unfortunate event.</li>
                            <li><strong>2️⃣ Health Insurance Plans:</strong> Offers medical coverage for NRIs and their dependents in India.</li>
                            <li><strong>3️⃣ ULIP Plans:</strong> Combines investment and insurance benefits with market-linked returns.</li>
                            <li><strong>4️⃣ Retirement Plans:</strong> Ensures a stable income for NRIs planning to retire in India.</li>
                            <li><strong>5️⃣ Term Insurance:</strong> Provides pure life cover at affordable premiums for NRIs.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for NRI Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for NRI Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptNRIImg}
                            alt="Who Should Opt for NRI Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Non-Resident Indians (NRIs) looking for insurance coverage in India.</li>
                                <li>✔ NRIs who wish to secure their family's financial future.</li>
                                <li>✔ Individuals planning to return to India after retirement.</li>
                                <li>✔ NRIs looking for tax-saving investment opportunities.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                        <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer/>
            <Copyright/>
        </>
    )
}

export default Nri_Insurance_Info