import Copyright from '../../pages/copyright/Copyright';
import Footer from '../../pages/footer/Footer';
import Navbar from '../../pages/navbar/Navbar';
import DeferredAnnuityInsuranceImg from '../../assets/images/Deferred_Annuity.avif';
import DeferredBenefitsImg from '../../assets/images/DeferredBenefitsImg.avif';
import DeferredWhoShouldOptImg from '../../assets/images/DeferredWhoShouldOptImg.avif';
import { Link } from 'react-router-dom'

function Deferred_Annuity_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Deferred Annuity Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Deferred Annuity Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={DeferredAnnuityInsuranceImg}
                            alt="Deferred Annuity Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Deferred Annuity Insurance is a type of insurance policy where the payouts or income are delayed until a future date. It allows individuals to accumulate funds over time, with the option to receive regular payments later, often during retirement.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Deferred Annuity Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Future Income Stream:</strong> Provides a steady income stream after the deferral period ends.</li>
                            <li><strong>✅ Tax-Deferred Growth:</strong> The value of the annuity grows without immediate taxation during the accumulation phase.</li>
                            <li><strong>✅ Flexible Payment Options:</strong> Offers various payment structures, including lifetime or fixed-period payments.</li>
                            <li><strong>✅ Customizable Contracts:</strong> Allows policyholders to choose the duration and amount of payments once the annuity begins.</li>
                            <li><strong>✅ Death Benefit:</strong> Some policies include a death benefit that ensures the policyholder's beneficiaries receive a payout in case of death before the annuity starts.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Deferred Annuity Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={DeferredBenefitsImg}
                            alt="Benefits of Deferred Annuity Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Deferred Annuity Insurance:</strong></p>
                            <ul>
                                <li>Helps in planning for long-term financial security, especially during retirement.</li>
                                <li>Offers tax-deferred growth, increasing the value of your investment.</li>
                                <li>Provides predictable income after the deferral period ends, ensuring a stable financial future.</li>
                                <li>Allows individuals to delay receiving payouts, providing flexibility in retirement planning.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Deferred Annuity Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Deferred Annuity Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Fixed Deferred Annuity:</strong> Offers guaranteed returns over the accumulation phase and fixed payouts after the deferral period.</li>
                            <li><strong>2️⃣ Variable Deferred Annuity:</strong> Allows investment in a range of sub-accounts with variable returns, subject to market performance.</li>
                            <li><strong>3️⃣ Indexed Deferred Annuity:</strong> Links returns to a stock market index, offering potential for higher returns while providing some level of protection from market downturns.</li>
                            <li><strong>4️⃣ Immediate Deferred Annuity:</strong> Begins payments soon after the deferral period ends, often within one year.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Deferred Annuity Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Deferred Annuity Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={DeferredWhoShouldOptImg}
                            alt="Who Should Opt for Deferred Annuity Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals looking to secure their financial future with guaranteed income in retirement.</li>
                                <li>✔ People who have a long-term investment horizon and want tax-deferred growth.</li>
                                <li>✔ Those seeking to diversify their retirement portfolio with steady and predictable income.</li>
                                <li>✔ Individuals interested in protecting their family by including a death benefit in their annuity contract.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Deferred_Annuity_Info