import React, { useState } from "react";
import "./Pms_dashboard.css";
import Pms_login_data from "../pms_login_entry/Pms_login_data";
import Pms_insert_user_kyc from "../pms_insert_user_kyc/Pms_insert_user_kyc";
import Pms_stock_entry from "../pms_stock_entry/Pms_stock_entry";
import Admin_sidebar_links from "../admin_sidebar_links/Admin_sidebar_links";
import Deposit_Data_Dashboard from "../depsoite_data_dashboard/Deposit_Data_Dashboard";
import Life_insurance_home from "../admin_life_insurance_home/Life_insurance_home";
import Third_Party_Entry from "../admin_third_party_entry/Third_Party_Entry";

function Pms_dashboard() {
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <>
      <Admin_sidebar_links />

      {/* Dropdown for selecting section */}
      <div className="pms_dropdown-container">
        <label>Select Section:</label>
        <select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="">-- Select --</option>
          <option value="PMS">PMS</option>
          <option value="Deposit">Deposit</option>
          <option value="LifeInsurance">Life Insurance</option>
          <option value="ThirdParty">Third Party</option>
        </select>
      </div>

      {/* Main content area */}
      <div className="pms_main-content">
        {/* Render selected section only after choosing an option */}
        {selectedOption === "PMS" && (
          <div className="pms_dashboard_area">
            <div className="Pms_dashboard_card">
              <div className="Pms_dashboard_section_title">PMS Stocks</div>
              <div className="accordion-content">
                <Pms_login_data />
                <Pms_insert_user_kyc />
                <Pms_stock_entry />
              </div>
            </div>
          </div>
        )}

        {selectedOption === "Deposit" && <Deposit_Data_Dashboard />}
        {selectedOption === "LifeInsurance" && <Life_insurance_home />}
        {selectedOption === "ThirdParty" && <Third_Party_Entry />}
      </div>
    </>
  );
}

export default Pms_dashboard;