import Copyright from '../../pages/copyright/Copyright';
import Footer from '../../pages/footer/Footer';
import Navbar from '../../pages/navbar/Navbar';
import TheftCoverInsuranceImg from '../../assets/images/Theft_cover.avif';
import BenefitsImg from '../../assets/images/Theft-Cover-home.avif';
import WhoShouldOpttheft from '../../assets/images/WhoShouldOpttheft.avif';
import { Link } from 'react-router-dom';

function Theft_Cover_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Theft Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Theft Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={TheftCoverInsuranceImg}
                            alt="Theft Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Theft Cover Insurance offers protection against theft and burglary, ensuring compensation for loss or damage to property due to criminal activities. It provides peace of mind to individuals and businesses against theft-related risks.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Theft Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Protection Against Theft:</strong> Covers loss or damage due to theft, burglary, and robbery.</li>
                            <li><strong>✅ Property Coverage:</strong> Ensures compensation for stolen or damaged property during theft incidents.</li>
                            <li><strong>✅ Legal Expenses:</strong> Provides financial coverage for legal fees related to theft claims.</li>
                            <li><strong>✅ Customizable Plans:</strong> Offers flexible coverage options to meet individual or business needs.</li>
                            <li><strong>✅ Financial Security:</strong> Protects against unforeseen financial loss due to theft and related liabilities.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Theft Cover Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Theft Cover Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Theft Cover Insurance:</strong></p>
                            <ul>
                                <li>Provides financial security against theft-related claims and losses.</li>
                                <li>Covers legal liabilities arising from theft damage.</li>
                                <li>Offers flexible plans for both personal and business protection.</li>
                                <li>Reduces the financial burden from theft-related incidents.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Theft Cover Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Theft Cover Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Basic Theft Cover:</strong> Covers basic protection against theft and burglary.</li>
                            <li><strong>2️⃣ Comprehensive Theft Cover:</strong> Provides extensive coverage including property damage and legal expenses.</li>
                            <li><strong>3️⃣ Business Theft Cover:</strong> Tailored coverage for commercial properties and business premises.</li>
                            <li><strong>4️⃣ Customized Plans:</strong> Flexible options based on the risk assessment of your property or business.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Theft Cover Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Theft Cover Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOpttheft}
                            alt="Who Should Opt for Theft Cover Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Homeowners protecting their property from theft and burglary risks.</li>
                                <li>✔ Business owners securing their commercial properties from theft liabilities.</li>
                                <li>✔ Landlords safeguarding rental properties against theft-related claims.</li>
                                <li>✔ Anyone looking for legal and financial protection from theft and robbery incidents.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Theft_Cover_Info