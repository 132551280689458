import Navbar from '../../pages/navbar/Navbar'
import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer';
import DomesticTravelInsuranceImg from '../../assets/images/Domestic-Travel.jpg';
import BenefitsImg from '../../assets/images/Domestic_Travel_Insurance.jpg';
import WhoShouldOptImg from '../../assets/images/International_Travel_Insurance.avif';
import { Link } from 'react-router-dom';

function Domestic_Travel_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Domestic Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Domestic Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={DomesticTravelInsuranceImg}
                            alt="Domestic Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Domestic Travel Insurance provides coverage for various unexpected events that may occur while traveling within the country. This includes coverage for trip cancellations, medical emergencies, loss of baggage, or delays, ensuring that your travel experience remains hassle-free and secure.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Domestic Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Medical Emergencies:</strong> Covers medical expenses in case of an illness or injury while traveling domestically.</li>
                            <li><strong>✅ Trip Cancellations:</strong> Provides compensation for trip cancellations due to unforeseen circumstances.</li>
                            <li><strong>✅ Baggage Loss or Delay:</strong> Reimburses expenses for lost or delayed baggage during your travel.</li>
                            <li><strong>✅ Travel Delays:</strong> Covers additional expenses incurred due to delays in flights, buses, or other travel arrangements.</li>
                            <li><strong>✅ Emergency Assistance:</strong> 24/7 emergency assistance in case of accidents or medical needs while traveling.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Domestic Travel Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Domestic Travel Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why You Need Domestic Travel Insurance:</strong></p>
                            <ul>
                                <li>Provides financial protection against unexpected events during your travels.</li>
                                <li>Offers peace of mind with comprehensive coverage for medical, trip delays, and cancellations.</li>
                                <li>Reduces the stress of dealing with unforeseen travel issues by offering emergency assistance.</li>
                                <li>Ensures a safer and more enjoyable travel experience with coverage for various risks.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Domestic Travel Insurance Plans */}
                <section className="Individual-Health-section">
                    <h2>Types of Domestic Travel Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Basic Travel Insurance:</strong> Covers medical expenses and trip cancellations.</li>
                            <li><strong>2️⃣ Comprehensive Travel Insurance:</strong> Provides extensive coverage, including baggage loss, travel delays, and medical emergencies.</li>
                            <li><strong>3️⃣ Family Travel Insurance:</strong> Tailored for families, covering all members with a single policy.</li>
                            <li><strong>4️⃣ Student Travel Insurance:</strong> Designed for students, offering coverage for travel delays, medical emergencies, and trip cancellations.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Domestic Travel Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Domestic Travel Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Domestic Travel Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals planning domestic trips who want to safeguard their travel investment.</li>
                                <li>✔ Families traveling together and seeking comprehensive protection during their vacation.</li>
                                <li>✔ Business travelers who need peace of mind in case of trip delays or medical emergencies.</li>
                                <li>✔ Students traveling for academic purposes who need affordable coverage for travel-related risks.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Domestic_Travel_Info