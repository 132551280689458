import './Mutal_Fund_Info_Card.css'; // Import the CSS file

const Mutal_Fund_Info_Card = () => {
  return (
    <>
      <div className="mf-investment-services-container">
        <h1 className="mf-investment-services-title">Mutual Fund Investment Services</h1>
        <p className="mf-investment-services-description">
          We provide expert guidance on investing in mutual funds, helping you achieve financial growth and stability through diversified portfolios.
        </p>
        <div className="mf-investment-services-grid">
          <div className="mf-investment-services-card">
            <div className="mf-investment-services-icon">📈</div>
            <h2 className="mf-investment-services-heading">Wealth Growth</h2>
            <p className="mf-investment-services-text">
              Our mutual fund strategies are designed to maximize your returns over time, helping you build wealth efficiently.
            </p>
          </div>
          <div className="mf-investment-services-card">
            <div className="mf-investment-services-icon">🔍</div>
            <h2 className="mf-investment-services-heading">Expert Analysis</h2>
            <p className="mf-investment-services-text">
              Get access to in-depth research and expert recommendations to make informed investment decisions.
            </p>
          </div>
          <div className="mf-investment-services-card">
            <div className="mf-investment-services-icon">💰</div>
            <h2 className="mf-investment-services-heading">Portfolio Diversification</h2>
            <p className="mf-investment-services-text">
              Diversify your investments across various asset classes to reduce risk and enhance long-term financial security.
            </p>
          </div>
          <div className="mf-investment-services-card">
            <div className="mf-investment-services-icon">📊</div>
            <h2 className="mf-investment-services-heading">Risk Assessment</h2>
            <p className="mf-investment-services-text">
              Our team helps you evaluate risk factors and choose the right mutual fund plans tailored to your financial goals.
            </p>
          </div>
          <div className="mf-investment-services-card">
            <div className="mf-investment-services-icon">⏳</div>
            <h2 className="mf-investment-services-heading">Long-Term Planning</h2>
            <p className="mf-investment-services-text">
              Plan your financial future with our long-term investment strategies designed to provide stability and growth.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mutal_Fund_Info_Card;
