import './About_Mission.css';
import about_us_mission from '../../assets/images/Child_Plan_Life_Insurance.jpg';

function About_Mission() {
    return (
        <>
            <div className="mission-container">
                <div className="mission-inner">
                    <div className="mission-left-section">
                        <div className="mission-image-container">
                            <img
                                src={about_us_mission}
                                alt="Financial Planning"
                                className="mission-main-image"
                            />
                            <div className="mission-quote-overlay">
                                <p>
                                    <span className="mission-quote-icon">"</span> Tailored Financial Solutions for a Secure Future
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mission-right-section">
                        <h4>WHY CHOOSE US ?</h4>
                        <h2>Comprehensive Financial Planning to Secure Your Future</h2>
                        <p>
                            At Upholic, we offer personalized solutions for life insurance, financial planning, PMS, and loans, ensuring that your financial goals are met with the highest degree of expertise and care.
                        </p>
                        <div className="mission-skills-container">
                            <div className="mission-skill">
                                <span>Life Insurance Expertise</span>
                                <div className="mission-progress-bar">
                                    <div className="mission-progress-fill" style={{ width: "95%" }}></div>
                                </div>
                                <span className="mission-percentage">95%</span>
                            </div>
                            <div className="mission-skill">
                                <span>Financial Planning Knowledge</span>
                                <div className="mission-progress-bar">
                                    <div className="mission-progress-fill" style={{ width: "98%" }}></div>
                                </div>
                                <span className="mission-percentage">98%</span>
                            </div>
                            <div className="mission-skill">
                                <span>Portfolio Management Services (PMS)</span>
                                <div className="mission-progress-bar">
                                    <div className="mission-progress-fill" style={{ width: "90%" }}></div>
                                </div>
                                <span className="mission-percentage">90%</span>
                            </div>
                            <div className="mission-skill">
                                <span>Loan Solutions</span>
                                <div className="mission-progress-bar">
                                    <div className="mission-progress-fill" style={{ width: "93%" }}></div>
                                </div>
                                <span className="mission-percentage">93%</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <footer className="mission-footer">
                    <p>
                        Trusted by 8 clients worldwide. We provide comprehensive financial solutions, including life insurance, financial planning, PMS, and loan services to help you secure a prosperous future.
                    </p>
                    <div className="mission-logos">
                        <span>Upholic</span>
                        <span>Rinsure</span>
                        <span>Raxmus</span>
                        <span>Ravenz</span>
                        <span>Rekover</span>
                        <span>Reshield</span>
                    </div>
                </footer> */}
            </div>
        </>
    );
}

export default About_Mission;
