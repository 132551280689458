import './Mutal_Fund_Build.css';
import { Link } from "react-router-dom";
import MutualFundImage from "../../assets/images/mutual-funds.avif";

function Mutal_Fund_Build() {
  return (
    <>
      <div className="mutual-fund-try-container">
        <div className="mutual-fund-try-content" data-aos="fade-up">
          <h3 className="mutual-fund-try-subtitle" data-aos="fade-up">
            Mutual Funds
          </h3>
          <h1 className="mutual-fund-try-title" data-aos="fade-up">
            Build Wealth with Smart Mutual Fund Investments
          </h1>
          <p className="mutual-fund-try-description" data-aos="fade-up">
            Investing in mutual funds has never been easier. Let us guide you through the best options to grow your wealth efficiently.
          </p>
          <Link to="/Signup">
            <button className="mutual-fund-try-button" data-aos="fade-up">
              Start Investing
            </button>
          </Link>
        </div>
        <div className="mutual-fund-try-image-container" data-aos="fade-up">
          <img
            src={MutualFundImage}
            alt="Mutual Fund illustration"
            className="mutual-fund-try-image"
          />
        </div>
      </div>
    </>
  );
}

export default Mutal_Fund_Build;
