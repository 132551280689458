import mf_image_AboutUs from '../../assets/images/mutual_fund_bg_img.jpg';

function Loan_About() {
  return (
    <>
      <div className="mf-about-us-container">
        <img
          src={mf_image_AboutUs}
          alt="Loan Services Background"
          className="mf-about-us-background"
        />

        {/* Main Content Container */}
        <div className="mf-about-us-content">
          <h2 className="mf-about-us-heading">Benefits of Loans</h2>

          {/* Cards Container */}
          <div className="mf-about-us-cards-container">
            <div className="mf-about-us-card dark-theme">
              <h3>Lower Interest Rates</h3>
              <p>Loans come with competitive interest rates, making them an affordable financing option.</p>
            </div>
            <div className="mf-about-us-card light-theme">
              <h3>Quick Approval and Disbursal</h3>
              <p>Get fast approval and quick access to funds for urgent financial needs.</p>
            </div>
            <div className="mf-about-us-card dark-theme">
              <h3>No Need to Liquidate Investments</h3>
              <p>Secure a loan without selling your existing investments or assets.</p>
            </div>
            <div className="mf-about-us-card light-theme">
              <h3>Use Funds for Various Purposes</h3>
              <p>Loans can be used for personal expenses, business expansion, medical emergencies, or education.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loan_About;
