import Navbar from '../../pages/navbar/Navbar'
import Footer from '../../pages/footer/Footer'
import Copyright from '../../pages/copyright/Copyright'
import FamilyHealthImg from '../../assets/images/Family_Health_Insurance.avif';
import BenefitsFamilyHealthImg from '../../assets/images/life_insurance_hero.avif';
import WhoShouldOptImg from '../../assets/images/family_insurance.avif';
import { Link } from 'react-router-dom'

function Family_Health_Info() {
  return (
    <>
      <Navbar/>
      <div className="Individual-Health-containerr">
        {/* Section 1: What is Family Health Insurance? */}
        <section className="Individual-Health-section">
          <h2>What is Family Health Insurance?</h2>
          <div className="Individual-Health-section-content">
            <img
              src={FamilyHealthImg}
              alt="Family Health Insurance"
              className="Individual-Health-section-img"
            />
            <div className="Individual-Health-section-text">
              <p className='Individual-Health-section-para'>
                Family Health Insurance is a policy that provides health coverage for the entire family under a single plan. It offers financial protection against medical expenses, including hospitalization, doctor visits, prescription medications, and preventive care for all family members. It is ideal for families looking for comprehensive and cost-effective health coverage.
              </p>
            </div>
          </div>
        </section>

        {/* Section 2: Key Features */}
        <section className="Individual-Health-section">
          <h2>Key Features of Family Health Insurance</h2>
          <div className="Individual-Health-section-content">
            <ul>
              <li><strong>✅ Comprehensive Coverage:</strong> Covers all family members, including spouse, children, and sometimes parents.</li>
              <li><strong>✅ Cost-Effective:</strong> More affordable than purchasing individual plans for each family member.</li>
              <li><strong>✅ Shared Benefits:</strong> Sum insured can be utilized by any family member as needed.</li>
              <li><strong>✅ Preventive Care:</strong> Includes vaccinations, health check-ups, and screenings for the entire family.</li>
              <li><strong>✅ Maternity and Newborn Care:</strong> Covers maternity expenses and newborn care under the same plan.</li>
            </ul>
          </div>
        </section>

        {/* Section 3: Benefits */}
        <section className="Individual-Health-section">
          <h2>Benefits of Family Health Insurance</h2>
          <div className="Individual-Health-section-content">
            <img
              src={BenefitsFamilyHealthImg}
              alt="Benefits of Family Health Insurance"
              className="Individual-Health-section-img"
            />
            <div className="Individual-Health-section-text">
              <p><strong>🎯 For Families:</strong></p>
              <ul>
                <li>One Plan for All: Simplifies health coverage for the entire family.</li>
                <li>Financial Security: Protects against high medical costs for multiple family members.</li>
                <li>Customizable Add-ons: Options to include critical illness cover, dental care, or wellness programs.</li>
                <li>Tax Benefits: Premiums paid are eligible for tax deductions under applicable laws.</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Section 4: Types of Family Health Insurance */}
        <section className="Individual-Health-section">
          <h2>Types of Family Health Insurance Plans</h2>
          <div className="Individual-Health-section-content">
            <ul>
              <li><strong>1️⃣ Comprehensive Family Plans:</strong> Covers all family members under a single policy with a shared sum insured.</li>
              <li><strong>2️⃣ Floater Plans:</strong> Allows the sum insured to be used by any family member as needed.</li>
              <li><strong>3️⃣ Top-Up Plans:</strong> Provides additional coverage beyond the base plan for high medical expenses.</li>
              <li><strong>4️⃣ Maternity Plans:</strong> Includes coverage for maternity-related expenses and newborn care.</li>
              <li><strong>5️⃣ Critical Illness Plans:</strong> Offers coverage for specific critical illnesses for the entire family.</li>
            </ul>
          </div>
        </section>

        {/* Section 5: Who Should Opt for Family Health Insurance? */}
        <section className="Individual-Health-section">
          <h2>Who Should Opt for Family Health Insurance?</h2>
          <div className="Individual-Health-section-content">
            <img
              src={WhoShouldOptImg}
              alt="Who Should Opt for Family Health Insurance?"
              className="Individual-Health-section-img"
            />
            <div className="Individual-Health-section-text">
              <ul>
                <li>✔ Families with multiple dependents, including children and elderly parents.</li>
                <li>✔ Parents planning to start or expand their family.</li>
                <li>✔ Families seeking a single, comprehensive health plan for all members.</li>
                <li>✔ Individuals looking for cost-effective health coverage for their loved ones.</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Call-to-Action Section */}
        <div className="Individual-Health-contact-container">
          <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
          <Link to="/book-free-appointment">
          <button className="Individual-Health-contact-button">Free Appointment</button>
          </Link>
        </div>
      </div>
      <Footer/>
      <Copyright/>
    </>
  )
}

export default Family_Health_Info