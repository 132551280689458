import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import ComprehensiveCarInsuranceImg from '../../assets/images/Comprehensive-Car-Insurance.avif';
import BenefitsImg from '../../assets/images/Comprehensive_Car_Insurance.jpg';
import WhoShouldOptImg from '../../assets/images/car_third_party.avif';
import { Link } from 'react-router-dom';

function Comprehensive_Car_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Comprehensive Car Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Comprehensive Car Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={ComprehensiveCarInsuranceImg}
                            alt="Comprehensive Car Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Comprehensive Car Insurance provides extensive coverage for your car, including damages to your vehicle due to accidents, natural disasters, theft, or vandalism. It also covers third-party liabilities for bodily injuries and property damage caused by your vehicle.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Comprehensive Car Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Third-Party Liability:</strong> Covers damages caused to third parties, including bodily injury and property damage.</li>
                            <li><strong>✅ Own Damage Coverage:</strong> Covers repairs or replacement for your car due to accidents, theft, or natural calamities.</li>
                            <li><strong>✅ Theft Protection:</strong> Offers protection if your car is stolen.</li>
                            <li><strong>✅ Vandalism and Malicious Damage Coverage:</strong> Provides compensation for damages caused by vandalism or malicious acts.</li>
                            <li><strong>✅ Personal Accident Cover:</strong> Covers injuries to the driver and passengers due to an accident.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Comprehensive Car Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsImg}
                            alt="Benefits of Comprehensive Car Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 Why Choose Comprehensive Car Insurance?</strong></p>
                            <ul>
                                <li>Protects against a wide range of risks, including accidents, theft, and natural disasters.</li>
                                <li>Offers peace of mind with third-party liability and own damage coverage.</li>
                                <li>Provides financial support in case of theft or vandalism.</li>
                                <li>Covers medical expenses for the driver and passengers in case of an accident.</li>
                                <li>Flexible coverage options with additional add-ons for enhanced protection.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Coverage Details */}
                <section className="Individual-Health-section">
                    <h2>Coverage Details</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✔ Own Damage Coverage:</strong> Covers damage to your vehicle caused by accidents or natural calamities.</li>
                            <li><strong>✔ Third-Party Liability:</strong> Covers injuries and property damage caused to others in an accident.</li>
                            <li><strong>✔ Theft Protection:</strong> Provides compensation if your car is stolen.</li>
                            <li><strong>✔ Personal Accident Cover:</strong> Offers medical coverage for injuries sustained in an accident.</li>
                            <li><strong>✔ Natural Calamities:</strong> Covers damages from natural disasters like floods, earthquakes, etc.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Comprehensive Car Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Comprehensive Car Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Comprehensive Car Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Car owners looking for extensive protection for their vehicles.</li>
                                <li>✔ Individuals living in areas prone to natural disasters, theft, or vandalism.</li>
                                <li>✔ Those who want financial protection against third-party claims.</li>
                                <li>✔ Drivers seeking additional medical coverage in case of an accident.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Comprehensive_Car_Info