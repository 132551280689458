import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import WhoShouldOptImg from '../../assets/images/Mediclaim_Insurance.avif';
import MediclaimImg from '../../assets/images/MediclaimImg.avif';
import BenefitsMediclaimImg from '../../assets/images/BenefitsMediclaimImg.avif';
import { Link } from 'react-router-dom'

function Mediclaim_Insurance_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Mediclaim Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Mediclaim Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={MediclaimImg}
                            alt="Mediclaim Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Mediclaim Insurance provides financial coverage for hospitalization expenses due to illness, surgery, or accidents. It ensures policyholders receive the necessary medical care without the burden of high medical costs.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Mediclaim Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Cashless Treatment:</strong> Avail treatment at network hospitals without upfront payment.</li>
                            <li><strong>✅ Hospitalization Coverage:</strong> Covers expenses for hospitalization, surgery, and ICU stays.</li>
                            <li><strong>✅ Pre & Post-Hospitalization:</strong> Medical expenses before and after hospitalization are covered.</li>
                            <li><strong>✅ Daycare Procedures:</strong> Covers treatments that do not require 24-hour hospitalization.</li>
                            <li><strong>✅ Tax Benefits:</strong> Premiums paid are eligible for tax deductions under Section 80D.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Mediclaim Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsMediclaimImg}
                            alt="Benefits of Mediclaim Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 For Policyholders:</strong></p>
                            <ul>
                                <li>Financial Security: Covers high medical expenses and hospitalization costs.</li>
                                <li>Access to Quality Healthcare: Ensures treatment at reputed hospitals.</li>
                                <li>Peace of Mind: Reduces stress about medical emergencies.</li>
                                <li>Affordable Premiums: Wide coverage at reasonable costs.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Mediclaim Insurance */}
                <section className="Individual-Health-section">
                    <h2>Types of Mediclaim Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Individual Mediclaim:</strong> Covers medical expenses for a single individual.</li>
                            <li><strong>2️⃣ Family Floater Mediclaim:</strong> Provides coverage for the entire family under one policy.</li>
                            <li><strong>3️⃣ Senior Citizen Mediclaim:</strong> Tailored plans for senior citizens with higher coverage.</li>
                            <li><strong>4️⃣ Critical Illness Mediclaim:</strong> Covers life-threatening diseases like cancer and heart ailments.</li>
                            <li><strong>5️⃣ Group Mediclaim:</strong> Offered by employers to provide healthcare benefits to employees.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Mediclaim Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Mediclaim Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptImg}
                            alt="Who Should Opt for Mediclaim Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals and families looking for medical expense coverage.</li>
                                <li>✔ Senior citizens who need specialized healthcare plans.</li>
                                <li>✔ Employees seeking employer-sponsored health insurance.</li>
                                <li>✔ Self-employed professionals ensuring financial protection against medical emergencies.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Mediclaim_Insurance_Info