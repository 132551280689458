import Copyright from '../../pages/copyright/Copyright'
import Footer from '../../pages/footer/Footer'
import Navbar from '../../pages/navbar/Navbar';
import CriticalIllnessImg from '../../assets/images/Critical-Illness-Insurance.avif';
import BenefitsCriticalImg from '../../assets/images/Critical_Illness_Insurance.avif';
import WhoShouldOptCriticalIllness from '../../assets/images/Who-CriticalIllness.avif';
import { Link } from 'react-router-dom'

function Critical_Illness_Info() {
    return (
        <>
            <Navbar />
            <div className="Individual-Health-containerr">
                {/* Section 1: What is Critical Illness Insurance? */}
                <section className="Individual-Health-section">
                    <h2>What is Critical Illness Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={CriticalIllnessImg}
                            alt="Critical Illness Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p className='Individual-Health-section-para'>
                                Critical Illness Insurance is a policy that provides a lump-sum payment upon the diagnosis of a covered critical illness, such as cancer, heart attack, stroke, or kidney failure. It is designed to help cover medical expenses, recovery costs, and other financial obligations during a challenging time. This insurance ensures financial stability when you need it most.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Section 2: Key Features */}
                <section className="Individual-Health-section">
                    <h2>Key Features of Critical Illness Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>✅ Lump-Sum Payment:</strong> Receive a one-time payout upon diagnosis of a covered critical illness.</li>
                            <li><strong>✅ Wide Coverage:</strong> Covers major illnesses like cancer, heart disease, stroke, and organ failure.</li>
                            <li><strong>✅ No Restrictions on Usage:</strong> Use the payout for medical bills, recovery, or even daily expenses.</li>
                            <li><strong>✅ Additional Riders:</strong> Options to add coverage for minor illnesses or recovery benefits.</li>
                            <li><strong>✅ Peace of Mind:</strong> Financial support to focus on recovery without worrying about costs.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 3: Benefits */}
                <section className="Individual-Health-section">
                    <h2>Benefits of Critical Illness Insurance</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={BenefitsCriticalImg}
                            alt="Benefits of Critical Illness Insurance"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <p><strong>🎯 For Policyholders:</strong></p>
                            <ul>
                                <li>Financial Security: Covers high treatment costs for critical illnesses.</li>
                                <li>Flexibility: Use the payout for medical expenses, lifestyle adjustments, or debt repayment.</li>
                                <li>Supplemental Coverage: Complements existing health insurance by covering non-medical costs.</li>
                                <li>Tax Benefits: Premiums and payouts may be eligible for tax deductions under applicable laws.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Section 4: Types of Critical Illness Insurance */}
                <section className="Individual-Health-section">
                    <h2>Types of Critical Illness Insurance Plans</h2>
                    <div className="Individual-Health-section-content">
                        <ul>
                            <li><strong>1️⃣ Stand-Alone Plans:</strong> Provides coverage exclusively for critical illnesses.</li>
                            <li><strong>2️⃣ Rider Plans:</strong> Added to existing life or health insurance policies for enhanced coverage.</li>
                            <li><strong>3️⃣ Return of Premium Plans:</strong> Refunds premiums if no claim is made during the policy term.</li>
                            <li><strong>4️⃣ Comprehensive Plans:</strong> Covers a wide range of critical illnesses and offers additional benefits like recovery support.</li>
                            <li><strong>5️⃣ Group Critical Illness Plans:</strong> Offered by employers to cover employees under a single policy.</li>
                        </ul>
                    </div>
                </section>

                {/* Section 5: Who Should Opt for Critical Illness Insurance? */}
                <section className="Individual-Health-section">
                    <h2>Who Should Opt for Critical Illness Insurance?</h2>
                    <div className="Individual-Health-section-content">
                        <img
                            src={WhoShouldOptCriticalIllness}
                            alt="Who Should Opt for Critical Illness Insurance?"
                            className="Individual-Health-section-img"
                        />
                        <div className="Individual-Health-section-text">
                            <ul>
                                <li>✔ Individuals with a family history of critical illnesses.</li>
                                <li>✔ Those seeking financial protection against high treatment costs.</li>
                                <li>✔ People without sufficient savings to cover unexpected medical expenses.</li>
                                <li>✔ Anyone looking to supplement their existing health insurance coverage.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action Section */}
                <div className="Individual-Health-contact-container">
                    <h3>"Book a free appointment with us today! We're happy to assist you."</h3>
                    <Link to="/book-free-appointment">
                    <button className="Individual-Health-contact-button">Free Appointment</button>
                    </Link>
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}

export default Critical_Illness_Info